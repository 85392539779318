import React, { useState } from "react";
import debitcard from "../../assets/icons/debcrecard.svg";
import ngnwallet from "../../assets/icons/ngnwallet.svg";
import axios from "../api/axios";
import { Platformbutton } from "../helpers/button/button";
import { RadioOption } from "../inputs/radiobutton";
import { openLink } from "../../helpers/helpers";
import { parsePhoneNumberFromString } from "libphonenumber-js/min";

// import { getCountryCallingCode, parsePhoneNumber } from 'libphonenumber-js/core';

export const ConferencePayment = (props) => {
  const [option, setOption] = useState("");
  const [step, setStep] = useState(1);
  const [selectedCurrency, setSelectedCurrency] = useState("");
  // eslint-disable-next-line
  const [payData, setPay] = useState({});

  // const [email, setEmail] = useState("");
  const [buttonText, setButtonText] = useState("Initiate Payment");

  const setPaymentOption = (value) => {
    setOption(value);
  };

  const setPaymentCurrency = (value) => {
    setSelectedCurrency(value);
  };

  const handlePayForTicket = () => {
    setButtonText("Redirecting ...");

    const payload = {
      fullName: props.registerForm.FullName,
      email: props.registerForm.EmailAddress,
      companyName: props.registerForm.CompanyName,
      ticketAction: props.selectedOption,
      countryCode: selectedCurrency ? selectedCurrency : "USD",

      phoneNumber: {
        code: parsePhoneNumberFromString(props.registerForm.PhoneNumber)
          .countryCallingCode,
        number: parsePhoneNumberFromString(props.registerForm.PhoneNumber)
          .nationalNumber,
      },

      paymentAction: selectedCurrency === "NGN" ? "CardNaira" : "Card",
    };

    axios
      .post(
        // 'http://syca-app-backend.eba-pe3mzmfm.us-east-1.elasticbeanstalk.com/api/v1/general-services/ticket-payment',
        "/v1/general-services/ticket-payment",
        payload
      )
      .then((response) => {
        const redirectPath = response.data.data.url;

        setTimeout(() => {
          window.open(redirectPath);
        }, 100);
        setButtonText("Initiate Payment");
        props.setShowModal(false);
      })

      .catch((error) => {
        setButtonText("Initiate Payment");
        console.log(error);
        props.setShowModal(false);
      });
  };

  switch (step) {
    case 1:
      return (
        <Mode
          option={option}
          setPaymentOption={setPaymentOption}
          payData={payData}
          setStep={setStep}
          paymentTitle={props.paymentTitle}
          toWholeCurrency={props.toWholeCurrency}
          step={step}
          rate={props.rate}
        />
      );
    case 2:
      return (
        <>
          <div className="paymentoption-container">
            <div className="paymentoption-inner">
              <div>
                <h2 className="font-[600] text-[24px]  text-[#14290A]">
                  Select Currency
                </h2>
                <p className="font-[300] text-[#2B5219]">
                  Select the currency you want to pay with
                </p>
              </div>

              <div className="paymentoption-inner mt-8 center mb-8">
                <RadioOption
                  image={ngnwallet}
                  changed={setPaymentCurrency}
                  id="1"
                  isSelected={selectedCurrency === "NGN"}
                  label="Pay in Naira"
                  value="NGN"
                />
                <div className="mb-4"></div>
                <RadioOption
                  image={ngnwallet}
                  changed={setPaymentCurrency}
                  id="2"
                  isSelected={selectedCurrency === "USD"}
                  label="Pay in USD"
                  value="USD"
                />

                <div className="mb-4"></div>
              </div>
              <Platformbutton
                type="normal"
                name={`${buttonText}`}
                click={() => {
                  handlePayForTicket();
                }}
                classname="fas fa-arrow-right"
                disabled={!selectedCurrency ? true : false}
              />
            </div>
          </div>
        </>
      );

    default:
      return <>Not Found</>;
  }
};

export const Mode = (props) => {
  return (
    <div className="paymentoption-container">
      <div className="paymentoption-inner">
        <div>
          <h2 className="text-vestigreen">
            {` Step 1 of `} {props.paymentTitle === "NMC UK" ? 3 : 2}
          </h2>
          <h2 className="font-[600] text-[18px] mb-2 ">{`Choose Payment Method`}</h2>
          <p className="font-[300]">
            Choose your preferred means of making payment
          </p>
        </div>

        <div className="paymentoption-inner mt-8 center mb-8">
          <div className="mb-4"></div>
          <RadioOption
            image={debitcard}
            changed={props.setPaymentOption}
            id="2"
            isSelected={props.option === "card"}
            label="Pay with card"
            value="card"
            sublabel="You can pay using active debit card."
          />

          <div className="mb-4"></div>
        </div>

        <Platformbutton
          type="normal"
          name="Continue Payment"
          click={() => props.setStep(2)}
          classname="fas fa-arrow-right"
          disabled={!props.option ? true : false}
        />
      </div>
    </div>
  );
};
