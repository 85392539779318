import React from "react";
import liberty from "../../assets/clarityCall/liberty.png";
import ladyflag from "../../assets/clarityCall/ladywithflag.png";
import general from "../../assets/clarityCall/general.png";
import { Link } from "react-router-dom";

function ClarityVisaTypes() {
  return (
    <div id="bookcallSection">
      <ReverseVisatypes
        toptitle="General Clarity Call Session"
        image={general}
        topDescription="Book a clarity call with our experts to ask about migrating to any country of your choice. From mastering the application requirements to settling into your new life, we’re here to support your journey every step of the way."
        topButtonText="Book Call"
        onClick={`/clarity-session/General`}
        baseTitle="Tech Nation Clarity Call Session"
        baseDescription="Schedule a Clarity Call with Our Experts to Guide You Through the Tech Nation Global Talent Visa Process. Whether you’re exploring eligibility, preparing your endorsement application, or planning your move, our experts are here to provide tailored support every step of the way. Let us help you navigate the complexities and ensure a smooth, successful journey"
        baseButtonText="Book Call"
        click={`/clarity-session/Tech Nation`}
      />
      <Visatypes
        toptitle="H1-B Visa Clarity Call Session"
        topDescription="Schedule a clarity call with our experts to guide you through the H-1B visa process. Whether it's understanding the application steps or adapting to a new culture, we're here to ensure a seamless transition."
        topButtonText="Book Call"
        onClick={`/clarity-session/H1B`}
        baseTitle="0-1 Visa Clarity Call Session"
        baseDescription="Book a clarity call with our experts to navigate your O-1 visa journey. From application details to cultural adjustment, we’ll ensure your transition is seamless and successful."
        baseButtonText="Book Call"
        click={`/clarity-session/0-1`}
      />
      <ReverseVisatypes
        toptitle="EB-1 Visa Clarity Call Session"
        image={ladyflag}
        topDescription="Book a clarity call with our experts to navigate the EB-1 visa process. From mastering the application requirements to settling into your new life, we’re here to support your journey every step of the way."
        topButtonText="Book Call"
        onClick={`/clarity-session/EB-1`}
        baseTitle="EB-2 NIW Visa Clarity Call Session"
        baseDescription="Schedule a clarity call with our experts to help you through the EB-2 NIW visa process. From meeting eligibility criteria to planning your move, we're here to ensure your journey is smooth and successful."
        baseButtonText="Book Call"
        click={`/clarity-session/EB-2 NIW`}
      />
    </div>
  );
}

const Visatypes = (props) => {
  return (
    <div className="w-full px-4 lg:px-0">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center lg:py-20 lg:w-[90%]">
        {/* Text Content */}
        <div className="flex flex-col gap-12 w-full lg:w-1/2 lg:pr-12">
          <div className="pt-10 lg:pt-0">
            <h1 className="text-[#14290A] text-2xl sm:text-3xl pb-6 font-fontReg lg:w-[90%]">
              {props.toptitle}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.topDescription}
            </p>
            <Link
              to={props.onClick}
              className="normal-case text-white bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[16px] rounded-lg cursor-pointer inline-block text-center w-[50%] lg:w-[30%]"
            >
              {props.topButtonText}
            </Link>
          </div>

          <div className="pt-10 lg:pt-0">
            <h1 className="text-[#14290A] text-2xl sm:text-3xl pb-6 font-fontReg lg:w-[90%]">
              {props.baseTitle}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 lg:w-[90%] font-fontLight">
              {props.baseDescription}
            </p>
            <Link
              to={props.click}
              className="normal-case text-white w-[50%] lg:w-[30%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[16px] rounded-lg cursor-pointer inline-block text-center"
            >
              {props.baseButtonText}
            </Link>
          </div>
        </div>

        {/* Image */}
        <div className="w-full lg:w-1/2 mt-10 lg:mt-0">
          <img
            className="w-full h-auto object-cover"
            src={liberty}
            alt="image of liberty"
          />
        </div>
      </div>
    </div>
  );
};

const ReverseVisatypes = (props) => {
  return (
    <div className="w-full px-4 lg:px-0 my-8">
      <div className="container mx-auto flex flex-col lg:flex-row justify-between items-center lg:py-20 lg:w-[90%]">
        <div className="w-full lg:w-1/2 flex justify-center lg:justify-start">
          <img
            className="w-full h-auto object-cover lg:w-[90%]"
            src={props.image}
            alt="statue of liberty"
          />
        </div>
        <div className="flex flex-col gap-12 w-full lg:w-1/2 lg:pl-12">
          <div className="pt-10 lg:pt-0">
            <h1 className="text-[#14290A] text-2xl sm:text-3xl pb-6 font-fontReg">
              {props.toptitle}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 font-fontLight">
              {props.topDescription}
            </p>
            <Link
              to={props.onClick}
              className="normal-case text-white bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[16px] rounded-lg cursor-pointer inline-block text-center w-[50%] lg:w-[30%]"
            >
              {props.topButtonText}
            </Link>
          </div>
          <div className="pt-10 lg:pt-0">
            <h1 className="text-[#14290A] text-2xl sm:text-3xl pb-6 font-fontReg">
              {props.baseTitle}
            </h1>
            <p className="text-base text-[#2B5219] pb-8 font-fontLight">
              {props.baseDescription}
            </p>
            <Link
              to={props.click}
              className="normal-case text-white bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[16px] rounded-lg cursor-pointer inline-block text-center w-[50%] lg:w-[30%]"
            >
              {props.baseButtonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClarityVisaTypes;
