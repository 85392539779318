import React, { useState } from "react";
import thumbsup from "../../assets/images/doublethumpsup.png";
import { Button } from "@material-tailwind/react";
import { Success } from "../helpers/success/success";
import { Titlesubtitle } from "../helpers/titlesubtitle/titlesubtitle";
import Inputfloat from "../inputs/inputfloat";
import { Phonenumber } from "../inputs/phoneinput";
import {
  IconOption,
  IconSingleValue,
  Singleselect,
} from "../inputs/singleselect";
import { Platformbutton } from "../helpers/button/button";
import { RadioOption } from "../inputs/radiobutton";
import { Backcontinue } from "../helpers/backcontinue/backcontinue";
import errorsvg from "../../assets/logo-icons/oops.svg";
import "../../components/oeligibility/Oeligibility.scss";
import { useStep } from "../../hooks/hooks";
import { openLink } from "../../helpers/helpers";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import techCity from "../../assets/CanadianCareGiver/TechCity Logo.svg";
import investO from "../../assets/CanadianCareGiver/inves.svg";
import investT from "../../assets/CanadianCareGiver/inves2.svg";
import { GetScore } from "../CanadianCareGiverVisa/getScore";
import warningIcon from "../../assets/icons/warning.svg";
import { FaCircleInfo } from "react-icons/fa6";

const config = {
  cors: "https://cors-anywhere.herokuapp.com/",
  formUrl:
    "https://docs.google.com/forms/d/1VesinbkmR424YL80JJHk8ZjDCinKzELv38he5X8vjJk/formResponse",
};

const H1BBanner = (props) => {
  const navigate = useNavigate();
  return (
    <div className="bg-secondary w-full pt-24 lg:pt-28 text-white">
      <div className="container mx-auto pb-6">
        <div className="grid lg:grid-cols-2 md:gap-20 gap-10">
          <div className="col-span-1 mt-10">
            <h1 className="text-2xl font-fontSemi mt-20 lg:text-3xl xl:text-4xl pb-5 lg:w-[100%] lg:font-fontBold !leading-[50px] lg:!leading-[60px]">
              <span className="text-[#67A948]"> Get a WorldForce ™</span>{" "}
              <span className="text-[#f4f7f3]">
                - H1B Visa to work in a specialty job in the US
              </span>{" "}
            </h1>
            <p className="text-lg pb-12 lg:w-[80%] font-fontLight">
              If you are a skilled worker who wants to work in the US, the H1B
              visa is the pathway to your goal.
            </p>
            <div className="flex flex-row justify-between gap-4">
              {/* <Button
                onClick={() => props.setOpen(true)}
                className="font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] border border-vestigreen bg-transparent transition duration-700 ease-in-out"
              >
                See if you qualify
              </Button> */}

              <Button
                onClick={
                  () => {
                    navigate("/h1b-lottery-payment");
                  }
                  // openLink(
                  //   "https://event.webinarjam.com/register/4/3ypnliq",
                  //   "_blank"
                  // )
                }
                className="font-fontLight flex-start text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
              >
                Pre-register for 2026
              </Button>
            </div>
            <div className="md:mt-[7%] mt-[5%] md:mb-6 mb-0 flex items-center xlg:gap-2 gap-1">
              <p className="text-[#7D7D7D] vlg:text-[24px] xxl:text-[20px] sm:text-[14px] text-[12px] lg:w-[0rem] md:w-[40%] sm:w-[18%] xs:w-[26%] w-[44%]">
                As featured in:
              </p>
              <div className="flex items-center xxl:gap-[20%] xlg:gap-[30px] sm:gap-8 gap-4">
                <img
                  className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                  src={investO}
                  alt=""
                  onClick={() =>
                    openLink(
                      "https://www.techstars.com/newsroom/techstars-nyc-class-of-spring-2022",
                      "_blank"
                    )
                  }
                />

                <img
                  className="bg:w-[50%] xxl:w-[100%] w-[20%] cursor-pointer"
                  src={techCity}
                  alt=""
                  onClick={() =>
                    openLink(
                      "https://www.techcityng.com/migrants-banking-app-vesti-raises-500k-in-pre-seed-funding/",

                      "_blank"
                    )
                  }
                />

                <img
                  className="xxl:w-[100%] w-[20%] bg:w-[50%] cursor-pointer"
                  src={investT}
                  alt=""
                  onClick={() =>
                    openLink(
                      "https://www.bloomberg.com/press-releases/2022-08-30/nova-credit-and-vesti-partner-to-alleviate-financial-barriers-for-u-s-bound-nigerians",
                      "_blank"
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="col-span-1">
            <img className="w-full" src={thumbsup} alt="Big Smile" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default H1BBanner;

export const H1Beligibility = (props) => {
  const [state, setState] = useState({
    fullName: "",
    gender: "",
    phoneNumber: "",
    email: "",
    country: "",
    expertise: "",
    degrees: "",
    experience: "",
    leadership: "",
    sponsor: "",
    employmentOffer: "",
    lottery: "",
    lotteryCost: "",
  });

  var ids = [
    "275674196",
    "500981336",
    "364319452",
    "850618021",
    "491281489",
    "1376928382", //expertise
    "574620756",
    "713045706",
    "1139714467",
    "540324483",
    "334475943",
    "497882825",
    "1019581484",
  ];

  const navigate = useNavigate();

  const { step, setStep, nextStep, previousStep } = useStep(0);
  const [score, setScore] = useState(7);
  var data = [
    {
      name: "degrees",
      title:
        "Do you have a Bachelors Degree Certificate(or equivalent like HND) or Masters Degree in a specialty occupation?",
      value: "yes",
    },
    {
      name: "experience",
      title: "Do you have any work experience in a specialty occupation?",
      value: "yes",
    },
    {
      name: "leadership",
      title:
        "Have you previously held a leadership role in a prominent organization in your field?",
      value: "yes",
    },
    {
      name: "sponsor",
      title:
        "Do you have a US registered company you can use to self sponsor or know a company that would be willing to be your sponsor?.",
      value: "yes",
    },
    {
      name: "employmentOffer",
      title:
        "Do you have an employment offer from an employer based in the US that can be used as basis for the filing",
      value: "yes",
    },
    {
      name: "lottery",
      title:
        "Are you able to move to the US in 2025 if you are selected in a lottery?",
      value: "yes",
    },
    {
      name: "lotteryCost",
      title:
        "If found eligible, are you able to cover cost for entering the lottery $715?",
      value: "yes",
    },
  ];
  var setInput = (e) => {
    var name = e.target.name;
    var value = e.target.value;
    setState({ ...state, [name]: value });
  };
  var handleSelect = (name, value) => {
    setState({ ...state, [name]: value });
  };
  var handleOption = (name, value) => {
    if (state[name] === "Yes" || !state[name]) {
      value === "No" && setScore(score - 1);
    } else if (state[name] === "No") {
      value === "Yes" && setScore(score + 1);
    }
    setState({ ...state, [name]: value });
  };

  var __renderStep = () => {
    switch (step) {
      case 0:
        return (
          <Stepone
            data={state}
            setInput={setInput}
            handleSelect={handleSelect}
            countries={props.countries}
            click={nextStep}
            setState={setState}
            state={state}
          />
        );
      case 1:
        return (
          <Steptwo
            disabled={
              Object.entries(state)
                .slice(4, 10)
                .every((value) => value[1] !== "")
                ? false
                : true
            }
            score={score}
            data={data.slice(0, 4)}
            name="Continue"
            click={nextStep}
            back={previousStep}
            handleOption={handleOption}
            setState={setState}
            state={state}
          />
        );
      case 2:
        return (
          <Stepthree
            disabled={
              Object.entries(state)
                .slice(11)
                .every((value) => value[1] !== "")
                ? false
                : true
            }
            score={score}
            data={data.slice(4)}
            name="Submit"
            click={() => submit()}
            back={previousStep}
            handleOption={handleOption}
            setState={setState}
            state={state}
          />
        );
      case 3:
        return (
          <Success
            title="Congratulations, You are a Fit for the H1B"
            subtitle="Congratulations, you are fit for the H1B VIsa."
            button="Enter the Lottery"
            onClick={() => {
              navigate("/h1b-lottery-payment");

              // window.open("https://calendly.com/vesti/h1b-visa-2024-stream");
              // props.close();
            }}
          />
        );
      case 4:
        return (
          <div className="flex flex-col items-center">
            <FaCircleInfo size={50} className="text-vestigreen" />

            <p className="text-[24px] text-vestigreen mb-1 font-semibold">
              Notification
            </p>
            <p className="text-center mb-4 text-headcol">
              Thank you for taking the test. <br /> A member of the team will
              reach out to you
            </p>

            <Platformbutton
              name="Okay, thank you"
              type="normal"
              disabled={false}
              click={() => props.close()}
            />
          </div>
          // <Success
          //   image={errorsvg}
          //   type="error"
          //   title="Bummer, We can help you get ready for the H1B"
          //   subtitle="Thanks for taking the test, a member of the team will reach out to you"
          // subtitle="Unfortunately you are not a Fit for the H1B Visa at the moment. However, we believe we can help you to decide which other Visa is better, also we can help prepare you for the H1B visa in 10-20weeks. A member of our team will reach out in 24-48 hours with additional information."
          //   button="Close"
          //   onClick={() => {
          //     props.close();
          //   }}
          // />
        );
      default:
        return <></>;
    }
  };

  var submit = async () => {
    const formData = new FormData();
    var list = Object.keys(state);
    for (var key in state) {
      const value = Array.isArray(state[key])
        ? JSON.stringify(state[key])
        : state[key];
      // eslint-disable-next-line
      value.label
        ? formData.append(
            // eslint-disable-next-line
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function (item) {
                  return item === key;
                })
              ]
            }`,
            value.label
          )
        : formData.append(
            `entry.${
              ids[
                // eslint-disable-next-line
                list.findIndex(function (item) {
                  return item === key;
                })
              ]
            }`,
            value
          );
    }

    await axios({
      url: `${config.formUrl}`,
      method: "post",
      data: formData,
      responseType: "json",
    })
      .then((res) => {
        axios
          .post(
            "https://pathwayapi.wevesti.com/api/v1/pathway/visa-eligibility-test",
            {
              email: state.email,
              score: GetScore(score),
              visaType: "H1B",
              fullName: state.fullName,
              hasClarityMiai: false,
              hasClarityPrerecorded: false,
            }
          )
          .then((res) => {
            score >= 4 ? setStep(3) : setStep(4);
          })
          .catch((err) => (score >= 4 ? setStep(3) : setStep(4)));
      })
      .catch((error) => {
        score >= 4 ? setStep(3) : setStep(4);
        axios
          .post(
            "https://pathwayapi.wevesti.com/api/v1/pathway/visa-eligibility-test",
            {
              email: state.email,
              score: GetScore(score),
              visaType: "H1B",
              fullName: state.fullName,
              hasClarityMiai: false,
              hasClarityPrerecorded: false,
            }
          )
          .then((res) => {});
      });
  };

  return (
    <div>
      {step < 3 && (
        <Titlesubtitle
          steps={` ${step + 1} of 3 `}
          title="See if an H1B Visa is a fit for you"
          subtitle="Take this quiz to see if the H1B Visa is a fit for you to move to America."
        />
      )}
      <div className="mb-4"></div>
      {__renderStep()}
    </div>
  );
};

const Stepone = (props) => {
  return (
    <div className="ostepone">
      <form className="ostepone__col">
        <div className="ostepone__row">
          <Inputfloat
            type="text"
            label="Full Name"
            name="fullName"
            placeholder="John Doe"
            value={props.data.fullname}
            disabled={false}
            onChange={props.setInput}
          />
          <Phonenumber
            value={props.data.phoneNumber}
            country={props.data.country?.vlaue}
            setValue={(value) =>
              props.setState({ ...props.state, phoneNumber: value })
            }
          />
        </div>
        <Inputfloat
          type="email"
          label="Email Address"
          name="email"
          placeholder="Johndoe@gmail.com"
          value={props.data.email}
          disabled={false}
          onChange={props.setInput}
        />
        <Singleselect
          label="Gender"
          value={props.data.gender}
          options={[
            { label: "Male", value: "Male" },
            { label: "Female", value: "Female" },
            { label: "Prefer not to say", value: "Prefer not to say" },
          ]}
          onChange={(value) => {
            //console.log(value.value);
            props.handleSelect("gender", value);
          }}
          placeholder="Select your gender "
        />

        <Singleselect
          label="Country of birth"
          value={props.data?.country}
          options={props.countries}
          onChange={(value) => {
            props.handleSelect("country", value);
          }}
          placeholder="Select Country of birth"
          components={{ SingleValue: IconSingleValue, Option: IconOption }}
        />

        <Singleselect
          label="What’s your area of Expertise"
          value={props.data.expertise}
          options={[
            { label: "Arts", value: "Arts" },
            {
              label: "Sciences, Education, Business or Athletics",
              value: "Sciences, Education, Business or Athletics",
            },
            {
              label: "Motion pictures or Television",
              value: "Motion pictures or Television",
            },
          ]}
          onChange={(value) => {
            props.handleSelect("expertise", value);
          }}
          placeholder="What’s your area of Expertise"
        />

        <div className="ostepone__btns">
          <Platformbutton
            name="Continue"
            type="normal"
            disabled={
              Object.entries(props.data)
                .slice(0, 6)
                .every((value) => value[1] !== "")
                ? false
                : true
            }
            click={() => props.click()}
          />
        </div>
      </form>
    </div>
  );
};

const Steptwo = (props) => {
  return (
    <div className="ostepone">
      <div className="ostepone__form">
        <div className="ostepone__bcol">
          {props.data.map((item, index) => (
            <div className="ostepone__col" key={index}>
              <p>{item.title}</p>
              <div className="ostepone__scol">
                <div style={{ width: "100%" }}>
                  <RadioOption
                    changed={(value) => props.handleOption(item.name, value)}
                    id={`0 + ${item.name}`}
                    isSelected={props.state[item.name] === "Yes"}
                    label="Yes"
                    value={"Yes"}
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <RadioOption
                    changed={(value) => props.handleOption(item.name, value)}
                    id={`1 + ${item.name}`}
                    isSelected={props.state[item.name] === "No"}
                    label="No"
                    value={"No"}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ostepone__btns">
        <Backcontinue goBack={() => props.back()}>
          <Platformbutton
            name={props.name}
            type="normal"
            disabled={props.disabled}
            click={() => props.click()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};

const Stepthree = (props) => {
  return (
    <div className="ostepone">
      <div className="ostepone__form">
        <div className="ostepone__bcol">
          {props.data?.map((item, index) => (
            <div className="ostepone__col" key={index}>
              <p>{item.title}</p>
              <div className="ostepone__scol">
                <div style={{ width: "100%" }}>
                  <RadioOption
                    changed={(value) => props.handleOption(item.name, value)}
                    id={`0 + ${item.name}`}
                    isSelected={props.state[item.name] === "Yes"}
                    label="Yes"
                    value={"Yes"}
                  />
                </div>

                <div style={{ width: "100%" }}>
                  <RadioOption
                    changed={(value) => props.handleOption(item.name, value)}
                    id={`1 + ${item.name}`}
                    isSelected={props.state[item.name] === "No"}
                    label="No"
                    value={"No"}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ostepone__btns">
        <Backcontinue goBack={() => props.back()}>
          <Platformbutton
            name={props.name}
            type="normal"
            disabled={props.disabled}
            click={() => props.click()}
          />
        </Backcontinue>
      </div>
    </div>
  );
};
