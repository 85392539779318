import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import frameOne from '../../assets/ElitePass/frameOne.svg';
import frameTwo from '../../assets/ElitePass/frameTwo.svg';
import frameThree from '../../assets/ElitePass/frameThree.svg';
import frameFour from '../../assets/ElitePass/frameFour.svg';
import frameFive from '../../assets/ElitePass/frameFive.svg';

const cards = [
  { id: 1, img: frameOne },
  { id: 2, img: frameTwo },
  { id: 3, img: frameThree },
  { id: 4, img: frameFour },
  { id: 5, img: frameFive }
];

const ScrollCards = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
    }, 3000); 
    return () => clearInterval(interval); 
  }, []);

  return (
    <div className="relative flex flex-col items-center w-full xl:px-[6%] px-[4%] mt-[8%]">
      <h2 className="text-[27px] sm:text-[28px] md:text-[27px] xl:text-[30px] font-bold">
        <span className="text-[#67A948]">Experience Luxury</span> at Every Airport
      </h2>
      <p className="vlg:text-[24px] xxl:text-[20px] text-18px mb-0 sm:mb-4">
        With the Elite Pass, we take care of the details so you can focus on your journey.
      </p>

      {/* Container for the frames */}
      <div className="relative w-full h-[24vh] md:h-[26vh] lg:h-[34vh] my-[3%] md:my-[4%] lg:my-[8%]">
        {cards.map((card, index) => (
          <ExperienceLuxury
            key={card.id}
            index={index}
            img={card.img}
            isActive={index === activeIndex}
          />
        ))}
      </div>
    </div>
  );
};

export const ExperienceLuxury = ({ index, img, isActive }) => {
  return (
    <motion.div
      className={`w-full h-[10rem] sm:h-60 flex items-center justify-center absolute top-0`}
      initial={{ opacity: 0, y: 50 }}
      animate={isActive ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
      transition={{ duration: 0.5 }}
      style={{ zIndex: index + 1 }} // Ensure frames stack correctly
    >
      <img src={img} alt={`Frame ${index + 1}`} className="w-full h-[100%] sm:h-[160%] object-fit lg:object-cover rounded-lg" />
    </motion.div>
  );
};

export default ScrollCards;

