import React from 'react'
import { useNavigate } from 'react-router-dom';
import caseStudyImg from '../../assets/SuccessStory/CaseStudyPic.png'
import { Button } from '@material-tailwind/react';
import Prembly from '../../assets/SuccessStory/Prembly.png'
import interswitch from '../../assets/SuccessStory/Interswitch.png'
import paystack from '../../assets/SuccessStory/Paystack.png'
import providus from '../../assets/SuccessStory/Providus.png'

const BrandData = [
  {
    id: "prembly",
    logo: Prembly,
    title: "Identitypass introduces Prembly as new parent brand to reflect growth and continued expansion",
    link: "#",
  },
  {
    id: "interswitch",
    logo: interswitch,
    title: "Interswitch Group has formally unveiled the 2024 Global Payment Innovation Jury Report",
    link: "#",
  },
  {
    id: "paystack",
    logo: paystack,
    title: "Partner with Paystack to build seamless payment experiences and grow your businesses",
    link: "#",
  },
  {
    id: "providus",
    logo: providus,
    title: "CBN has approved the merger between Unity Bank Plc and Providus Bank Limited.",
    link: "#",
  },
];

export const CaseStudy = () => {
  const navigate = useNavigate();
  return (
    <div className='md:px-[8%] lg:px-[12%] px-[4%]'>
    <div className="mb-[10%] md:mt-[4rem] mt-12 md:my-[7%] md:flex md:items-center lg:gap-[6%] gap-[6%]">
    <img
      className="vlg:w-[50rem] xlg:w-[40rem] lg:w-[30rem] md:w-[22rem] w-[100%] rounded-md"
      src={caseStudyImg}
      alt=""
    />
    <div>
      <h1 className="text-[27px] sm:text-[28px] md:text-[27px] xl:text-[30px] md:mt-0 mt-2 font-bold">
      Company Case Study
      </h1>
      <p className="text-[#667085] xxl:text-[20px] sm:text-[18px] text-[14px]">
      Check out how clients around the world migrate and also handle financial situations with VESTI.
      </p>
      
      <div className='mt-8'>
        <Button
            onClick={() => navigate('/case-study/vesti')}
            className="font-fontLight text-[#ffffff] text-base capitalize p-5 mb-0 md:mb-16 w-[100%] sm:w-[60%] lg:w-[50%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
        >
             View Case Study
        </Button>
       
      </div>
    </div>
    </div>
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-6">
            {BrandData.map((brand) => (
              <a
                key={brand.id}
                href={brand.link}
                className="bg-[#F7FFF3] border-r-4 border-[#1B2C48] p-4 lg:p-6 rounded-lg shadow-md hover:shadow-lg transition duration-300 block"
              >
                <img src={brand.logo} alt="Logo" className="w-32 h-16 object-contain mb-4" />
                <p className="text-gray-800 text-sm font-medium">{brand.title} 
                  <span 
                  onClick={(e) => {
                    e.preventDefault(); 
                    navigate(`/case-study/${brand.id}`); 
                  }} 
                  className="text-vestigreen">↗</span>
                  </p>
              </a>
            ))}
          </div>
  </div>
  )
}
