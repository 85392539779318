import React, {useState} from 'react'
import imgUp from '../../assets/Event-Img/imgUp.png'
import imgDown from '../../assets/Event-Img/imgUp.png'
import { Testimony } from '../../constant/TestimonialsData';
import TestimonialVideos from './TestimonialVideos';

export const ChooseVesti = () => {
    const [showAll, setShowAll] = useState(false);
    const visibleTestimonials = showAll ? Testimony : Testimony.slice(0, 6);
  return (
    <div className='sm:py-8'>
    <div className="bg-[#F7FFF3] h-full relative mt-8 lg:mt-12 py-20 px-4 md:px-[6%] overflow-hidden">
    <div className="flex flex-col justify-center items-center">
      <div className="">
        <h1 className="font-bold text-[27px] text-center">Why Choose <span className='text-vestigreen'>Vesti ?</span></h1>
        <p className="text-center mt-2 mb-6 text-[16px]">Hear from Those Who Have Benefited from Our Expertise and Technologies</p>
      </div>
       <div className="grid sm:grid-cols-2 xl:grid-cols-3 gap-4 p-1 sm:p-2 md:p-4 xl:p-6 xlg:p-6">
                {visibleTestimonials.map(
                  ({ image, name, date, testimony, background }, index) => {
                    return (
                      <div key={index} className={`bg-[${background}] rounded-md`}>
                        <div className="p-4">
                          <div className="flex items-center gap-2">
                            <img src={image} alt="" className="w-[40px] h-[40px] rounded-full" />
                            <p className="text-[#14290A]">{name}</p>
                            <div className="">
                              <h6 className="text-[#14290A] text-[20px] font-normal">
                                {/* {company} */}
                              </h6>
                            </div>
                          </div>
                          <p className="text-[12px] text-[#4F4F4F] mt-2">{date}</p>
      
                          <p className="text-[#4F4F4F] pt-4 text-sm">{testimony}</p> 
                        </div>
                      </div>
                    );
                  }
                )}
        </div>
        {/* Button */}
        {Testimony.length > 6 && (
          <button
            onClick={() => setShowAll(!showAll)}
            className="mt-6 text-vestigreen font-bold text-[14px] sm:text-[16px] flex items-center"
          >
            {showAll ? 'Show Less' : 'More Written Testimonials'} ↗
          </button>
        )}

        
    </div>

    <div className='mt-4 sm:mt-8 lg:mt-12'>
    <TestimonialVideos/>
    </div>

    <img src={imgUp} alt="" className='flex justify-start w-[80px] lg:w-auto absolute top-0 -left-5'/>
    <img src={imgDown} alt="" className='fles justify-end absolute w-[80px] lg:w-auto bottom-0 -right-5' />
    </div>
  </div>
  )
}
