// import Pathways from "../views/Pathways";


import { useState, useEffect } from "react";
import CardAd from "../../../views/CardAd";
import Offerings from "../../../views/Offerings";
import Showcase from "../../../views/Showcase";
import WalletCard from "../../../views/WalletCard";
import OpenAccount from "../../../views/OpenAccount";
import Testimonials from "../../../views/Testimonials";
import FaqSection from "../../../views/FaqSection";
import DownloadApp from "../../../views/DownloadApp";
import { BsArrowUpRight } from "react-icons/bs";
import { Link } from "react-router-dom";
import VestiCard from '../../../assets/images/cards.png'
import PathwaySlides from "../../../views/PathwaySlides";
import NavBar from "../../../containers/NavBar";
import Footer from "../../../containers/Footer";
import { Notificationmodal, Simplemodal } from "../../../components/helpers/modal/simplifiedmodal";
import { FlightAd } from "../../../views/flightAd";
// import EbookM from "./Ebook";
import popUpIcon from '../../../assets/popUpIcon/H1bpopup.svg'
import { Platformbutton } from "../../../components/helpers/button/button";
// import { openLink } from "../helpers/helpers";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";
import { RadioOption } from "../../../components/inputs/radiobutton";
import ngnwallet from "../../../assets/icons/ngnwallet.svg";
import { useNavigate } from "react-router-dom";
import { LiaTimesSolid } from "react-icons/lia";
import { BookAFlight } from "../../BookAFlight";

// import "./slider.css";


const KenyaHome = () => {
    const navigate = useNavigate();
    const [openModal, setOpenModal] = useState(false);
    const [modalState, setModalState] = useState(false);
  
    const [showCurrencyModal, setShowCurrencyModal] = useState(false);
    const [option, setOption] = useState("NGN");
  
    const setPaymentOption = (value) => {
      setOption(value);
    };
  
    useEffect(() => {
      const timeoutId = setTimeout(() => {
        setOpenModal(true);
      }, 3000);
  
      return () => clearTimeout(timeoutId);
    }, [modalState]);
  
    const handleClick = () => {
      setOpenModal(false);
      navigate("/h1b-lottery-payment");
      // setOpenModal(false);
  
      // openLink(
      //   "https://docs.google.com/forms/d/e/1FAIpQLSdNwK6f7XFIRJNJz-s4zJKdFUYlAxASHY-bevTAMpGKBnMEbg/viewform",
      //   "_blank"
      // );
    };
  
    const PrevButton = ({ onClick }) => (
      <button
        className="text-[40px] lg:text-[30px]"
        onClick={onClick}
        style={{ position: "absolute", top: "50%", zIndex: "5", left: "20px" }}
      >
        {/* <img src={larrow} alt="" /> */}
        <IoMdArrowDropleftCircle />
      </button>
    );
  
    const NextButton = ({ onClick }) => (
      <button
        className="text-[40px] lg:text-[30px]"
        onClick={onClick}
        style={{ position: "absolute", top: "50%", zIndex: "5", right: "20px" }}
      >
        <IoMdArrowDroprightCircle />
      </button>
    );
  
    // https://event.webinarjam.com/register/4/3ypnliq
  
    const slidersettings = {
      prevArrow: <PrevButton />,
      nextArrow: <NextButton />,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipeToSlide: true,
      autoplay: true, // Enable autoplay
      autoplaySpeed: 4000, // Autoplay speed in milliseconds
    };
  
    return (
      <>
        <Simplemodal
          onClick={() => setShowCurrencyModal(false)}
          open={showCurrencyModal}
        >
          <div className="paymentoption-container">
            <div className="paymentoption-inner">
              <div>
                {/* <h2 className="text-vestigreen">{` Step 1 of 2`}</h2> */}
                <h2 className="font-[600] text-[24px]  text-[#14290A]">
                  Select Currency
                </h2>
                <p className="font-[300] text-[#2B5219]">
                  Select the currency you want to pay with
                </p>
              </div>

              <div className="paymentoption-inner mt-8 center mb-8">
                <RadioOption
                  image={ngnwallet}
                  changed={setPaymentOption}
                  id="1"
                  isSelected={option === "NGN"}
                  label="Pay in Naira"
                  sublabel="Pay for your flight in Naira"
                  value="NGN"
                />
                <div className="mb-4"></div>
                <RadioOption
                  image={ngnwallet}
                  changed={setPaymentOption}
                  id="2"
                  isSelected={option === "USD"}
                  label="Pay in USD"
                  value="USD"
                  sublabel="Pay for your flight in Dollars"
                />

                <div className="mb-4"></div>
              </div>

              {option === "NGN" && (
                <Platformbutton
                  type="normal"
                  name="Continue"
                  click={() => {
                    window.open(
                      " https://app.wevesti.com/bookflight",
                      "_blank"
                    );
                    setShowCurrencyModal(false);
                  }}
                  classname="fas fa-arrow-right"
                />
              )}

              {option === "USD" && (
                <Platformbutton
                  type="normal"
                  name="Continue"
                  click={() => {
                    window.open(
                      " https://app.wevesti.com/bookflight/usd",
                      "_blank"
                    );
                    setShowCurrencyModal(false);
                  }}
                  classname="fas fa-arrow-right"
                />
              )}
            </div>
          </div>
        </Simplemodal>

        <Notificationmodal onClick={() => setOpenModal(false)} open={openModal}>
          <LiaTimesSolid
            onClick={() => setOpenModal(false)}
            className="text-[#000] absolute top-4 right-8 text-[24px] cursor-pointer"
          />

          {/* <EbookM setOpenModal={setOpenModal} /> */}
          <div className="w-[100%] sm:w-[400px] md:w-[450px] flex flex-col items-center">
            <img src={popUpIcon} alt="" />
            <p className="text-[#67A948] text-[18px] font-[600] mt-[20px] mb-[15px] text-center">
              Move to the US as an H-1B Worker with minimum HND/Bachelors Degree
            </p>
            <p className="text-[#2B5219] text-[14px] font-[500] text-center mb-[20px]">
              {/* Regular H1B pre-registration with Vesti for 2026 is now open */}
              The pre-registration for H1B lottery registration for 2026 has now
              opened.
            </p>

            <Platformbutton
              name="Start now"
              type="normal"
              disabled={false}
              click={() => {
                handleClick();
              }}
            />
          </div>
        </Notificationmodal>
        <NavBar />
        <Showcase />
        <WalletCard />
        <BookAFlight />
        {/* <Pathways /> */}
        <PathwaySlides />
        <Offerings />
        <OpenAccount />
        <Testimonials
          title={
            <>
              <h1 className="text-3xl text-headcol font-fontSemi text-center">
                What our users are{" "}
                <span className="text-vestigreen">saying</span>
              </h1>
              <p className="text-center text-[#2B5219] text-lg font-fontReg">
                Few out of 1000+ stories of Vesti Users
              </p>
            </>
          }
        />
        <FaqSection />
        <DownloadApp />
        <Footer />
      </>
    );
  };
  
  export default KenyaHome;