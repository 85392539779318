import React from "react";
import { Modal } from "antd";
import "./modal.scss";

export const Simplemodal = (props) => {
  return (
    <Modal
      closable={props.closable ? false : true}
      maskClosable={props.closable ? false : true}
      keyboard={props.closable ? false : true}
      cancelButtonProps={{ style: { display: "none" } }}
      open={props.open}
      onCancel={props.onClick}
      destroyOnClose
      footer=""
      className="new-modal"
      centered={true}
      okButtonProps={{ style: { display: "none" } }}
      styles={{
        mask: {
          background: "rgba(103, 169, 72, 0.2)",
          backdropFilter: "blur(4px)",
        },
      }}
    >
      {props.children}
    </Modal>
  );
};

export const ElementSimplemodal = (props) => {
  return (
    <Modal
      closable={props.closable ? false : true}
      keyboard={props.closable ? false : true}
      cancelButtonProps={{ style: { display: "none" } }}
      open={props.open}
      onCancel={props.onClick}
      destroyOnClose
      footer=""
      className="element-modal"
      centered={true}
      okButtonProps={{ style: { display: "none" } }}
      styles={{
        mask: {
          background: "rgba(103, 169, 72, 0.2)",
          backdropFilter: "blur(4px)",
        },
      }}
    >
      {props.children}
    </Modal>
  );
};

export const Notificationmodal = (props) => {
  return (
    <Modal
      closable={props.closable ? false : true}
      keyboard={props.closable ? false : true}
      cancelButtonProps={{ style: { display: "none" } }}
      open={props.open}
      onCancel={props.onClick}
      closeIcon={null}
      destroyOnClose
      footer=""
      // className={`notification-modal ${props.size || 'small'}-modal`}
      className="notification-modal"
      centered={true}
      okButtonProps={{ style: { display: "none" } }}
      styles={{
        mask: {
          background: "rgba(103, 169, 72, 0.2)",
          backdropFilter: "blur(4px)",
        },
      }}
    >
      {props.children}
    </Modal>
  );
};
