import React from "react";
import covenLogo from "../../assets/visaForm/covenlabsFullLogo.svg";
import InfraNova from "../../assets/H1bPetitionImg/infracomLogo.png";
import { FaArrowRight } from "react-icons/fa";
import OretuLogo from "../../assets/H1bPetitionImg/oretuImg-removebg-preview.png";
import RexLogo from "../../assets/H1bPetitionImg/rexLogo-removebg-preview.png";
import { useParams } from "react-router-dom";
import paidhr from "../../assets/H1bPetitionImg/paidhrlogo.jpeg";
import BayardeLogo from "../../assets/H1bPetitionImg/bayardeLogo.JPG";

const data = [
  {
    logo: covenLogo,
    companyName: "Coven Work",
    text: "Coven works is a leading data science and AI company dedicated to helping organizations recruit and build their staff with cutting-edge technology and expertise.",
    link: "https://joincovenworks.com",
    id: "1",
  },
  {
    logo: InfraNova,
    companyName: "Infra Nova Inc",
    text: "InfraNova uses its proprietary technology to drive growth for other technology and non-technology companies . Consulting for a variety of business and government clients in Africa, United Kingdom and the US.",
    link: "https://infranova.us/",
    id: "2",
  },

  {
    logo: BayardeLogo,
    companyName: "Bayarde Services",
    text: "",
    link: "",
    id: "4",
  },
  {
    logo: paidhr,
    companyName: "PaidHr",
    text: "PaidHR is building Africa’s largest database of verified earners, via end-to-end HR and Payroll Technology.",
    link: "https://infranova.us/",
    id: "3",
  },
  // {
  //   logo: OretuLogo,
  //   companyName: "Oretu LLC",
  //   text: "Oretu is a full service digital marketing consulting agency that focuses on helping direct-to-consumer brands scale beyond six and seven figures using comprehensive digital advertising. Our services are designed to drive significant business growth by using data and artificial intelligence in targeting the right audience and optimizing campaigns to drive a positive return on ad spend.",
  //   link: "",
  //   id: "4",
  //   ref: "Oretu LLC",
  // },
  {
    logo: RexLogo,
    companyName: "Rex Technologies Inc",
    text: "Rex Music is a genre-focused streaming company, catering to a global audience of over 2.5 billion who seek a curated experience within their preferred musical genre. Rex is committed to spotlighting emerging talent across diverse genres, offering pathways to global recognition while enriching the musical landscape accessible to audiences worldwide.",
    link: "https://rexapp.org/",
    id: "5",
    ref: "Rex Technologies Inc",
  },
];

const referrerData = [
  {
    logo: covenLogo,
    companyName: "Coven Work",
    text: "Coven works is a leading data science and AI company dedicated to helping organizations recruit and build their staff with cutting-edge technology and expertise.",
    link: "https://joincovenworks.com",
    id: "1",
  },
  {
    logo: InfraNova,
    companyName: "Infra Nova Inc",
    text: "InfraNova uses its proprietary technology to drive growth for other technology and non-technology companies . Consulting for a variety of business and government clients in Africa, United Kingdom and the US.",
    link: "https://infranova.us/",
    id: "2",
  },

  {
    logo: RexLogo,
    companyName: "Intensify Placements Africa",
    text: "Intensify Placements Incorporated is a data science and analysis consulting firm empowering North American enterprises to make smarter, data-driven decisions. We specialize in delivering actionable insights, predictive models, and custom analytics solutions to optimize business performance and drive growth.",
    link: "",
    id: "3",
    ref: "Intensify Placements Africa",
  },
  {
    logo: OretuLogo,
    companyName: "Oretu LLC",
    text: "Oretu is a full service digital marketing consulting agency that focuses on helping direct-to-consumer brands scale beyond six and seven figures using comprehensive digital advertising. Our services are designed to drive significant business growth by using data and artificial intelligence in targeting the right audience and optimizing campaigns to drive a positive return on ad spend.",
    link: "",
    id: "4",
    ref: "Oretu LLC",
  },
  {
    logo: RexLogo,
    companyName: "Rex Technologies Inc",
    text: "Rex Music is a genre-focused streaming company, catering to a global audience of over 2.5 billion who seek a curated experience within their preferred musical genre. Rex is committed to spotlighting emerging talent across diverse genres, offering pathways to global recognition while enriching the musical landscape accessible to audiences worldwide.",
    link: "https://rexapp.org/",
    id: "5",
    ref: "Rex Technologies Inc",
  },
];

const otherCompanies = [
  {
    logo: RexLogo,
    companyName: "Intensify Placements Africa",
    text: "Intensify Placements Incorporated is a data science and analysis consulting firm empowering North American enterprises to make smarter, data-driven decisions. We specialize in delivering actionable insights, predictive models, and custom analytics solutions to optimize business performance and drive growth.",
    link: "https://joincovenworks.com",
    id: "1",
    ref: "Intensify Placements Africa",
  },
  {
    logo: OretuLogo,
    companyName: "Oretu LLC",
    text: "Oretu is a full service digital marketing consulting agency that focuses on helping direct-to-consumer brands scale beyond six and seven figures using comprehensive digital advertising. Our services are designed to drive significant business growth by using data and artificial intelligence in targeting the right audience and optimizing campaigns to drive a positive return on ad spend.",
    link: "https://infranova.us/",
    id: "2",
    ref: "Oretu LLC",
  },
  {
    logo: RexLogo,
    companyName: "Rex Technologies Inc",
    text: "Rex Music is a genre-focused streaming company, catering to a global audience of over 2.5 billion who seek a curated experience within their preferred musical genre. Rex is committed to spotlighting emerging talent across diverse genres, offering pathways to global recognition while enriching the musical landscape accessible to audiences worldwide.",
    link: "https://rexapp.org/",
    id: "3",
    ref: "Rex Technologies Inc",
  },
];

export const FillingCompanies = (props) => {
  let { referrer } = useParams();

  return (
    <div className="bg:relative w-full bg:top-4">
      <p
        className="text-[14px] bg:text-[14px] bg:absolute "
        style={{ color: "#62943B", fontWeight: "600" }}
      >
        {/* {props.companyRef
          ? "About our Filing Company"
          : "H1"} */}
        H-1B Employers and Staffing Companies We Work With
      </p>

      <div
        className={`${
          // referrer
          //   ? "grid grid-cols-1 bg:grid-cols-2 gap-[20px]"
          //   :
          "flex items-center  gap-[20px]"
        }`}
      >
        {props.companyRef
          ? otherCompanies.map(({ logo, id, text, companyName, link, ref }) => {
              return (
                <>
                  {ref == props.companyRef ? (
                    <div
                      style={{
                        background: `#EEFFE6`,
                        color: `${
                          companyName === "Intensify Placements Africa"
                            ? "#1481BA"
                            : "#1481BA"
                        }`,
                        padding: "10px",
                        // width: "50%",
                      }}
                    >
                      <img
                        src={logo}
                        alt="img"
                        className={`${
                          companyName === "Intensify Placements Africa" ||
                          companyName === "Rex Technologies Inc"
                            ? "w-24 h-12 bg-black object-center rounded-sm"
                            : "w-16 h-12 bg-black object-center rounded-sm"
                        }`}
                      />
                      <p
                        style={{
                          // color: `${
                          //   companyName === "Intensify Placements Africa"
                          //     ? " #1481BA"
                          //     : "#B54708"
                          // }`,
                          fontWeight: "400",
                          padding: "10px 0px",
                          color: "#14290A",
                          fontSize: "15px",
                        }}
                      >
                        {text}
                      </p>

                      {/* <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "4px",
                         
                          color: "#66AA48",
                          fontWeight: "600",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          window.open(link, "_blank");
                        }}
                      >
                        Learn more <FaArrowRight />
                      </p> */}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              );
            })
          : // : referrer
            // ? referrerData.map(({ logo, id, text, companyName, link }) => {
            //     return (
            //       <div
            //         style={{
            //           background: `${
            //             companyName === "Coven Work" ? "#ECF8FF" : "#FFFCF5"
            //           }`,
            //           color: `${
            //             companyName === "Coven Work" ? " #1481BA" : "#B54708"
            //           }`,
            //           padding: "10px",
            //         }}
            //       >
            //         {logo ? (
            //           <img
            //             src={logo}
            //             alt="img"
            //             className={`${
            //               companyName === "Intensify Placements Africa" ||
            //               companyName === "Rex Technologies Inc"
            //                 ? "w-24 h-12 bg-black object-center rounded-sm"
            //                 : "w-16 h-12 bg-black object-center rounded-sm"
            //             }`}
            //           />
            //         ) : (
            //           <p className=""></p>
            //         )}

            //         <p
            //           style={{
            //             color: `${
            //               companyName === "Coven Work" ? " #1481BA" : "#B54708"
            //             }`,
            //             fontWeight: "300",
            //             padding: "10px 0px",
            //           }}
            //         >
            //           {text}
            //         </p>

            //         {/* <p
            //           style={{
            //             display: "flex",
            //             alignItems: "center",
            //             gap: "4px",
            //             color: `${
            //               companyName === "Coven Work" ? " #1481BA" : "#B54708"
            //             }`,
            //             fontWeight: "600",
            //             cursor: "pointer",
            //           }}
            //           onClick={() => {
            //             window.open(link, "_blank");
            //           }}
            //         >
            //           Learn more <FaArrowRight />
            //         </p> */}
            //       </div>
            //     );
            //   })
            data.map(({ logo, id, text, companyName, link }) => {
              return (
                <div>
                  <div>
                    {companyName === "PaidHr" ? (
                      <></>
                    ) : (
                      <img
                        src={logo}
                        alt="Coven Works"
                        className={`${
                          companyName === "Coven Work"
                            ? "w-[16rem] h-[3rem] object-contain"
                            : companyName === "Bayarde Services"
                            ? "w-[25rem]  object-contain"
                            : "w-[15rem]  object-contain"
                        }`}
                      />
                    )}{" "}
                    {companyName == "PaidHr" ? (
                      <p className="text-[#154AF5] ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35%"
                          viewBox="0 0 901 250"
                          fill="none"
                        >
                          <path
                            d="M132.96 0.255066C57.8856 -4.4842 -3.99067 57.4612 0.743307 132.62C4.65762 194.598 54.8914 244.888 116.801 248.807C191.876 253.563 253.752 191.601 249.018 116.442C245.104 54.4636 194.87 4.17375 132.96 0.255066ZM133.512 230.637C67.9893 235.812 13.7241 181.469 18.893 115.873C22.9579 64.277 64.7273 22.461 116.266 18.3915C181.789 13.2169 236.054 67.5426 230.885 133.139C226.82 184.735 185.067 226.551 133.512 230.62V230.637Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M206.697 90.6691H189.818C188.798 90.6691 187.811 91.071 187.092 91.7911L121.753 157.203C120.247 158.71 117.805 158.71 116.316 157.203L111.365 152.246C109.859 150.739 107.417 150.739 105.928 152.246L97.4973 160.686C95.9918 162.193 95.9918 164.638 97.4973 166.129L116.316 184.969C117.822 186.476 120.264 186.476 121.753 184.969L132.91 173.799H132.927L209.423 97.2337C211.849 94.8055 210.126 90.6523 206.697 90.6523V90.6691Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M151.093 90.6691H134.214C133.194 90.6691 132.207 91.071 131.488 91.7911L93.9338 129.387C92.4283 130.894 89.9861 130.894 88.4973 129.387L83.5458 124.43C82.0403 122.923 79.5981 122.923 78.1093 124.43L69.6784 132.87C68.1729 134.378 68.1729 136.822 69.6784 138.313L88.4973 157.153C90.0028 158.66 92.4451 158.66 93.9338 157.153L105.075 146H105.091L153.836 97.2337C156.262 94.8055 154.539 90.6523 151.11 90.6523L151.093 90.6691Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M95.6573 90.6691H78.7789C77.7585 90.6691 76.7716 91.071 76.0523 91.7911L66.1996 101.655C64.6941 103.162 62.2518 103.162 60.763 101.655L55.8618 96.7481C54.3562 95.2409 51.914 95.2409 50.4252 96.7481L41.9944 105.188C40.4889 106.696 40.4889 109.14 41.9944 110.631L60.7965 129.454C62.302 130.961 64.7442 130.961 66.233 129.454L77.3905 118.284L98.4007 97.2337C100.826 94.8055 99.1033 90.6523 95.674 90.6523L95.6573 90.6691Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M751.429 113.781V52.1055H770.534V197.468H751.429V132.055H680.824V197.468H661.512V52.1055H680.824V113.781H751.429Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M900.904 197.468H879.515L846.912 141.815H812.648V197.468H793.336V52.1055H851.481C863.941 52.1055 874.601 56.5356 883.461 65.3958C892.321 74.1175 896.751 84.7083 896.751 97.1679C896.751 106.582 893.913 115.165 888.237 122.918C882.423 130.809 875.224 136.277 866.64 139.323L900.904 197.468ZM851.481 70.172H812.648V124.372H851.481C858.68 124.372 864.771 121.741 869.755 116.48C874.878 111.22 877.439 104.782 877.439 97.1679C877.439 89.5537 874.878 83.1854 869.755 78.0631C864.771 72.8024 858.68 70.172 851.481 70.172Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M279.273 51.1956H332.116C345.431 51.1956 356.596 55.7032 365.611 64.7185C374.627 73.7337 379.134 84.8294 379.134 98.0056C379.134 111.182 374.627 122.277 365.611 131.293C356.596 140.308 345.431 144.816 332.116 144.816H298.621V196.827H279.273V51.1956ZM298.621 126.716H332.116C340.299 126.716 346.957 124.011 352.088 118.602C357.359 112.915 359.994 106.05 359.994 98.0056C359.994 89.6838 357.359 82.8183 352.088 77.4092C346.818 72 340.161 69.2955 332.116 69.2955H298.621V126.716Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M469.999 110.696V92.8045H488.099V196.827H469.999V178.935C461.123 192.666 448.016 199.531 430.679 199.531C415.977 199.531 403.564 194.261 393.439 183.72C383.175 173.04 378.044 160.072 378.044 144.816C378.044 129.698 383.175 116.799 393.439 106.119C403.702 95.4397 416.116 90.0999 430.679 90.0999C448.016 90.0999 461.123 96.9654 469.999 110.696ZM432.967 182.056C443.508 182.056 452.315 178.519 459.389 171.445C466.463 164.094 469.999 155.218 469.999 144.816C469.999 134.275 466.463 125.467 459.389 118.394C452.315 111.182 443.508 107.576 432.967 107.576C422.565 107.576 413.827 111.182 406.754 118.394C399.68 125.467 396.144 134.275 396.144 144.816C396.144 155.218 399.68 164.094 406.754 171.445C413.827 178.519 422.565 182.056 432.967 182.056Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M516.354 73.8724C512.887 73.8724 509.974 72.6935 507.616 70.3357C505.258 67.9779 504.08 65.1346 504.08 61.8059C504.08 58.4772 505.258 55.6339 507.616 53.276C509.974 50.7795 512.887 49.5312 516.354 49.5312C519.683 49.5312 522.526 50.7795 524.884 53.276C527.242 55.6339 528.421 58.4772 528.421 61.8059C528.421 65.1346 527.242 67.9779 524.884 70.3357C522.526 72.6935 519.683 73.8724 516.354 73.8724ZM525.3 196.827H507.2V92.8045H525.3V196.827Z"
                            fill="currentColor"
                          ></path>
                          <path
                            d="M630.722 110.696V51.1956H648.822V196.827H630.722V178.935C621.845 192.666 608.738 199.531 591.401 199.531C576.699 199.531 564.286 194.261 554.161 183.72C543.898 173.04 538.766 160.072 538.766 144.816C538.766 129.698 543.898 116.799 554.161 106.119C564.425 95.4397 576.838 90.0999 591.401 90.0999C608.738 90.0999 621.845 96.9654 630.722 110.696ZM593.69 182.056C604.231 182.056 613.038 178.519 620.111 171.445C627.185 164.094 630.722 155.218 630.722 144.816C630.722 134.275 627.185 125.467 620.111 118.394C613.038 111.182 604.231 107.576 593.69 107.576C583.288 107.576 574.55 111.182 567.476 118.394C560.403 125.467 556.866 134.275 556.866 144.816C556.866 155.218 560.403 164.094 567.476 171.445C574.55 178.519 583.288 182.056 593.69 182.056Z"
                            fill="currentColor"
                          ></path>
                        </svg>
                      </p>
                    ) : (
                      <p></p>
                    )}
                  </div>

                  {/* <p
                    style={{
                      color: `${
                        companyName === "Coven Work"
                          ? " #1481BA"
                          : companyName == "PaidHr"
                          ? "#032B86"
                          : "#B54708"
                      }`,
                      fontWeight: "300",
                      padding: "10px 0px",
                    }}
                  >
                    {text}
                  </p> */}
                  {/* <p
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      color: `${
                        companyName === "Coven Work" ? " #1481BA" : "#B54708"
                      }`,
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      window.open(link, "_blank");
                    }}
                  >
                    Learn more <FaArrowRight />
                  </p> */}
                </div>
              );
            })}
        {}
      </div>
    </div>
  );
};
