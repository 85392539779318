import { Routes, Route, useLocation } from "react-router-dom";
// import { Suspense } from "react";
import Home from "./pages/Home";
import VirtualCard from "./pages/VirtualCard";
// eslint-disable-next-line
import GlobalGengCard from "./pages/GlobalGengCard";
import WalletsPage from "./pages/WalletsPage";
import AboutUs from "./pages/AboutUs";
import CareersPage from "./pages/CareersPage";
import Disclosures from "./pages/Disclosures";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Aml from "./pages/Aml";
import MigrationFries from "./pages/MigrationFries";
import MigrationServices from "./pages/MigrationServices";
import NigeriaHome from "./pages/NG/NigeriaHome";
import ZambiaHome from "./pages/ZM/ZambiaHome";
import GhanaHome from "./pages/GH/GhanaHome";
import MexicoHome from "./pages/MX/MexicoHome";
import ScrollToTop from "./components/ScrollToTop";
import MigrationPost from "./pages/MigrationPost";
import Faqs from "./pages/Faqs";
import NewsRoom from "./pages/NewsRoom";
import Team from "./pages/Team";
import TestimonialPage from "./pages/TestimonialPage";
import FinancialServices from "./pages/FinancialServices";
import CardsPage from "./pages/CardsPage";
import ReactGA from "react-ga";
import MigrationFeesPayment from "./components/MerchantFees/MigrationFeesPayment";
import ElementLandingPage from "./components/ElementLandingPage/elementlandingpage";
import FlightLandingPage from "./components/FlightLandingPage/flightlandingpage";
import "./assetlinks.json";
// import MigrationFeesPayment from "./components/MigrationFeesPayment";

import { QueryClient, QueryClientProvider } from "react-query";
import { useEffect, useState } from "react";
import O1visa from "./pages/O1visa";
import EB1visa from "./pages/EB1visa";
import EBNIW from "./pages/EBNIWvisa";
import Schools from "./pages/Schools";
import IframeComponent from "./components/Iframe.mia";
import FloatingButton from "./components/floatingbutton/FloatingButton";
// import Miai from "./pages/Miai";
import MiaHome from "./pages/MiaHome";
import ATS from "./pages/ATS";
import NITDA from "./pages/NITDA";
import MiaiPrivacyPolicy from "./components/Miai/PrivacyPolicy";
import Tos from "./components/Miai/Tos";
import { ClaritycallVisa } from "./components/ClarityCall/claritycallVisa";
import { Events } from "./components/Events/Events";
import VGE from "./pages/VGE";
import { Partnership } from "./components/Partnership/Partnership";
import { H1bPetition } from "./components/H1b-petition-form/H1bPetition";
import { ProsperConf } from "./components/ProsperConference/ProsperConf";
import H1BVisa from "./pages/H1BVisa";
import Miailanding from "./components/Miai/miailanding";
import MiaiPolicies from "./components/Miai/miaiPolicies";
import { OnsuccessH1bPetition } from "./components/H1b-petition-form/onSucess/home";
import ClarityCallHome from "./components/ClarityCall/claritycallHome";
import ClarityCallHomeStaging from "./components/ClarityCall/claritycallHomeStaging";
import { ClaritycallVisaStaging } from "./components/ClarityCall/claritycallVisaStaging";
import CanadianCareGivVisa from "./components/CanadianCareGiverVisa/CanadianCareGivVisa";
import UkTechVisa from "./components/UkTechNationVisa/UkTechVisa";
import { MaintenancePage } from "./constant/maintenancePage";
import MortgageAndRent from "./components/mortgageAndRent/mortgageAndRent";
import AIpoweredHome from "./pages/NewHome";
import { SuccessStory } from "./components/SuccessStory/SuccessStory";
import NotFound from "./views/notfound";
import { ElitePass } from "./components/Elite-Pass/ElitePass";
import KenyaHome from "./pages/GH/KE/KenyaHome";
// import { ResetPassword } from "./components/AusLogIn/CreateNewPassword/ResetPassword";
import CaseStudyPage from "./components/CaseStudyPage";
const queryClient = new QueryClient();
ReactGA.initialize("G-JH1PW3QEQP");

// ReactGA.initialize("G-7E2M5N2KPP");

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [window.location.pathname]);

  const [showIframe, setShowIframe] = useState(false);
  const [height, setHeight] = useState("10vh");
  const [smallheight, setSmallHeight] = useState("30vh");
  const mylocation = useLocation();
  const { pathname } = mylocation;
  const splitLocation = pathname.split("/");

  // MIAI state
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [answer, setAnswer] = useState("");
  const [minimize, setMinimize] = useState(false);
  const [chatId, setChatId] = useState("");
  const [typewritermsg, setTypewriterMsg] = useState("");
  const [loading, setLoading] = useState(false);

  const addMessage = (text, isUser = true) => {
    const newMessage = {
      text: typeof text === "string" ? text : "", // Initially set to empty if not a string
      isUser,
      examTime: messages.length === 0 ? new Date() : undefined,
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);

    // Update the message with the actual text when the response arrives
    if (typeof text !== "string") {
      console.log(text);
      setTimeout(() => {
        const actualText = text; // Extract text from Typed component
        setMessages((prevMessages) =>
          prevMessages.map((message, index) =>
            index === prevMessages.length - 1 // Update the last message
              ? { ...message, text: actualText }
              : message
          )
        );
      }, 20); // Adjust timeout as needed
    }
  };

  // const [paid, setPaid] = useState(false);

  const paid = localStorage.getItem("mainStackPayment");

  // if (event.data.status === "mainstack-payment-success") {
  //   localStorage.setItem("mainStackPayment", event.data.status);

  //   navigate("/h1b/registration");

  // }

  // disable inspection tab on console
  // var addHandler = function (element, type, handler) {
  //   if (element.addEventListener) {
  //     element.addEventListener(type, handler, false);
  //   } else if (element.attachEvent) {
  //     element.attachEvent("on" + type, handler);
  //   } else {
  //     element["on" + type] = handler;
  //   }
  // };

  // var preventDefault = function (event) {
  //   if (event.preventDefault) {
  //     event.preventDefault();
  //   } else {
  //     event.returnValue = false;
  //   }
  // };

  // addHandler(window, "contextmenu", function (event) {
  //   preventDefault(event);
  // });
  // document.onkeydown = function (event) {
  //   if (event.keyCode == 123) {
  //     return false;
  //   } else if (event.ctrlKey && event.shiftKey && event.keyCode == 73) {
  //     return false;
  //   }
  // };

  const validRoutes = [
    "kenya-home",
    "global-immigrants-platform",
    "virtual-card",
    "ats",
    "nitda",
    "graduate-trainee",
    "globalgeng-card",
    "wallets",
    "about-us",
    "careers",
    "team",
    "tos",
    "privacy-policy",
    "aml",
    "migration-fries",
    "migration-services",
    "financial-services",
    "cards",
    "testimonials",
    "faqs",
    "news-room",
    "merchant-fees",
    "ng",
    "gh",
    "zm",
    "mx",
    "flight",
    //  "O1visa",
    //  "EB1visa",
    //  "EB2NIWvisa",
    //  "H1Bvisa",
    "schools_we_support",
    "miai",
    "miai/privacy-policy",
    "miai/tos",
    "partnership",
    "events",
    //  "care",
    "maintenance",
    "technation",
    "migration/housing",
    //  "h1b-lottery-payment",
    //  "h1b-lottery",
    "prosper-conference-e150824",
    //  "h1b/registration",
    "claritycallhome",
    "claritycallhome-staging",
  ];

  return (
    <>
      {showIframe && (
        <div className="popup">
          {<IframeComponent onClick={() => setShowIframe(false)} />}
        </div>
      )}
      {
        // splitLocation[1] === "element" ||
        // splitLocation[1] === "care" ||
        // splitLocation[1] === "O1visa" ||
        // splitLocation[1] === "EB1visa" ||
        // splitLocation[1] === "EB2NIWvisa" ||
        // splitLocation[1] === "maintenance"
        validRoutes.includes(splitLocation[1]) || !splitLocation[1] ? (
          <Miailanding
            height={height}
            setHeight={setHeight}
            smallheight={smallheight}
            setSmallHeight={setSmallHeight}
            inputText={inputText}
            setInputText={setInputText}
            messages={messages}
            setMessages={setMessages}
            answer={answer}
            setAnswer={setAnswer}
            typewritermsg={typewritermsg}
            setTypewriterMsg={setTypewriterMsg}
            loading={loading}
            setLoading={setLoading}
            addMessage={addMessage}
          />
        ) : (
          <></>
        )
      }

      <QueryClientProvider client={queryClient}>
        <ScrollToTop />

        <Routes>
          <Route
            path="/"
            element={
              <AIpoweredHome
                height={height}
                setHeight={setHeight}
                smallheight={smallheight}
                setSmallHeight={setSmallHeight}
                inputText={inputText}
                setInputText={setInputText}
                messages={messages}
                setMessages={setMessages}
                answer={answer}
                setAnswer={setAnswer}
                typewritermsg={typewritermsg}
                setTypewriterMsg={setTypewriterMsg}
                loading={loading}
                setLoading={setLoading}
                addMessage={addMessage}
              />
            }
          />
          {/* <Route path="/global-immigrants-platform" element={<Home />} /> */}
          <Route path="*" element={<NotFound />} />

          <Route path="/virtual-card" element={<VirtualCard />} />
          <Route path="/ats" element={<ATS />} />
          <Route path="/nitda" element={<NITDA />} />
          <Route path="/graduate-trainee" element={<VGE />} />
          <Route path="/globalgeng-card" element={<GlobalGengCard />} />
          <Route path="/wallets" element={<WalletsPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/team" element={<Team />} />
          <Route path="/tos" element={<Disclosures />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/aml" element={<Aml />} />
          <Route path="/migration-fries" element={<MigrationFries />} />
          <Route path="/migration-services" element={<MigrationServices />} />
          <Route path="/financial-services" element={<FinancialServices />} />
          <Route path="/cards" element={<CardsPage />} />
          <Route path="/testimonials" element={<TestimonialPage />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/news-room" element={<NewsRoom />} />
          <Route path="/merchant-fees" element={<MigrationFeesPayment />} />
          <Route path="/ng" element={<NigeriaHome />} />
          <Route path="/gh" element={<GhanaHome />} />
          <Route path="/zm" element={<ZambiaHome />} />
          <Route path="/mx" element={<MexicoHome />} />
          <Route path="/migration-post/:postId" element={<MigrationPost />} />
          <Route path="/flight" element={<FlightLandingPage />} />
          {/* <Route path="*" element={<ErrorPage />} /> */}
          <Route path="/O1visa" element={<O1visa />} />
          <Route path="/EB1visa" element={<EB1visa />} />
          <Route path="/EB2NIWvisa" element={<EBNIW />} />
          <Route path="/H1Bvisa" element={<H1BVisa />} />
          <Route path="/schools_we_support" element={<Schools />} />
          <Route path="/miai" element={<MiaHome />} />
          <Route path="/miai/privacy-policy" element={<MiaiPrivacyPolicy />} />
          <Route path="/miai/tos" element={<Tos />} />
          <Route path="/partnership" element={<Partnership />} />
          <Route path="/events" element={<Events />} />
          <Route path="/care" element={<CanadianCareGivVisa />} />
          <Route path="/maintenance" element={<MaintenancePage />} />
          <Route path="/technation" element={<UkTechVisa />} />
          <Route path="/migration/housing" element={<MortgageAndRent />} />
          <Route path="/success-story" element={<SuccessStory />} />
          <Route path="/kenya-home" element={<KenyaHome />} />
          <Route path="/elite-pass" element={<ElitePass/>}/>
          <Route path="/case-study/:brand" element={<CaseStudyPage />} />

          <Route
            path="/h1b-lottery-payment"
            element={
              // paid === "mainstack-payment-success" ? (
              //   <OnsuccessH1bPetition />
              // ) : (
              <H1bPetition />
              // )
            }
          />

          <Route
            path="/h1b-lottery"
            element={
              // paid === "mainstack-payment-success" ? (
              //   <OnsuccessH1bPetition />
              // ) : (
              <H1bPetition />
              // )
            }
          />

          <Route
            path="/h1b-lottery-payment/:referrer"
            element={
              // paid === "mainstack-payment-success" ? (
              //   <OnsuccessH1bPetition />
              // ) : (
              <H1bPetition />
              // )
            }
          />

          <Route path="/prosper-conference-e150824" element={<ProsperConf />} />
          <Route path="/h1b/registration" element={<OnsuccessH1bPetition />} />
          <Route path="/claritycallhome" element={<ClarityCallHome />} />
          <Route
            path="/claritycallhome-staging"
            element={<ClarityCallHomeStaging />}
          />

          <Route
            path="/clarity-session/:visaType"
            element={<ClaritycallVisa />}
          />
          <Route
            path="/clarity-session-staging/:visaType"
            element={<ClaritycallVisaStaging />}
          />
        </Routes>

        <Routes>
          <Route path="/element" element={<ElementLandingPage />} />
          <Route
            path="/element/:referralcode"
            element={<ElementLandingPage />}
          />
          {/* /element?referralcode=456677 */}
          <Route path="/config" element={"./assetlinks.json"} />
        </Routes>
        {/* </Suspense> */}
      </QueryClientProvider>
    </>
  );
}

export default App;
