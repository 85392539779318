import React, { useState } from 'react'
import { FiPlus } from "react-icons/fi"
import { FiMinus } from "react-icons/fi";
import { EliteQuestion } from './EliteTestimonials';

export const EliteQues = () => {
    const [hideAnswer, setHideAnswer] = useState(null)

    const toggleAnswer = (index) => {
        if(hideAnswer === index) {
            setHideAnswer(null)
        } else {
            setHideAnswer(index)
        }
    }
  return (
    <div className=' pt-[6%]'>
    <div className='bg-[#060E42] mt-4 md:mt-10 text-[#FFFFFF] xlg:mt-10 p-4 xlg:p-16'>
        <div className='grid sm:grid-cols-2 sm:p-2 md:p-4 xl:p-8 h-full mt-6 md:mt-0'>
            <div className='flex h-full'>
                <div className='flex-grow'> 
                <h1 className='text-[26px] sm:text-[30px] w-[100%] sm:w-[80%] pb-6'>Some of the things you may want to know</h1>
                </div>
            </div>
            <div className='grid h-full'>
                <div className='flex h-full'>
                    <div className='flex-grow'>
                        {EliteQuestion.map(({title, ans, border}, index) => {
                            return <div key={index}  className={`${index + 1 === EliteQuestion?.length ?  '' : 'border-b-2'}   pb-4 mb-4`}>
                                <div className='flex items-center justify-between'>
                                    <div className='block w-[74%]'>
                                        <h6 className='text-[18px] sm:text-[20px]'>{title}</h6>
                                        {hideAnswer=== index && <p className='py-3'>{ans}</p>}
                                    </div>
                                    <div className=''>
                                        {hideAnswer === index ? (
                                        <FiMinus className='cursor-pointer sm:text-[30px] md:text-[35px] xl:text-[40px]'
                                        onClick={() => toggleAnswer(index)}
                                        />
                                        ) : (
                                        <FiPlus className='cursor-pointer text-[30px] md:text-[35px] xl:text-[40px]'
                                        onClick={() => toggleAnswer(index)}
                                        /> 
                                        )}
                                    </div>
                                </div>
                        </div>
                        })}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
