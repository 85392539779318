import React, { useState, useEffect } from "react";
import { useTransition } from "react-spring";
import { useSpring, animated } from "react-spring";
import eliteGlobe from '../../assets/ElitePass/Object.svg'
import flash from '../../assets/ElitePass/flash.png'
import emojiNormal from '../../assets/ElitePass/emoji-normal.png'
import errorsvg from "../../assets/logo-icons/oops.svg";
import globeImg from '../../assets/ElitePass/global.png'
import { Notificationmodal, Simplemodal } from "../helpers/modal/simplifiedmodal";
import axios from "../api/axios";
import { Success } from "../helpers/success/success";
import { Link as AnchorLink } from "react-scroll";

export const ElitePassHero = () => {
  const [index, setIndex] = useState(0);
  const [successModal, setSuccessModal] = useState(false);
  const [showInitialModal, setShowInitialModal] = useState(false);
  const [showEliteServiceModal, setShowEliteServiceModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [elitePassId, setElitePassId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [modalStep, setModalStep] = useState("elitePassId"); 
  const [forgotEmail, setForgotEmail] = useState("");
  const [isSubmittingForgotId, setIsSubmittingForgotId] = useState(false);
  const [isValidatingId, setIsValidatingId] = useState(false);
  const [associatedEmail, setAssociatedEmail] = useState("");

  const [formData, setFormData] = useState({
    email: "",
    flightDate: "",
    bookingReference: "",
    from: "",
    to: "",
    airline: "",
    airportLocation: "",
    uniqueId: "",
  });

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleForgotIdClick = () => {
    setModalStep("emailInput");
  };
  

  // const handleModalClick = () => setShowInitialModal(true);
  const handleModalClick = () => {
    setShowInitialModal(true);
    setModalStep("elitePassId"); // Ensure it starts at first step
    setElitePassId(""); // Clear any previous ID
  };
  // const handleCloseInitialModal = () => setShowInitialModal(false);
  const handleCloseInitialModal = () => {
    setShowInitialModal(false);
    setModalStep("elitePassId"); // Reset to first step
    setElitePassId(""); // Clear previous ID
  };

  const handleOptionSelect = (value) => {
    setSelectedOption(value);
  };

  const handleProceed = () => {
    if (selectedOption === "New Elite Traveler") {
      handleCloseInitialModal();
      document.getElementById("EliteFormSection").scrollIntoView({ behavior: "smooth" });
    } else if (selectedOption === "Existing User") {
      handleCloseInitialModal();
      setShowEliteServiceModal(true);
    }
  };

  const handleCloseEliteServiceModal = () => {
    setShowEliteServiceModal(false);
    setFormData({
      email: "",
      flightDate: "",
      bookingReference: "",
      from: "",
      to: "",
      airline: "",
      uniqueId: "",
      airportLocation: "",
    });
  };
  

  const handleSuccessClick = () => {
    setSuccess(true)
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    
  };

  const isFormComplete = 
  formData.email?.trim() && 
  isValidEmail(formData.email) && 
  formData.flightDate?.trim() && 
  formData.bookingReference?.trim() && 
  formData.from?.trim() && 
  formData.to?.trim() && 
  formData.airline?.trim() && 
  formData.uniqueId?.trim() &&
  formData.airportLocation?.trim();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Reset states before new submission
    setError(false);
    setSuccess(false);
    setErrorMessage("");
    
    // Validate form first
    if (!isFormComplete) {
      setError(true);
      setErrorMessage("Please fill all required fields");
      setSuccessModal(true);
      return;
    }
  
    if (!isValidEmail(formData.email)) {
      setError(true);
      setErrorMessage("Please enter a valid email address");
      setSuccessModal(true);
      return;
    }
  
    try {
      const response = await axios.post("/general-services/book-ticket", {
        ...formData,
        uniqueId: elitePassId.trim()
      }, {
        headers: { "Content-Type": "application/json" }
      });
  console.log(response, 'response booking');
  
      if (response?.data?.message) {
        setSuccess(true);
        setErrorMessage("Your booking was submitted successfully!");
      } else {
        throw new Error(response.data.message || "Booking failed");
      }
    } catch (error) {
      setSuccess(false);
      setError(true);
      setErrorMessage(
        error.response?.data?.message || 
        "Failed to submit booking. Please try again."
      );
    } finally {
      setSuccessModal(true);
    }
  };

  const handleSelectElitePass = async () => {
    if (!elitePassId.trim()) return;
  
    setIsValidatingId(true);
    
    try {
      const response = await axios.post("/general-services/searchUniqueId", { 
        uniqueId: elitePassId.trim() 
      });
  
      if (response.data.email) {
        setAssociatedEmail(response.data.email);
        setFormData(prev => ({
          ...prev,
          email: response.data.email,
          uniqueId: elitePassId.trim()
        }));
        setModalStep("flightDetails");
      } else {
        throw new Error("Invalid ElitePass ID");
      }
    } catch (error) {
      setError(true);
      setSuccess(false);
      setErrorMessage("Invalid ElitePass ID. Please check and try again.");
      setSuccessModal(true);
    } finally {
      setIsValidatingId(false);
    }
  };

  // forgot id
  const handleForgotIdSubmit = async () => {
    if (!isValidEmail(forgotEmail)) {
      setError(true);
      setErrorMessage("Please enter a valid email address");
      setSuccessModal(true);
      return;
    }
  
    setIsSubmittingForgotId(true);
  
    try {
      const response = await axios.post('/general-services/get-uniqueId', {
        email: forgotEmail.trim()
      });
  console.log(response);
  
      setSuccess(true);
      setErrorMessage("Your ElitePass ID has been sent to your email");
      setSuccessModal(true);
      setModalStep("elitePassId"); 
      
    } catch (error) {
      setError(true);
      setErrorMessage(
        error.response?.data?.message || 
        "Failed to send ID. Please try again later."
      );
      setSuccessModal(true);
    } finally {
      setIsSubmittingForgotId(false);
      setSuccessModal(true);
    }
  };
 
  const texts = ["Experience Luxury", "Elevate your journey", "Enjoy Seamless Travel", "Skip the Line"];

const transitions = useTransition(index, {
    from: { opacity: 0, transform: "translateY(-100px)" },
    enter: { opacity: 1, transform: "translateY(20px)" },
    leave: { opacity: 0, transform: "translateY(50px)" }, 
    config: { tension: 200, friction: 20 },
  });


useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % texts.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const textSlide = useSpring({
    from: { transform: "translateY(100%)", opacity: 0 },
    to: { transform: "translateY(0)", opacity: 1 },
    config: { tension: 170, friction: 20 }
  });

  const imageMove = useSpring({
    from: { transform: "translateY(0px)" },
    to: async (next) => {
      while (true) {
        await next({ transform: "translateY(-10px)" });
        await next({ transform: "translateY(10px)" });
      }
    },
    config: { tension: 50, friction: 10 }
  });

  const bgAnimation = useSpring({
    from: { backgroundPosition: "0% 0%" },
    to: { backgroundPosition: "100% 100%" },
    loop: { reverse: true },
    config: { duration: 5000 },
  });

  const imageAnimation = useSpring({
    from: { transform: "translateY(0px)" },
    to: { transform: "translateY(10px)" },
    loop: { reverse: true },
    config: { duration: 3000 },
  });

  const handleSignUpClick = () => {
    handleCloseInitialModal(); 
    document.getElementById("EliteFormSection").scrollIntoView({ behavior: "smooth" });
  };
  
  const SimpleSpinner = () => (
    <div className="inline-block animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
  );
 
  return (
    <>
    {/* success modal for forgotten password and book a trip */}
  <Notificationmodal open={successModal} onClose={() => setSuccessModal(false)}>
    <Success
      image={success ? undefined : errorsvg}
      type={success ? "success" : "error"}
      title={success ? "Booking Successful" : "Booking Failed"}
      subtitle={errorMessage}
      button={success ? "Okay, thank you" : "Try Again"}
      onClick={() => {
        setSuccessModal(false);
        if (success) {
          // Reset form on success
          setModalStep("elitePassId");
          setElitePassId("");
          setAssociatedEmail("");
          setFormData({
            email: "",
            flightDate: "",
            bookingReference: "",
            from: "",
            to: "",
            airline: "",
            airportLocation: "",
            uniqueId: "",
          });
          setElitePassId("");
          setAssociatedEmail("");
        }
      }}
    />
  </Notificationmodal>

   {/* Initial Selection Modal */}
   <Notificationmodal open={showInitialModal} onClick={() => handleCloseInitialModal(false)} width={500}>
    {modalStep === 'elitePassId' &&(
      <>
      <h2 className="text-[22px] md:text-3xl font-bold text-gray-800">
          Request <span className="text-green-600">Elite Service</span>
        </h2>
        <p className="text-gray-800 font-semibold text-[14px]">
          Please input your ElitePass ID
        </p>
        {/* Sign-Up Link */}
        <p className="text-[13px] mt-[2px] text-gray-600">
          Don’t have an ID?{" "}
          <AnchorLink
            spy={true}
            smooth={true}
            // to="EliteFormSection"
            onClick={handleSignUpClick} 
            className="text-green-600 text-[12px] font-medium hover:underline"
            >
           Sign up here
        </AnchorLink>
        </p>

        {/* Input Field */}
        <div className="relative mt-5">
          <label className="absolute top-[-10px] left-4 bg-white text-[12px] px-2 font-semibold text-gray-700">
            ElitePass ID
          </label>
          <input
            type="text"
            placeholder="ELP1128757488"
            value={elitePassId}
            onChange={(e) => setElitePassId(e.target.value)}
            className="w-full p-3 border border-gray-300 rounded-lg placeholder:text-[12px] text-[14px] outline-none"
          />
        </div>

        {/* Submit Button */}
          <button
            onClick={handleSelectElitePass}
            disabled={!elitePassId.trim() || isValidatingId}
            className={`mt-6 w-full py-3 rounded-lg text-white transition flex justify-center items-center 
              ${elitePassId.trim() ? 'bg-green-600 hover:bg-green-700' : 'bg-gray-400 cursor-not-allowed'}`}
          >
            {isValidatingId ? (
              <span className="flex items-center justify-center w-full">
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Verifying...
              </span>
            ) : (
              "Select"
            )}
          </button>

        <p className="text-[12px]">Forgot your ID?&nbsp;
          <span 
          onClick={handleForgotIdClick}
          className="text-[#67A948] cursor-pointer">
            Click here
        </span></p>
      </>
    )}
   
   {/* flight details */}
    {modalStep === 'flightDetails' && (
      <form onSubmit={handleSubmit}>
      <h2 className="text-[27px] font-bold text-gray-800">
          Request <span className="text-[#67A948]">Elite Service</span>
        </h2>
        <p className="xxl:text-[24px] text-18px font-bold mb-0 sm:mb-7">
          Enter your flight details below and we'll handle the rest
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="relative flex flex-1 flex-col md:col-span-2">
        <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">Email</label>
            <input type="text" 
            placeholder="you@gmail.com"
            name="email" 
            value={associatedEmail || formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            // onChange={handleChange}
            className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none"
            />
        </div>
          <div className="relative flex flex-col">
            <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">Flight Date</label>
            <input type="text" 
            placeholder="9/12/2025" 
            name="flightDate" 
            value={formData.flightDate} 
            onChange={handleChange}
            onPaste={(e) => handleChange(e)}   
            className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none"
            />
          </div>
  
          <div className="relative flex flex-col">
            <label className="absolute text-gray-700 bg-[#F7F8FA]  px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">Booking Reference</label>
            <input type="text" 
            placeholder="AW3478RE" 
            name="bookingReference" 
            value={formData.bookingReference} 
            onChange={handleChange}  
            className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none" />
          </div>
  
          <div className="relative flex flex-col">
            <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">From</label>
            <input type="text" 
            placeholder="Lagos" 
            name="from" 
            value={formData.from} 
            onChange={handleChange}  
            className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none" />
          </div>
  
          <div className="relative flex flex-col">
            <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">To</label>
             <input type="text" 
             placeholder="Abuja" 
             name="to" 
             value={formData.to} 
             onChange={handleChange}  
             className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none"/>
          </div>
  
          <div className="relative flex flex-col">
            <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold"> Airline</label>
             <input type="text" 
             placeholder="Airline" 
             name="airline" 
             value={formData.airline} 
             onChange={handleChange}  
             className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none"/>
          </div>
  
          <div className="relative flex flex-col">
            <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold"> Airport Location</label>
             <input type="text" 
             placeholder="Ikeja" 
             name="airportLocation" 
             value={formData.airportLocation} 
             onChange={handleChange}  
             className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none"/>
          </div>
        </div>

        <div className="mt-6 flex justify-between">
            <button
            type="button"
            onClick={() => setModalStep("elitePassId")}
            className="py-3 px-[64px] rounded-lg bg-[#67A948] text-[#ffffff] transition"
          >
            Back
          </button>

         <button 
            // onClick={handleSuccessClick}
            type="submit"
            disabled={!isFormComplete}
            className={`py-3 px-[64px] rounded-lg text-white transition 
              ${isFormComplete ? 'bg-[#67A948] hover:bg-green-700' : 'bg-gray-400 cursor-not-allowed'}`}
          >
            Book Trip
          </button>
        </div>
        </form>
    )}

    {/* forgot password */}
    {modalStep === 'emailInput' && (
      <div>
      <h2 className="text-[27px] font-bold text-gray-800">
        Request <span className="text-[#67A948]">Elite Service</span>
      </h2>
      <p className="xxl:text-[24px] text-18px font-bold mb-0 sm:mb-7">
      Please input your email address and we will send you a link to recover your ID
      </p>
      <div className="relative flex flex-1 flex-col md:col-span-2">
        <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">Email</label>
        <input
          type="email"
          placeholder="your@email.com"
          name="email"
          value={forgotEmail}
          onChange={(e) => setForgotEmail(e.target.value)}
          disabled={isSubmittingForgotId}
          className="p-3 border border-[#D0D5DD] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] mb-[0px] outline-none"
        />
         {forgotEmail && !isValidEmail(forgotEmail) && (
          <p className="text-red-500 text-[12px] ">Please enter a valid email address.</p>
        )}
      </div>
      <div className="mt-6 ">
        <button
         onClick={handleForgotIdSubmit}
        disabled={!forgotEmail || isSubmittingForgotId}
          className={`py-3 px-[64px] w-full  rounded-lg text-white transition 
            ${forgotEmail && isValidEmail(forgotEmail) ? 'bg-[#67A948] hover:bg-green-700' : 'bg-gray-400 cursor-not-allowed'}`}
        >
          {isSubmittingForgotId ? (
          <>
            <SimpleSpinner /> Sending...
          </>
        ) : (
          'Submit'
        )}
        </button>
        <p className="text-[12px]">Remember your ID?&nbsp;
          <span 
          onClick={() => setModalStep("elitePassId")}
          className="text-[#67A948] cursor-pointer">
             Login here
        </span></p>
      </div>
    </div>
    )}
    </Notificationmodal>
   
    <div className="lg:flex lg:items-center gap-[86px] relative bg-cover bg-center h-full mt-[12%] sm:mt-[6%] xl:px-[6%] px-[4%] pt-[2rem] sm:pt-[3.5rem] md:pt-[6rem] pb-[2rem] sm:pb-[4rem] lg:pb-[8rem] text-[#ffffff]"
    style={{
        background: `
          radial-gradient(circle at 25% 20%, #2B5219 5%, rgba(76, 175, 80, 0) 30%), 
          radial-gradient(circle at 20% 75%, #1f0e3f 20%, rgba(27, 20, 63, 0) 30%), 
          radial-gradient(circle at 80% 100%, #263D6E 15%, rgba(47, 42, 98, 0) 40%), 
          radial-gradient(circle at 80% 25%, #450C0C 12%, rgba(178, 34, 34, 0) 40%), 
          linear-gradient(to bottom, #1E1E2F, #261C32)
        `,
        backgroundSize: "400% 400%",
        animation: "movingBackground 10s infinite linear"
      }}
      >
      
      <style>
        {`
          @keyframes moveBackground {
            0% { background-position: 0% 0%; }
            50% { background-position: 100% 100%; }
            100% { background-position: 0% 0%; }
          }
        `}
      </style>

      {/* Badge */}
      <div className="block">
        <div className="inline-flex items-center gap-2 bg-[#518C36] text-[#DFFFD0] text-[12px] rounded-[10px] py-[4px] px-[12px] opacity-[0.7] mt-9 sm:mt-8 lg:mt-[-6%] ">
          <span className="w-1 h-1 bg-white rounded-full"></span>
          <p className=""> Premium Airport Experience</p>
        </div>

        <div className="relative h-[130px] xlg:h-[80px] overflow-hidden w-full max-w-full lg:max-w-[28rem] xl::max-w-[40rem] xlg:max-w-[50rem] xlg:whitespace-nowrap">
        {transitions((style, i) => (
            <animated.h1 style={style} className="absolute  md:text-[40px] text-[35px] font-bold">
            <span className="text-[#67A948]">{texts[i]}</span> with Elite Pass.
            </animated.h1>
        ))}
        </div>

        <p className="vlg:text-[24px] xxl:text-[20px] text-18px mt-0 md:mt-4 max-w-full lg:max-w-[28rem] xl::max-w-[40rem] xlg:max-w-[46rem]">
          Your personal gateway to stress-free, luxury airport experiences worldwide.
          Join an exclusive community of travelers who demand excellence at every step of their journey.
        </p>

        {/* Button */}
        <div className="flex items-center gap-2 sm:gap-4">
        <AnchorLink
            spy={true}
            smooth={true}
            to="EliteFormSection"
            className="mt-8 sm:mt-12 bg-[#67A948] hover:bg-green-700 text-white py-3 md:py-4 px-4 sm:px-14 rounded-lg text-[14px] cursor-pointer transition">
           Get Your Elite Pass
        </AnchorLink>
        <button 
          onClick={handleModalClick}
          className="mt-8 sm:mt-12 border border-[#67A948] hover:border-green-700 text-[#67A948] py-3 md:py-4 px-4 sm:px-14 rounded-lg text-[14px] transition">
          Book a trip
        </button>
        </div>

        {/* Icons */}
        <div className="flex items-center gap-4 sm:gap-6 mt-8 sm:mt-12 text-[#DFFFD0] vlg:text-[20px] xxl:text-[18px] text-12px">
          <div className="flex items-center gap-1 sm:gap-2">
            <img src={flash} className="w-[18px]" alt=""/>
            <p>Instant Access</p>
          </div>
          <div className="flex items-center gap-1 sm:gap-2">
            <img src={emojiNormal} className="w-[18px]" alt=""/>
            <p>Global Coverage</p>
          </div>
          <div className="flex items-center gap-1 sm:gap-2">
            <img src={globeImg} className="w-[18px]" alt=""/>
            <p>24/7 Support</p>
          </div>
        </div>
      </div>

      {/* Globe Image with smooth movement animation */}
      <div className="relative flex justify-center items-center mt-[1rem] sm:mt-[3rem] lg:hidden">
        <animated.img
          src={eliteGlobe}
          alt="Globe"
          style={imageAnimation}
          className=" lg:absolute lg:bottom-[80px] xlg:bottom-[50px] lg:right-10 w-63"
        />
      </div>
      <div className="hidden lg:block">
        <animated.img
          src={eliteGlobe}
          alt="Globe"
          style={imageAnimation}
          className="lg:absolute lg:bottom-[80px] xlg:bottom-[50px] lg:right-10 w-63"
        />
      </div>
    </div>
    </>
  );
};
