import React, { useState } from "react";
import axios from "../api/axios";
import errorsvg from "../../assets/logo-icons/oops.svg";
import { Simplemodal } from "../helpers/modal/simplifiedmodal";
import { Success } from "../helpers/success/success";
import { EmailUpdateForm } from "../EmailUpdateForm";

export const EliteEmailUpdate = () => {
  const [successModal, setSuccessModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [email, setEmail] = useState("");


  const handleSubmit = async ( email, setError) => {
    try {
      const response = await axios.post(
        "/v1/general-services/email-workflow",
        { email, origin: "ElitePass" }, 
        { headers: { "Content-Type": "application/json" } }
      );
  
      setSuccess(true);
      setSuccessModal(true);
      setEmail(""); 
    } catch (error) {
      setSuccess(false);
      setErrorMessage(error.response?.data?.message || "Something went wrong. Please try again.");
      setSuccessModal(true);
    }
  };
  
  return (
    <div>
       <Simplemodal open={successModal} onClick={() => setSuccessModal(false)}>
        {success ? (
          <Success
            title="Form submitted successfully"
            subtitle="Thank you for providing your details. You will receive a confirmation email shortly."
            button="Okay, thank you"
            // onClick={() => setSuccessModal(false)}
            onClick={() => {
              setSuccessModal(false);
              setEmail("");  
            }}
          />
        ) : (
          <Success
            image={errorsvg}
            type="error"
            title="Error submitting form"
            subtitle={errorMessage}
            button="Retry"
            onClick={() => setSuccessModal(false)}
          />
        )}
      </Simplemodal>

      <div className="pb-8 sm:pb-[0px]"> 
      <EmailUpdateForm
        title="Ready to Transform Your Travel Experience?"
        subtitle="Join thousands of travelers who have elevated their airport experience with Vesti Elite Pass."
        onSubmit={handleSubmit}
        email={email}
        setEmail={setEmail} 
      />
      </div>
      
    </div>
  );
};


