import React from "react";

const StoriesList = ({ title, stories }) => {
  return (
    <div className="bg-black p-6 rounded-md text-white">
      <h2 className="text-[18px] font-bold text-center">{title}</h2>
      <div className="mt-6 space-y-4">
        {stories.map((story, index) => (
          <div
            key={index}
            className="flex items-center border border-green-500 rounded-lg p-1 gap-4"
          >
            <img src={story.icon} alt="icon" className="w-[64px]" />
            <div className="flex flex-col">
              <p className="text-[12px]">{story.title}</p>
              <div className="flex items-center gap-2">
                {/* <button className="text-green-500">▶</button> */}
                <img src={story.waveform} alt="waveform" className="h-6" />
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* <p className="text-center mt-6 text-sm">Listen To More Podcasts ↗</p> */}
    </div>
  );
};

export default StoriesList;
