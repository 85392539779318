import React, { useState, useEffect } from "react";
import { H1bPetitionHero } from "./H1bPetHero";
import NavBar from "../../containers/NavBar";
import { H1bUser } from "./H1bUser";
import { PetitionForm } from "./petitionForm";
import Footer from "../../containers/Footer";
import { Faqs } from "./H1bFaqs";
import Features from "../Features";
import history from "../../assets/images/history.svg";
import History from "../visaComponents/history";
import { H1Beligibility } from "../visaComponents/visaBanner";
import {
  Simplemodal,
  Notificationmodal,
} from "../helpers/modal/simplifiedmodal";
import axios from "../api/axios";
import { useNavigate, useParams } from "react-router-dom";
import { FillingCompanies } from "./fillingCompany";
import { RefFillingCompanies } from "./referrerFillingCompanies";
import { LiaTimesSolid } from "react-icons/lia";
import popUpIcon from "../../assets/popUpIcon/H1bpopup.svg";
import { Platformbutton } from "../helpers/button/button";

export const H1bPetition = () => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { referrer } = useParams();

  const navigate = useNavigate();

  const [countries, setCountries] = useState(false);

  const fetchAllCountries = () => {
    const url = "https://restcountries.com/v3.1/all";
    axios
      .get(url)
      .then((res) => {
        var options = (res.data ?? []).map((item) => ({
          image: item.flags.svg,
          label: item.name.common,
          value: item.cca3,
          currency: item.currencies,
        }));
        setCountries(options);
      })
      .catch((error) => error);
  };

  useEffect(
    () => fetchAllCountries(),
    // eslint-disable-next-line
    []
  );

  return (
    <div>
      <Simplemodal open={open} onClick={() => setOpen(false)}>
        <H1Beligibility countries={countries} close={() => setOpen(false)} />
      </Simplemodal>

      <Notificationmodal onClick={() => setOpenModal(false)} open={openModal}>
        <LiaTimesSolid
          onClick={() => setOpenModal(false)}
          className="text-[#000] absolute top-4 right-8 text-[24px] cursor-pointer"
        />

        {/* <EbookM setOpenModal={setOpenModal} /> */}
        <div className="w-[100%] sm:w-[400px] md:w-[450px] flex flex-col items-center">
          <img src={popUpIcon} alt="" />
          <p className="text-[#67A948] text-[18px] font-[600] mt-[20px] mb-[15px] text-center">
            H1B 2025 Registration Closed
          </p>
          <p className="text-[#2B5219] text-[14px] font-[500] text-center mb-[20px]">
            The regular H1B lottery registration for 2025 has now closed. Stay
            tuned for future updates.
          </p>

          <Platformbutton
            name="Okay, thank you"
            type="normal"
            disabled={false}
            click={() => {
              setOpenModal(false);
              // handleClick();
            }}
          />
        </div>
      </Notificationmodal>

      <NavBar />
      <H1bPetitionHero />

      <div>
        <PetitionForm setOpenModal={setOpenModal} />

        <H1bUser />
        <Faqs />

        <History
          icon={history}
          alt="Img"
          title="We have successfully filled over 340+ H1B visa to date and the number continues to grow."
          buttonTitle=""
          // open={open}
          // setOpen={setOpen}
        />
        <div className="my-12">
          <Features />
        </div>
      </div>

      <Footer />
    </div>
  );
};
