import { useState, useEffect } from "react";
import axios from "../api/axios";
import travelFormImg from '../../assets/ElitePass/travelFormImg.svg';
import { FaUser, FaPlane, FaCreditCard, FaUpload, FaTrash } from "react-icons/fa";
import { Simplemodal } from "../helpers/modal/simplifiedmodal";
import EliteCardBg from "../../assets/ElitePass/EliteCardBg.png";
import { cardFeatures } from "./EliteTestimonials";
import cardAb from '../../assets/ElitePass/cardAb.png';
import { Phonenumber } from "../inputs/phoneinput";
import { Success } from "../helpers/success/success";
import errorsvg from "../../assets/logo-icons/oops.svg";
import { Link as AnchorLink } from "react-scroll";
import Loader from "../Loader/Loader";
import { useSearchParams } from "react-router-dom";

export const EliteTravelForm = () => {
  const [file, setFile] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [buttonText, setButtonText] = useState("Make Payment");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false)
  const [hasVerifiedPayment, setHasVerifiedPayment] = useState(false);
  const [isVerifyingPayment, setIsVerifyingPayment] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [step, setStep] = useState(1);
  const [bookingSuccess, setBookingSuccess] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const reference = searchParams.get('reference');
  const sessionId = searchParams.get('sessionId');

  let verificationPayload = {}
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: '',
    passportPicture: null,
    flightDate: "",
    bookingReference: "",
    from: "",
    to: "",
    airline: "",
    airportLocation: "",
    paymentAction: "",
    countryCode: "",
    uniqueId: ""
  });

  const isBillingComplete =
    formData.firstName?.trim() &&
    formData.lastName?.trim() &&
    isValidEmail(formData.email) &&
    selectedCurrency;

  const isPersonalInfoComplete =
    formData.firstName?.trim() &&
    formData.lastName?.trim() &&
    isValidEmail(formData.email) &&
    formData.phoneNumber?.trim() &&
    formData.passportPicture;

  const isFlightInfoComplete =
    formData.flightDate?.trim() &&
    formData.bookingReference?.trim() &&
    formData.from?.trim() &&
    formData.to?.trim() &&
    formData.airline?.trim() &&
    formData.uniqueId?.trim() &&
    formData.airportLocation?.trim();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (uploadedFile && uploadedFile.size <= 2 * 1024 * 1024) {
      setFile(uploadedFile);
      setFormData((prev) => ({ ...prev, passportPicture: uploadedFile }));
    } else {
      setErrorMessage("File size exceeds 2MB limit.");
      setSuccessModal(true);
    }
  };

  const handleDeleteFile = () => {
    setFile(null);
    setFormData((prev) => ({ ...prev, passportPicture: null }));
  };

  const handleContinue = () => {
    if (step === 1 && selectedCurrency) {
      setStep(2); 
    } else if (step === 2 && isPersonalInfoComplete) {
      setStep(3);
    } else if (step === 3 && isFlightInfoComplete) {
      handleUpdateFlightItinerary();
    } else {
      setErrorMessage("Please complete all required fields");
      setSuccessModal(true);
    }
  };

  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
  };

  const handleSubmit = async () => {
    if (!selectedCurrency || !isBillingComplete) {
      setErrorMessage("Please complete all billing details and select a currency.");
      setSuccessModal(true);
      return;
    }
  
    if (!formData.firstName?.trim()) {
      setErrorMessage("First Name is required.");
      setSuccessModal(true);
      return;
    }

    let fileUrl = null;
    if (file) {
    try {
      fileUrl = await uploadFile(file);
    } catch (error) {
      setErrorMessage("Failed to upload passport picture. Please try again.");
      setSuccessModal(true);
      return;
    }
  }
  
    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      flightDate: formData.flightDate,
      bookingReference: formData.bookingReference,
      from: formData.from,
      to: formData.to,
      airline: formData.airline,
      airportLocation: formData.airportLocation,
      uniqueId: formData.uniqueId,
      countryCode: selectedCurrency || "USD",
      phoneNumber: formData.phoneNumber,
      paymentAction: selectedCurrency === "NGN" ? "CardNaira" : "Card",
      passportPicture: fileUrl,
    };
  
    setButtonText("Processing...");
  
    try {
      const response = await axios.post("/general-services/elite-pass", payload, {
        headers: { "Content-Type": "application/json" },
      });

      // Check if email already registered
    if (response.data.message?.includes("already registered")) {
      setErrorMessage("This email is already registered.");
      setSuccessModal(true);
      setButtonText("Make Payment");
      return;
    }
      // Redirect to the Paystack payment URL
      const paymentUrl = response.data.data.url;
      console.log("Payment response:", response.data);
      if (paymentUrl) {
        window.location.href = paymentUrl; // Redirect to Paystack
      } else {
        throw new Error("Payment URL not found in response");
      }
    } catch (error) {
      setButtonText("Make Payment");
      setErrorMessage(error.response?.data?.message || "Payment failed. Please try again.");
      setSuccessModal(true);
    }
  };

  const verifyReference = async () => {
    if (!verificationPayload.reference && !verificationPayload.sessionId) return;
    setIsVerifyingPayment(true);
    setIsLoading(true)
    setPaymentSuccess(false)
    try {
      const response = await axios.post(`/general-services/confirmElitepassPayment`, 
        verificationPayload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        });

    if (response.data) {
      setPaymentSuccess(true);
      setSuccessModal(true);
      setStep(2);
      setHasVerifiedPayment(true);
      verificationPayload = {}
      // Clear URL parameters to prevent re-running
      window.history.replaceState({}, document.title, window.location.pathname);
    } else {
      setErrorMessage(response.data?.message || "Payment verification failed");
      setSuccessModal(true);
    }
  } catch (error) {
    setErrorMessage(
      error.response?.data?.message || 
      error.message || 
      "Error verifying payment"
    );
    setSuccessModal(true);
  } finally {
    setIsLoading(false);
    setIsVerifyingPayment(false);
  }
  }

  useEffect(() => {
    if (reference && !bookingSuccess) {
      verificationPayload = {
        reference: reference,
        currency: "NGN"
      };
      verifyReference();
      
    } else if (sessionId && !bookingSuccess) {
      verificationPayload = {
        sessionId: sessionId,
        currency: "USD"
      };
      verifyReference();
    }
  }, []);

  const handleUpdateFlightItinerary = async () => {
    setIsLoading(true);
    try {
      const formDataToSend = new FormData();
  
      // 1. Append Personal Info
      formDataToSend.append('firstName', formData.firstName);
      formDataToSend.append('lastName', formData.lastName);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('phoneNumber', formData.phoneNumber);
  
      // 2. Append Flight Itinerary
      formDataToSend.append('flightDate', formData.flightDate);
      formDataToSend.append('bookingReference', formData.bookingReference);
      formDataToSend.append('from', formData.from);
      formDataToSend.append('to', formData.to);
      formDataToSend.append('airline', formData.airline);
      formDataToSend.append('airportLocation', formData.airportLocation);
      formDataToSend.append('uniqueId', formData.uniqueId);
  
      // 3. Append Payment Reference (from Step 1)
      if (reference) formDataToSend.append('paymentReference', reference);
      if (sessionId) formDataToSend.append('paymentSessionId', sessionId);
  
      // 4. Append Passport Picture (if exists)
      if (file) {
        formDataToSend.append('passportPicture', file);
      }

  setPaymentSuccess(false)
      const response = await axios.patch(
        "/general-services/elite-pass",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      );
      setPaymentSuccess(false); 
      setBookingSuccess(true);
      setSuccessModal(true);
      setIsLoading(false);

      setStep(1)
      setFormData ({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: '',
        passportPicture: null,
        flightDate: "",
        bookingReference: "",
        from: "",
        to: "",
        airline: "",
        airportLocation: "",
        paymentAction: "",
        countryCode: "",
        uniqueId: ""
      })
  
    } catch (error) {
      setErrorMessage(error.response?.data?.message || "Failed to book trip");
      setSuccessModal(true); 
      setIsLoading(false);
    }
  };

  const handleFinish = () => {
   setSuccessModal(false); 
    window.history.replaceState({}, document.title, window.location.pathname);
  }
  if (isLoading) {
    return (
      <Simplemodal open={isLoading} closable={true}
      > 
      <p className="text-center text-green-700">Verifying payment...</p>
      <Loader/>
      </Simplemodal>
    )
  } 
  return (
    <>
    <>
    {/* payment confirmation modal*/}
  <Simplemodal open={successModal} closable={true} onClick={() => {
    if (paymentSuccess || bookingSuccess) {
      setSuccessModal(false);
      setPaymentSuccess(false); 
      setBookingSuccess(false); 
    }
  }}>
    {bookingSuccess ? (
      <Success
        type="success"
        title="Booking Confirmed!"
        subtitle="Your trip has been successfully booked. You will receive a confirmation email from our team within the next few hours."
        button="Finish"
        onClick={handleFinish}
      />
    ) : paymentSuccess ? (
      <Success
        type="success"
        title="Payment Successful!"
        subtitle="Your Elite Pass ID has been sent to your email. You can now proceed with your application."
        button={
          <AnchorLink
            spy={true}
            smooth={true}
            to="EliteFormSection"
            offset={-100}
            duration={500}
            className="inline-block py-3 px-6 w-full bg-[#67A948] text-white rounded-lg cursor-pointer"
            onClick={() => setSuccessModal(false)}
          >
            Continue
          </AnchorLink>
        }
        onClick={() => setSuccessModal(false)}
      />
    ) : (
      <Success
        image={errorsvg}
        type="error"
        title={errorMessage.includes('payment') ? "Payment Error" : "Booking Error"}
        subtitle={errorMessage || "An unexpected error occurred"}
        button="Try Again"
        onClick={() => {
          if (errorMessage?.includes('payment')) {
            verifyReference();
          } else {
            setSuccessModal(false);
          }
        }}
      />
    )}
  </Simplemodal>
    </>
    <div id="EliteFormSection" className="xl:px-[6%] px-[4%] mt-[-4%] sm:mt-[5%] mb-[8%] md:flex md:items-start sm:gap-[7%] md:gap-[10%] gap-[6%] relative">
      {/* Left Image */}
      <div className="sm:flex sm:items-center gap-2 md:w-[34rem] md:h-[36rem] w-full">
        <img src={travelFormImg} alt="Travel" className="w-full rounded-md" />

        {/* Stepper */}
        <div className="flex items-center sm:flex-col justify-center mt-3 sm:mt-0">
          <div className={`flex items-center gap-1 ${
            paymentSuccess || (step > 1) ? 'text-green-500' : 
            isVerifyingPayment ? 'text-blue-500' : 'text-gray-400'
          }`}>
            {isVerifyingPayment ? (
              <div className="border-2 rounded-[48%] px-2 h-[38px] w-[40px] flex items-center justify-center">
                <div className="animate-spin rounded-full h-4 w-4 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <FaCreditCard className="border-2 rounded-[48%] px-2 h-[38px] w-[40px]" />
            )}
            <p>Payment Info</p>
          </div>

          <div className={`sm:w-[2px] w-[4rem] h-[2px] sm:h-[4rem] lg:h-[9rem] ml-1 sm:ml-4 ${isPersonalInfoComplete ? 'bg-green-500' : 'bg-gray-400'}`}></div>

          <div className={`flex items-center gap-1 ${isPersonalInfoComplete ? 'text-green-500' : 'text-gray-500'}`}>
            <FaUser className="border-2 rounded-[48%] py-1 px-2 text-[38px] h-[38px] w-[40px]" />
            <span className="xxl:text-[20px] sm:text-[14px] text-[12px]">Personal Info</span>
          </div>

          <div className={`sm:w-[2px] w-[4rem] h-[2px] sm:h-[4rem] lg:h-[9rem] ml-1 sm:ml-4 ${isFlightInfoComplete ? 'bg-green-500' : 'bg-gray-400'}`}></div>

          <div className={`flex items-center gap-1 ${isFlightInfoComplete ? 'text-green-500' : 'text-gray-400'}`}>
            <FaPlane className="border-2 rounded-full px-2 text-[38px] h-[38px] w-[40px]" />
            <p>Flight Itinerary</p>
          </div>
        </div>
      </div>

      {/* Right Form */}
      <div className=" pl-0 md:pl-12">
        <h2 className="text-[27px] font-bold pt-4 xl:pt-0 mb-4 xl:mb-8 text-gray-800">
          Start Enjoying Elite Travel in <span className="text-[#67A948]">3 Easy Steps.</span>
        </h2>

        {/* Payment Info */}
        {step === 1 && (
          <>
            <div className="rounded-[40px] bg-cover bg-center sm:mx-auto md:mx-0 w-full sm:w-[24rem] md:w-full h-[16rem]"
              style={{ backgroundImage: `url(${EliteCardBg})` }}
            >
              <div className="p-4 lg:p-6">
                <div className="flex items-center justify-between mt-2">
                  <p className="bg-[#2B5219] py-2 px-4 rounded-full text-[#DFFFD0] text-[12px]">Elite Pass Membership</p>
                  <div className="flex items-center gap-4 bg-[#65656566] py-2 px-4 rounded-lg">
                    <button
                      className={`text-[12px] ${selectedCurrency === "USD" ? "text-[#DFFFD0]" : "text-[#7F7F7F]"}`}
                      onClick={() => setSelectedCurrency("USD")}
                    >
                      USD
                    </button>
                    <button
                      className={`text-[12px] ${selectedCurrency === "NGN" ? "text-[#DFFFD0]" : "text-[#7F7F7F]"}`}
                      onClick={() => setSelectedCurrency("NGN")}
                    >
                      NGN
                    </button>
                  </div>
                </div>
                <div className="mt-2">
                  <h1 className="text-[20px] mt-4 lg:text-[24px] text-[#ffffff]">$100/year</h1>
                  <div className="flex items-end justify-between">
                    <div className="py-2 block">
                      {cardFeatures.map(({ img, title }, index) => {
                        return <div key={index} className="flex items-center gap-1 lg:gap-2 leading-8">
                          <img src={img} alt="" />
                          <p className="text-[#DFFFD0] text-[14px]">{title}</p>
                        </div>
                      })}
                    </div>
                    <img src={cardAb} className="pb-4 w-[40px]" alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* Billing details */}
            <div className="mt-6">
              <h2 className="pb-4 font-bold text-[18px]">Billing Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div className="relative flex flex-col">
                  <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">First Name</label>
                  <input type="text"
                    placeholder="First Name"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    onPaste={(e) => handleChange(e)}
                    className="px-3 py-5 border border-gray-300 bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none"
                  />
                </div>

                <div className="relative flex flex-col">
                  <label className="absolute text-gray-700 bg-[#F7F8FA]  px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">Last Name</label>
                  <input type="text"
                    placeholder="Last Name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    className="px-3 py-5 border border-gray-300 bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none" />
                </div>

                <div className="relative flex flex-col md:col-span-2">
                  <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">Email</label>
                  <input type="email"
                    placeholder="Email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => {
                      handleChange(e);
                      if (!isValidEmail(e.target.value)) {
                        setEmailError("Please enter a valid email address");
                      } else {
                        setEmailError("");
                      }
                    }}
                    className={`px-3 py-5 border ${emailError ? "border-red-500" : "border-gray-300"} bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none`}
                    />
                     {emailError && (
                        <p className="text-red-500 text-xs mt-1">{emailError}</p>
                      )}
                </div>
              </div>
            </div>
            {/* Button */}
            <div className="mt-4 xl:mt-4 flex items-center justify-end">
              <button
                onClick={handleSubmit}
                disabled={!isBillingComplete || emailError}
                className={`py-3 px-[30px] lg:px-[40px] xl:px-[64px] border  rounded-lg ${isBillingComplete && !emailError ? "bg-[#67A948] border-[#67A948] text-white" : "bg-gray-400 border-gray-400 text-gray-500"}`}
              >
                {buttonText}
              </button>
            </div>
          </>
        )}

        {/* Personal Info */}
        {step === 2 && (
          <>
            {/* Modal */}
            <Simplemodal
              open={showModal}
              onClick={() => setShowModal(false)}
            >
              <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <div className="bg-white p-6 rounded-lg">
                  {file && <img src={URL.createObjectURL(file)} alt="file" className="max-w-md" />}
                </div>
              </div>
            </Simplemodal>

            {/* Form Fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="relative flex flex-col">
                <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">First Name</label>
                <input type="text"
                  placeholder="First Name"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  onPaste={(e) => handleChange(e)}
                  className="px-3 py-[18px] border border-gray-300 bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none"
                />
              </div>

              <div className="relative flex flex-col">
                <label className="absolute text-gray-700 bg-[#F7F8FA]  px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">Last Name</label>
                <input type="text"
                  placeholder="Last Name"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="px-3 py-[18px] border border-gray-300 bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none" />
              </div>

              <div className="relative flex flex-col">
                <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">Email</label>
                <input type="email"
                  placeholder="Email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => {
                    handleChange(e);
                    if (!isValidEmail(e.target.value)) {
                      setEmailError("Please enter a valid email address");
                    } else {
                      setEmailError("");
                    }
                  }}
                  className={`px-3 py-[18px] border ${emailError ? "border-red-500" : "border-gray-300"} bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none`}
                  />
                   {emailError && (
                      <p className="text-red-500 text-xs mt-1">{emailError}</p>
                    )}
              </div>

              <div className="relative flex flex-col bg-[#F7F8FA]">
                <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold z-10">Phone Number</label>
                <Phonenumber
                  value={formData.phoneNumber}
                  country="NG"
                  setValue={
                    (value) => {
                      setFormData({ ...formData, phoneNumber: String(value) });
                    }
                  }
                  inputClass="border rounded-md w-full outline-none z-4 "
                  onChange={handleChange}
                />
              </div>
            </div>

            {/* File Upload */}
            <div className="relative mt-6 flex flex-col">
              <label className="absolute bg-[#ffffff] font-bold px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] text-gray-700">Valid International Passport</label>

              {file ? (
                <div className="p-4 border border-[#000000] rounded-lg flex items-center justify-between bg-[#F7F8FA]">
                  <div>
                    <p className="text-gray-800 font-bold">Passport</p>
                    <p className="text-gray-500 text-sm">{(file.size / 1024).toFixed(0)} KB</p>
                    <button className="text-green-500 text-sm font-semibold" onClick={() => setShowModal(true)}>Click to view</button>
                  </div>
                  <FaTrash className="text-gray-500 cursor-pointer" onClick={handleDeleteFile} />
                </div>
              ) : (
                <div className="p-6 border-dashed border-2 border-gray-300 text-center rounded-lg">
                  <label className="cursor-pointer">
                    <FaUpload className="text-[#292D32] mx-auto text-[20px]" />
                    <input type="file" className="hidden" onChange={handleFileChange} />
                    <p className="text-[#667085] text-[14px] mt-2">JPG, PNG, and PDF only | 2MB max size</p>
                    <p className="text-[#67A948] text-[14px] font-semibold">Upload</p>
                  </label>
                </div>
              )}
            </div>

            {/* Button */}
            <div className="mt-6 flex justify-end">
              <button
                onClick={handleContinue}
                disabled={!isPersonalInfoComplete || emailError}
                className={` py-3 px-[64px] rounded-lg ${isPersonalInfoComplete && !emailError? 'bg-[#67A948] text-white' : 'bg-gray-400 text-gray-500'}`}
              >
                Continue
              </button>
            </div>
          </>
        )}

        {/* Flight Itinerary */}
        {step === 3 && (
          <>
            {/* Form Fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div className="relative flex flex-1 flex-col md:col-span-2">
                <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold"> ElitePass ID</label>
                <input type="text"
                  placeholder="ELP1128757488"
                  name="uniqueId"
                  value={formData.uniqueId}
                  onChange={handleChange}
                  className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none" />
              </div>

              <div className="relative flex flex-col">
                <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">Flight Date</label>
                <input type="text"
                  placeholder="Flight Date"
                  name="flightDate"
                  value={formData.flightDate}
                  onChange={handleChange}
                  onPaste={(e) => handleChange(e)}
                  className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none"
                />
              </div>

              <div className="relative flex flex-col">
                <label className="absolute text-gray-700 bg-[#F7F8FA]  px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">Booking Reference</label>
                <input type="text"
                  placeholder="Booking Reference"
                  name="bookingReference"
                  value={formData.bookingReference}
                  onChange={handleChange}
                  className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none" />
              </div>

              <div className="relative flex flex-col">
                <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">From</label>
                <input type="text"
                  placeholder="From"
                  name="from"
                  value={formData.from}
                  onChange={handleChange}
                  className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none" />
              </div>

              <div className="relative flex flex-col">
                <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">To</label>
                <input type="text"
                  placeholder="To"
                  name="to"
                  value={formData.to}
                  onChange={handleChange}
                  className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none" />
              </div>

              <div className="relative flex flex-col">
                <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold"> Airline</label>
                <input type="text"
                  placeholder="Airline"
                  name="airline"
                  value={formData.airline}
                  onChange={handleChange}
                  className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none" />
              </div>

              <div className="relative flex flex-col">
                <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold"> Airport Location</label>
                <input type="text"
                  placeholder="Airport Location"
                  name="airportLocation"
                  value={formData.airportLocation}
                  onChange={handleChange}
                  className="p-3 border border-[#2A3147] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none" />
              </div>
            </div>

            {/* Button */}
            <div className="mt-6 flex items-center justify-between">
              <button
                onClick={() => setStep(2)}
                className='border border-[#67A948] text-[#67A948] py-3 px-[64px] rounded-lg bg-[#ffffff] hover:bg-[#67A948] hover:text-white '
              >
                Back
              </button>

              <button
                onClick={handleContinue}
                disabled={!isFlightInfoComplete}
                className={` py-3 px-[64px] rounded-lg ${isFlightInfoComplete ? 'bg-[#67A948] text-white' : 'bg-gray-400 text-gray-500'}`}
              >
                Book Trip
              </button>
            </div>
          </>
        )}
      </div>
    </div>
    </>
  );
};