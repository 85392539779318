import React from 'react'

export const PremiumTravel = () => {
    return (
        <div className="px-[4%] sm:px-12 text-center py-4 sm:py-[0px]">
          {/* Heading */}
          <h2 className="text-[27px] sm:text-[28px] md:text-[27px] xl:text-[30px] font-semibold">
            365 Days of <span className="text-[#67A948]">Premium Travel</span>
          </h2>
    
          {/* Stats Section */}
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 sm:gap-8 mt-3 sm:mt-6">
            {/* Airports */}
            <div>
              <p className="sm:text-[32px] text-[28px] font-medium text-[#060E42]">9+</p>
              <p className="text-[#30262A] font-medium xxl:text-[20px] sm:text-[16px] text-[15px]">International</p>
              <p className="text-[#67A948] font-semibold xxl:text-[20px] sm:text-[16px] text-[15px]">Airports</p>
            </div>
    
            {/* Cities */}
            <div>
              <p className="sm:text-[32px] text-[28px] font-medium text-[#060E42]">8</p>
              <p className="text-[#30262A] font-medium xxl:text-[20px] sm:text-[16px] text-[15px]">African</p>
              <p className="text-[#67A948] font-semibold xxl:text-[20px] sm:text-[16px] text-[15px]">Cities</p>
            </div>
    
            {/* Support */}
            <div>
              <p className="sm:text-[32px] text-[28px] font-medium text-[#060E42]">24/7</p>
              <p className="text-[#30262A] font-medium xxl:text-[20px] sm:text-[16px] text-[15px]">Dedicated</p>
              <p className="text-[#67A948] font-semibold xxl:text-[20px] sm:text-[16px] text-[15px]">Support</p>
            </div>
          </div>
        </div>
      );
}

