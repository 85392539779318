import React from 'react'
import eliteTravImg from '../../assets/ElitePass/eliteTravImg.svg'
import { Link as AnchorLink } from "react-scroll";

export const EliteTreatment = () => {
    return (
        <div className="xl:px-[6%] px-[4%] mb-[10%] md:mt-[6rem] mt-16 sm:mt-10 md:my-[7%] md:flex md:items-center lg:gap-[10%] gap-[6%]">
          <img
            className="vlg:w-[50rem] xlg:w-[40rem] lg:w-[30rem] md:w-[22rem] w-[100%] rounded-md"
            src={eliteTravImg}
            alt=""
          />
          <div>
            <h1 className="text-[27px] sm:text-[28px] md:text-[27px] xl:text-[30px] md:mt-0 mt-2 font-bold">
            Elite Treatment at <span className='text-[#67A948]'>Every Step</span>
            </h1>
            <p className="text-[#667085] xxl:text-[20px] sm:text-[18px] text-[14px]">
                From check-in to arrival, experience a seamless journey with our comprehensive 
                suite of premium airport services. <br/>
                Say goodbye to stress and long queues. With the Elite Pass, we take care of the 
                details so you can focus on your journey.
            </p>
            
            <div className='mt-8 sm:mt-12'>
              <AnchorLink
              spy={true}
              smooth={true}
              to="EliteFormSection"
              className="border border-[#67A948] bg-[#67A948] rounded-md text-[#ffffff] lg:py-[20px] py-[12px] lg:px-[78px] px-[46px] lg:mt-10 mt-4 xxl:text-[20px] sm:text-[16px] text-[14px] cursor-pointer">
              Get Started
              </AnchorLink>
            </div>
          </div>
        </div>
      );
}
