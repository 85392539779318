import React, {useState} from 'react'
import NavBar from '../../containers/NavBar'
import Footer from '../../containers/Footer'
import { ElitePassHero } from './ElitePassHero'
import { EliteTreatment } from './EliteTreatment'
import { PremiumTravel } from './PremiumTravel'
import ScrollCards, { ExperienceLuxury } from './ExperienceLuxery'
import { EliteTravelForm } from './EliteTravelForm'
import { EliteFeedback } from './EliteFeedback'
import { EliteEmailUpdate } from './EliteEmailUpdate'
import { EliteQues } from './EliteQues'



export const ElitePass = () => {
  return (
    <>
    <NavBar/>
    <ElitePassHero/>
    <EliteTreatment/>
    <PremiumTravel/>
    <div className="relative flex justify-center items-center ">
        <ScrollCards />  
    </div>
    <EliteTravelForm />
    <EliteFeedback/>
    <EliteEmailUpdate/>
    <EliteQues/>
    <Footer/>
    </>
  )
}
