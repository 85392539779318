import { Button } from '@material-tailwind/react'
import React from 'react'
import avataFourPlus from '../../assets/SuccessStory/avatarFourPlus.png'
import successHeroImg from '../../assets/SuccessStory/heroImg.svg'
import hundredPlus from '../../assets/SuccessStory/hundredplus.png'
import headphone from '../../assets/SuccessStory/headphone.png'


export const SuccessStoryHero = () => {
  return (
    <div id='ukHeroSection' className="bg-[#060E42] sm:mt-[6%] mt-[15%] xl:px-[6%] px-[4%] py-[8%] mb-[6%]">
    <div className="md:flex md:items-center md:justify-between">
      <div className="xxl:w-[50%] xlg:w-[45%] md:w-[50%]">
        <h1 className="vlg:text-[65px] xxl:text-[55px] md:text-[40px] text-[35px] font-bold text-[#67A948]">
          Real Stories of Success
        </h1>
        <p className="vlg:text-[24px] xxl:text-[20px] text-18px text-[#ffffff] md:mt-1 mt-2">
        Gain inspirations and ideas with people’s insights, real stories, and practical advice. Listen to our episodes for valuable tips to help you grow.        
        </p>

        <div className="flex flex-row justify-between gap-4 md:mt-[8%] mt-[4%]">
          <Button
            // onClick={() => props.setOpen(true)}
            className="flex items-center gap-2 justify-center font-fontLight text-base capitalize p-5 mb-16 w-[90%] sm:w-[60%] lg:w-[50%] border border-vestigreen bg-[#67A948] text-[#ffffff] transition duration-700 ease-in-out"
          >
            Start Listening
            <img 
            className="w-[16px]"
            src={headphone} alt=''/>
          </Button>

        </div>
        <div>
            <img 
            className="w-[12rem] md:w-[16rem]"
            src={avataFourPlus} alt=''/>  
        </div>

      </div>

      <div className="relative flex items-center justify-center mt-10 md:mt-0">
        <img
          className="md:w-[23rem] xl:w-[30rem] xlg:w-[34rem]"
          src={successHeroImg}
          alt=""
        />
        <img
          className="absolute w-[4rem] xl:w-[7rem] left-[27px] md:left-[-38px] xl:left-[-48px] bottom-[-30px] md:bottom-[-35px] xl:bottom-[-45px]"
          src={hundredPlus}
          alt=""
        />
      </div>
    </div>
  </div>
  )
}
