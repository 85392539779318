import React, { useState } from "react";
import ThumbImg from '../../assets/SuccessStory/ThumbImg.png'
import ThumbImgMan from '../../assets/SuccessStory/ThumbImgMan.png'

const videoData = [
  { id: 1, name: "Funsho", location: "Texas, USA", videoUrl: "https://www.youtube.com/watch?v=0-zpkwSUJFI", thumbnail: ThumbImg },
  { id: 2, name: "Chinyere", location: "Texas, USA", videoUrl: "https://www.youtube.com/watch?v=sGZxWTLmV8M", thumbnail: ThumbImgMan },
  { id: 3, name: "Chinyere", location: "Texas, USA", videoUrl: "https://www.youtube.com/watch?v=Laco0LdeFgI", thumbnail: ThumbImg },
  { id: 4, name: "Funsho", location: "Texas, USA", videoUrl: "https://www.youtube.com/watch?v=fVc4czue2f4", thumbnail: ThumbImgMan },
  { id: 5, name: "Chinyere", location: "Texas, USA", videoUrl: "https://www.youtube.com/watch?v=sGZxWTLmV8M", thumbnail: ThumbImg },
  { id: 6, name: "Funsho", location: "Texas, USA", videoUrl: "https://www.youtube.com/watch?v=cBuYk3CVRMI", thumbnail: ThumbImgMan },
  { id: 7, name: "Chinyere", location: "Texas, USA", videoUrl: "https://www.youtube.com/watch?v=FC6avYf82pQ&t=35s", thumbnail: ThumbImg },
];

const TestimonialVideos = () => {
  const [showAll, setShowAll] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const visibleVideos = showAll ? videoData : videoData.slice(0, 6);

  return (
    <div className="bg-[#F7FFF3] py-10 sm:py-16 sm:px-2 md:px-[2%]">
      <h2 className="text-2xl md:text-3xl font-bold text-center">
        <span className="text-vestigreen">Testimonial</span> Videos
      </h2>

      {/* Video Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 sm:gap-8 md:gap-12 mt-[6%]">
        {visibleVideos.map((video) => (
          <div
            key={video.id}
            className="relative cursor-pointer rounded-lg overflow-hidden"
            onClick={() => setSelectedVideo(video)}
          >
            {/* Video Thumbnail */}
            <img src={video.thumbnail} alt={video.name} className="w-full h-60 object-cover" />
            <div className="absolute inset-0 bg-black/40 flex items-center justify-center">
              <div className="bg-vestigreen p-4 rounded-full">
                <svg className="w-8 h-8 text-white" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M8 5v14l11-7z" />
                </svg>
              </div>
            </div>
            <div className="absolute bottom-2 left-3 text-white">
              <p className="font-bold text-[16px]">{video.name}</p>
              <p className="text-[14px] flex items-center">
                <span className="mr-1">📍</span> {video.location}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Toggle More/Less Videos */}
      <div className="flex flex-col justify-center">
        {videoData.length > 6 && (
            <button
            onClick={() => setShowAll(!showAll)}
            className="mt-8 text-vestigreen font-bold text-[14px] sm:text-[16px] flex items-center justify-center"
            >
            {showAll ? "Show Less Videos" : "More Testimonial Videos"} ↗
            </button>
        )}
      </div>
     

      {/* Fullscreen Video Modal */}
      {selectedVideo && (
        <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50">
          <div className="relative w-full max-w-4xl">
            <iframe
                className="w-full h-[500px] rounded-lg"
                // src={selectedVideo.videoUrl.replace("watch?v=", "embed/")}
                src={`${selectedVideo.videoUrl.replace("watch?v=", "embed/")}?autoplay=1&rel=0`}
                title="Testimonial Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
            ></iframe>
            <button
                onClick={() => setSelectedVideo(null)}
                className="absolute top-4 right-4 text-vestigreen text-3xl"
            >
                ✖
            </button>
           </div>

        </div>
      )}
    </div>
  );
};

export default TestimonialVideos;
