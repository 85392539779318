import { Button } from '@material-tailwind/react'
import React, {useState} from 'react'
import opeImg from '../../assets/SuccessStory/opeImg.png'
import StoriesList from './StoriesList';
import { Notificationmodal, Simplemodal } from '../helpers/modal/simplifiedmodal';
import headPhone from '../../assets/SuccessStory/headPhoneColored.png'
import waveForm from '../../assets/SuccessStory/waveform.png'
import headPhoneUncolored from '../../assets/SuccessStory/headPhoneUncolored.png'

const migrationStories = [
    { icon: headPhone, title: "How To Make Plans Before Migrating To Your Dream Country", waveform: waveForm },
    { icon: headPhone, title: "Essential Documents For A Smooth Migration Process", waveform: waveForm },
    { icon: headPhone, title: "Essential Documents For A Smooth Migration Process", waveform: waveForm },
    { icon: headPhone, title: "Essential Documents For A Smooth Migration Process", waveform: waveForm },
    { icon: headPhone, title: "Essential Documents For A Smooth Migration Process", waveform: waveForm },
    { icon: headPhone, title: "Essential Documents For A Smooth Migration Process", waveform: waveForm },
    { icon: headPhone, title: "Essential Documents For A Smooth Migration Process", waveform: waveForm },
    { icon: headPhone, title: "Essential Documents For A Smooth Migration Process", waveform: waveForm },
  ];
  
  const financialStories = [
    { icon: headPhone, title: "Smart Ways To Invest In A New Country", waveform: waveForm },
    { icon: headPhone, title: "Understanding Exchange Rates Before Moving", waveform: waveForm },
    { icon: headPhone, title: "Understanding Exchange Rates Before Moving", waveform: waveForm },
    { icon: headPhone, title: "Understanding Exchange Rates Before Moving", waveform: waveForm },
    { icon: headPhone, title: "Understanding Exchange Rates Before Moving", waveform: waveForm },
    { icon: headPhone, title: "Understanding Exchange Rates Before Moving", waveform: waveForm },
    { icon: headPhone, title: "Understanding Exchange Rates Before Moving", waveform: waveForm },
    { icon: headPhone, title: "Understanding Exchange Rates Before Moving", waveform: waveForm },
  ];
  

export const PrefferedTopic = () => {
    const [selectedTopic, setSelectedTopic] = useState("Migration Stories");
    const [storyModal, setStoryModal] = useState(false);

    const openModal = (topic) => {
        setSelectedTopic(topic);
        setStoryModal(true);
      };

  return (
    <div className='px-[18px] sm:px-[30px] md:px-[90px] lg:px-[110px] py-8'>
        <div className="border bg-[#060E42] pt-2 sm:pt-4 md:pt-16 rounded-md">
        <div className='md:px-[4%] px-[6%] sm:px-[8%] md:mt-0 mt-10 md:flex md:items-center lg:gap-[10%] gap-[6%]'>
            <div className=''>
            <h1 className="text-[27px] text-[#ffffff] sm:text-[28px] md:text-[27px] xl:text-[30px] md:mt-0 mt-2 font-bold">
            Select Your Preferred Topic 
            </h1>
            <p className="text-[#F8FEF5] xxl:text-[20px] sm:text-[18px] text-[14px] leading-loose mt-6 tracking-wider">
            Choose the "Financial Stories" button for content focused on finance, such as investment or economic trends. 
            Select the "Migration Stories" button for content about migration
            </p>
            {/* <div className='mt-8'></div> */}
            <div className="sm:flex sm:flex-row sm:justify-between sm:gap-4 mt-[4%] sm:mt-[7%]">
                    <Button
                        onClick={() => openModal("Financial Stories")}
                        className={`font-light text-base capitalize p-5 w-full sm:w-[60%] lg:w-[50%] border border-green-500 ${
                        selectedTopic === "Financial Stories" ? "bg-green-500 text-white" : "bg-transparent text-white"
                        } transition duration-700 ease-in-out`}
                    >
                        Financial Stories
                    </Button>
                    <Button
                        onClick={() => openModal("Migration Stories")}
                        className={`font-light text-base capitalize p-5 w-full sm:w-[60%] lg:w-[50%] ${
                        selectedTopic === "Migration Stories" ? "bg-green-500 text-white" : "bg-transparent text-white"
                        } transition duration-700 ease-in-out`}
                    >
                        Migration Stories
                    </Button>
                </div>
            </div>
            <div>
                <img
                className="w-[80rem] rounded-md mb-[3.5rem] md:mb-[4.3rem]"
                src={opeImg}
                alt=""
                />
            </div>
        </div>

        <Notificationmodal open={storyModal} onClick={() => setStoryModal(false)}>
        <div className="max-h-[70vh] overflow-y-auto">
          <StoriesList
            title={selectedTopic}
            stories={selectedTopic === "Migration Stories" ? migrationStories : financialStories}
          />
        </div>
        </Notificationmodal>
        </div>
    </div>
  )
}
