import React from 'react'
import NavBar from '../../containers/NavBar'
import { SuccessStoryHero } from './SuccessStoryHero'
import { PrefferedTopic } from './PrefferedTopic'
import { ChooseVesti } from './ChooseVesti'
import { CaseStudy } from './CaseStudy'
import { VestiQues } from '../Partnership/VestiQues'
import Footer from '../../containers/Footer'

export const SuccessStory = () => {
  return (
    <div>
        <NavBar/>
        <SuccessStoryHero/>
        <PrefferedTopic/>
        <ChooseVesti/>
        <CaseStudy/>
        <div className='mt-[12%] sm:mt-[9%] lg:mt-[5%]'>
        <VestiQues/>
        </div>
        <Footer/>
    </div>
  )
}
