import { useState } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; 
import { testimonials } from "./EliteTestimonials";
import { Notificationmodal, Simplemodal } from "../helpers/modal/simplifiedmodal";
import axios from "../api/axios";
import './ElitePass.css';
import { useRef } from "react";
import { Success } from "../helpers/success/success";
import errorsvg from "../../assets/logo-icons/oops.svg";
import FeedbackForm from "../FeedbackForm";

export const EliteFeedback = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [shareExpModal, setShareExpModal] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const scrollRef = useRef(null);
    const [successModal, setSuccessModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const [formData, setFormData] = useState({
      email: "",
      name: "",
      phoneNumber: "",
      rating: 1,
      userExperience: "",
      additionalInformation: "",
      service: "elitePass",
      // countryCode: "",
    });

    const handleScroll = () => {
      const scrollLeft = scrollRef.current.scrollLeft;
      const index = Math.round(scrollLeft / scrollRef.current.clientWidth);
      setActiveIndex(index);
    };
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post("/v1/general-services/feedback-form", formData, {
          headers: { "Content-Type": "application/json" }
        });
        // console.log("Submitting formData:", formData);
        // console.log("Response:", response);
    
        setSuccess(true);
        setSuccessModal(true);
      } catch (error) {
        setSuccess(false);
        setErrorMessage(error.response?.data?.message || "Something went wrong. Please try again.");
        setSuccessModal(true);
      }
      setFormData({
        email: "",
        name: "",
        phoneNumber: "",
        rating: 1,
        userExperience: "",
        additionalInformation: "",
        service: "elitePass",
        // countryCode: "",
      });
    };
    
    const handleExpModalClick = () => {
      setShareExpModal(!shareExpModal);
    };

    const prevTestimonial = () => {
      if (currentIndex > 0) {
        setCurrentIndex(currentIndex - 1);
      }
    };
  
    const nextTestimonial = () => {
      if (currentIndex < testimonials.length - 1) {
        setCurrentIndex(currentIndex + 1);
      }
    };
  

    return (
      <>
      <Simplemodal open={successModal} onClick={() => setSuccessModal(false)}>
        {success ? (
          <Success
            title="Form submitted successfully"
            subtitle="Thank you for providing your details. You will receive a confirmation email shortly."
            button="Okay, thank you"
            onClick={() => setSuccessModal(false)}
          />
        ) : (
          <Success
            image={errorsvg}
            type="error"
            title="Error submitting form"
            subtitle={errorMessage}
            button="Retry"
            onClick={() => setSuccessModal(false)}
          />
        )}
      </Simplemodal>

      <Notificationmodal open={shareExpModal} onClick={() => handleExpModalClick(false)} width={600}>
      <div className="">
        <FeedbackForm
        title="Feedback Form"
        subtitle="Please provide your feedback below."
        ratingLabel="How would you rate your overall experience with the Elite Pass service?"
        textAreaLabel="Were there any delays or issues during your experience? If so, please describe them."
        textAreaLabelSecond="Any additional information you might want to share to help us serve you better."
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
        />
      </div>
      </Notificationmodal>
        <div className="flex flex-col items-center justify-center pt-4 bg-white pb-12 px-2 sm:px-6 md:px-12 relative">
          <h2 className="text-[27px] sm:text-[28px] md:text-[27px] xl:text-[30px] font-semibold text-gray-900 text-center">
            Feedback from <span className="text-green-600 font-bold">Elite Travelers</span>
          </h2>
          <p className="text-gray-600 text-center mt-2 w-[100%] md:w-[70%] lg:w-[50%] vlg:text-[24px] xxl:text-[18px] text-[15px]">
            We can tell you all day how great Vesti Elite Pass is, but hearing it from those who’ve actually used it? That’s a whole other level of excitement!
          </p>
          <div>
            <button 
            onClick={handleExpModalClick}
            className="mt-2 sm:mt-4 border border-[#101828] bg-[#101828] hover:border-green-700 text-[#ffffff] py-3 md:py-3 px-6 sm:px-8 rounded-lg text-[12px] transition">
            Share your Experience
          </button>
          </div>
    
          {/* Navigation Buttons (outside the card) */}
          <div className="flex items-center justify-center w-full max-w-3xl mt-4 relative">
            <button
              onClick={prevTestimonial}
              disabled={currentIndex === 0}
              className={`absolute  left-[0px] sm:left-[-20px] lg:left-[-40px] p-2 rounded-full text-green-600 ${
                currentIndex === 0 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-100"
              }`}
            >
              <FaChevronLeft size={20} />
            </button>
    
            {/* Testimonial Card */}
            <div className="bg-[#F6F7FF] rounded-lg p-3 py-4 sm:py-6 sm:p-6 lg:mt-6 shadow-md text-center w-[80%] sm:w-[90%]">
              <p className="text-[#2B5219] vlg:text-[24px] xxl:text-[20px] text-[15px]">“{testimonials[currentIndex].quote}”</p>
              <div className="flex items-start justify-start mt-6">
                <img
                  src={testimonials[currentIndex].avatar}
                  alt={testimonials[currentIndex].name}
                  className="w-9 h-9 rounded-full border-2 border-green-600"
                />
                <div className="ml-2 text-left">
                  <p className="text-gray-900 font-semibold vlg:text-[24px] xxl:text-[18px] text-[15px]">{testimonials[currentIndex].name}</p>
                  <p className="text-green-600 text-[13px]">{testimonials[currentIndex].role}</p>
                </div>
              </div>
            </div>
    
            <button
              onClick={nextTestimonial}
              disabled={currentIndex === testimonials.length - 1}
              className={`absolute right-[0px] sm:right-[-20px] lg:right-[-40px] p-2 rounded-full text-green-600 ${
                currentIndex === testimonials.length - 1 ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-100"
              }`}
            >
              <FaChevronRight size={20} />
            </button>
          </div>
        </div>
        </>
      );
}
