// import { Link } from "react-router-dom";
import BgPattern from "../assets/images/pattern-bg.svg";
import students from "../assets/images/grouppeople.png";
import { Button } from "@material-tailwind/react";

const SchoolsHero = () => {
  return (
    <div className="bg-secondary w-full pt-28 lg:pt-20 relative overflow-hidden">
      <img
        src={BgPattern}
        alt="pattern"
        className="absolute right-[-100%] top-0 lg:right-0"
      />
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row justify-between items-center mt-12 mb-16">
          <div className="lg:w-[50%] z-30">
            <h1 className="text-4xl lg:text-4xl xl:text-5xl font-fontSemi pb-5 text-[#ffffff]">
              <span className="text-[#67A948]">
                Loans for International Students
              </span>
            </h1>
            <p className="lg:w-[94%] text-lg font-fontLight pb-12 text-[#ffffff]">
              Vesti has implemented an effective lending service that will make
              it easy for you to study in your country of choosing. Need a loan?
            </p>

            <div className="flex flex-col sm:flex-row gap-4 mb-16 lg:mb-0">
              <Button
                onClick={() =>
                  window.open("https://app.wevesti.com/loan", "_blank")
                }
                className="font-fontLight text-base capitalize py-5 px-2 w-[100%] sm:w-[60%] lg:w-[40%] border border-vestigreen bg-transparent transition duration-700 ease-in-out"
              >
                Need a loan? Apply here
              </Button>
              <Button
                onClick={() =>
                  window.open(
                    "https://paystack.com/buy/student--migration-loan-advisory-clarity-session",
                    "_blank"
                  )
                }
                className="font-fontLight text-base capitalize py-5 px-2 w-[100%] sm:w-[60%] lg:w-[40%] bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
              >
                Need help with Admission?
              </Button>
            </div>

            {/* <div className="bg-[#67A948] sm:w-[60%] lg:w-[50%] font-fontReg text-center hover:bg-headcol p-4 rounded-xl mb-[70px] text-[#ffffff]">
              <Link
                onClick={() =>
                  window.open("https://app.wevesti.com/loan", "_blank")
                }
              >
                Need a loan? Apply here
              </Link>
            </div> */}
          </div>
          <div className="lg:w-[50%] z-30 relative">
            <img className="w-full" src={students} alt="global" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SchoolsHero;
