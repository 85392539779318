import React, { useState, useRef } from "react";
import { motion } from "framer-motion";
import { Phonenumber } from "./inputs/phoneinput";
import worseImgCol from "../assets/ElitePass/WorstColored.png"
import notGoodImgCol from "../assets/ElitePass/notGoodColored.png"
import fineImgCol from "../assets/ElitePass/FineColored.png"
import greatImgCol from "../assets/ElitePass/GreatColored.png"
import awesomeImgCol from "../assets/ElitePass/LoveitColored.png"


const FeedbackForm = ({  
    title,
    subtitle,
    textAreaLabel,
    textAreaLabelSecond,
    ratingLabel,
    formData,
    setFormData,
    onSubmit,
    }) => {
  const [selectedRating, setSelectedRating] = useState(0);
  const [bouncing, setBouncing] = useState(false);
  const timeoutRef = useRef(null);
  const [errors, setErrors] = useState({});

  const ratings = [
    { emoji: worseImgCol, label: "Worst" },
    { emoji: notGoodImgCol, label: "Not Good" },
    { emoji: fineImgCol, label: "Fine" },
    { emoji: greatImgCol, label: "Great" },
    { emoji: awesomeImgCol, label: "Awesome" },
  ];

  const handleSliderChange = (e) => {
    const newRating = Number(e.target.value);
  
    if (newRating === selectedRating) {
      setSelectedRating(null); 
      setTimeout(() => setSelectedRating(newRating), 10); 
    } else {
      setSelectedRating(newRating);
    }
  
    setFormData((prev) => ({
      ...prev,
      rating: newRating + 1,
    }));
  
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
  
    // Force animation restart
    setBouncing(false); // Reset bounce state
    setTimeout(() => setBouncing(true), 50); // Restart animation after slight delay
  
    timeoutRef.current = setTimeout(() => {
      setBouncing(false);
    }, 1000);
  };


const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const handleChange = (e) => {
  const { name, value } = e.target;

  if (name === "email") {
    if (!emailRegex.test(value)) {
      setErrors((prev) => ({ ...prev, email: "Invalid email format" }));
    } else {
      setErrors((prev) => ({ ...prev, email: "" }));
    }
  }

  setFormData((prev) => ({ ...prev, [name]: value }));
};




  

  return (
    <div className="">
      <h2 className="text-2xl font-bold">{title}</h2>
      <p className="text-gray-600">{subtitle}</p>
      <form onSubmit={onSubmit} className="mt-4 space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Email Field */}
          <div className="relative flex flex-1 flex-col md:col-span-2">
            <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">
              Email
            </label>
            <input
              type="email"
              placeholder="Email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="p-5 border border-[#D0D5DD] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none"
            />
             {errors.email && <p className="text-red-500 text-[13px] ">{errors.email}</p>}
          </div>

          {/* Name Field */}
          <div className="relative flex flex-col">
            <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold">
              Name
            </label>
            <input
              type="text"
              placeholder="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="px-3 py-[18px] border border-[#D0D5DD] bg-[#F7F8FA] rounded w-full text-[14px] placeholder:text-[12px] outline-none"
            />
          </div>

          {/* Phone Number Field */}
          <div className="relative flex flex-col bg-[#F7F8FA]">
            <label className="absolute text-gray-700 bg-[#F7F8FA] px-2 py-1 rounded-lg top-[-12px] left-4 text-[12px] font-bold z-10">
              Phone Number
            </label>
            <Phonenumber
              value={formData.phoneNumber}
              country="NG"
              setValue={(value) =>
                setFormData({ ...formData, phoneNumber: String(value) })
              }
              inputClass="border border-[#D0D5DD] rounded-md w-full outline-none z-4"
              // onChange={handleChange}
            />
          </div>
        </div>

        {/* Rating Section */}
        <label className="font-medium text-[13px]">
            {ratingLabel}
        </label>
        <div className="w-full md:w-[75%] flex flex-col items-center">
          <div className="flex justify-between w-full md:px-4">
            {ratings.map((rate, index) => (
              <div key={index} className="flex flex-col items-center">
                <motion.img
                  src={rate.emoji}
                  alt=""
                  className={`w-8 h-8 transition duration-300 ${
                    selectedRating === index ? "grayscale-0" : "grayscale"
                  }`}
                //   animate={selectedRating === index && bouncing ? { y: [-5, 0, -3, 0] } : {}}
                animate={
                    selectedRating === index && bouncing
                      ? { y: [-15, 10, -12, 6, 0] } // Higher bounce effect
                      : {}
                  }
                //   transition={{ duration: 0.4, ease: "easeInOut" }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                />
                <p
                  className={`text-sm font-medium mt-1 ${
                    selectedRating === index ? "text-green-700 font-bold" : "text-[#D0D5DD]"
                  }`}
                >
                  {rate.label}
                </p>
              </div>
            ))}
          </div>

          {/* Rating Slider */}
          <input
            type="range"
            min="0"
            max={ratings.length - 1}
            value={selectedRating}
            onChange={handleSliderChange}
            // onChange={(e) => setSelectedRating(Number(e.target.value))}
            className="w-full mt-3 appearance-none h-2 rounded-lg bg-gray-300 
              [&::-webkit-slider-runnable-track]:rounded-lg [&::-moz-range-track]:rounded-lg 
              [&::-webkit-slider-thumb]:appearance-none [&::-webkit-slider-thumb]:h-4 [&::-webkit-slider-thumb]:w-4 
              [&::-webkit-slider-thumb]:rounded-full [&::-webkit-slider-thumb]:bg-[#67A948]"
            style={{
              background: `linear-gradient(to right, #2F855A ${
                (selectedRating / (ratings.length - 1)) * 100
              }%, #C7FFAD ${(selectedRating / (ratings.length - 1)) * 100}%)`,
            }}
          />
        </div>

        {/* Textarea Fields */}
        <div className="relative flex flex-col pt-4">
          <label className="text-gray-700 text-[13px] font-bold mb-1">
          {textAreaLabel}
          </label>
          <textarea
            name="userExperience"
            placeholder="Enter text"
            value={formData.userExperience}
            onChange={handleChange}
            className="w-full md:w-[75%] p-2 border border-[#667085] rounded outline-none"
          ></textarea>
        </div>

        <div className="relative flex flex-col pt-2">
          <label className="text-gray-700 text-[13px] font-bold mb-1">
          {textAreaLabelSecond}
          </label>
          <textarea
            name="additionalInformation"
            placeholder="Enter text"
            value={formData.additionalInformation}
            onChange={handleChange}
            className="w-full md:w-[75%] p-2 border border-[#667085] rounded outline-none"
          ></textarea>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <button
            type="submit"
            // className="bg-green-600 text-white py-2 px-12 rounded-lg hover:bg-green-700"
            disabled={!emailRegex.test(formData.email)}
            className={`py-2 px-12 rounded-lg ${
                emailRegex.test(formData.email)
                  ? "bg-green-600 text-white hover:bg-green-700"
                  : "bg-gray-400 text-gray-200 cursor-not-allowed"
              }`}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
