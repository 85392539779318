import React, { useState, useEffect, useRef } from "react";

const Typewriter = ({
  text,
  speed = 1,
  setTypewriterMsg,
  usecase,
  // displayText,
  // setDisplayText,
}) => {
  const [displayText, setDisplayText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const textContainerRef = useRef(null); // Ref for scrolling

  // Reset text when `text` prop changes
  useEffect(() => {
    if (text) {
      setDisplayText("");
      setCurrentIndex(0);
    }
  }, [text]);

  // Typewriter effect
  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setDisplayText((prev) => prev + text[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      }, speed);
      return () => clearTimeout(timeout);
    } else {
      setTypewriterMsg("done");
      // Scroll to the top once typing is complete
      if (textContainerRef.current) {
        textContainerRef.current.scrollTop = 0;
      }
    }
  }, [currentIndex, text, speed]);

  // Auto-scroll effect while typing (scrolls down as text appears)
  useEffect(() => {
    if (textContainerRef.current && currentIndex < text.length) {
      textContainerRef.current.scrollTop =
        textContainerRef.current.scrollHeight;
    }
  }, [displayText]);

  return (
    <div
      ref={textContainerRef} // Attach ref for scrolling
      className={`Typewriter FormattedTextComponent bg-[#fff] text-black px-2 pt-2 text-[14px] rounded-b-[8px] rounded-tr-[8px] ${
        usecase == "copilot"
          ? "h-fit"
          : " max-h-[12rem] lg:max-h-[7rem] overflow-y-auto"
      } `}
      dangerouslySetInnerHTML={{ __html: displayText }}
    />
  );
};

export default Typewriter;
