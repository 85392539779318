import { IoIosArrowDown } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";
import ProductsDropdown from "./ProductsDropdown";
import { useState } from "react";
import plane from "../assets/flight/flight 1.png";
import CompanysDropdown from "./CompanysDropdown";

const NavLinks = ({ addStyle, open, setOpen }) => {
  const [show, setShow] = useState(false);
  const [Financialshow, setFinancialShow] = useState(false);
  const [Migrationshow, setMigrationShow] = useState(false);
  const [MigrationPathwaysShow, setMigrationPathwaysShow] = useState(false);
  const location = useLocation();
  const [showCompany, setShowCompany] = useState(false);
  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");

  const toggleProducts = () => {
    setShow(!show);
  };

  const toggleCompany = () => {
    setShowCompany(!showCompany);
  };

  const toggleFinancial = () => {
    setFinancialShow(!Financialshow);
  };

  const toggleMigration = () => {
    setMigrationShow(!Migrationshow);
  };

  const toggleMigrationPathways = () => {
    setMigrationPathwaysShow(!MigrationPathwaysShow);
  }
  return (
    <>
      <ul
        className={`${addStyle} bg:gap-10 mt-4 items-start gap-y-10  font-[400] text-[14px]`}
      >
        <li className="group">
          <NavLink
            onClick={toggleProducts}
            className="flex items-center font-[400] relative cursor-pointer hover:text-vestigreen"
          >
            <p className="pr-1">Products</p>
            <IoIosArrowDown />
          </NavLink>

          <div className={`pl-6 bg:hidden mt-4 ${show ? "visible" : "hidden"}`}>
            <ul className="flex flex-col gap-y-2">
              <li
                className="flex items-center gap-2 font-fontLight"
                onClick={toggleFinancial}
              >
                Financial services <IoIosArrowDown />
              </li>
              <ul
                className={`pl-6 flex flex-col gap-3 bg:hidden mt-1 ${
                  Financialshow ? "visible" : "hidden"
                }`}
              >
                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/globalgeng-card"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    GlobalGeng Card
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/virtual-card"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    Virtual Dollar Card
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/wallets"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    Vesti Wallets
                  </NavLink>
                </li>
              </ul>
              <li
                className="flex items-center gap-2 font-fontLight"
                onClick={toggleMigration}
              >
                Migration services <IoIosArrowDown />
              </li>
              <ul
                className={`pl-6 bg:hidden mt-1 flex flex-col gap-3 ${
                  Migrationshow ? "visible" : "hidden"
                }`}
              >
                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/migration-fries"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"

                    // className="hover:text-vestigreen hover:font-fontSemi"
                  >
                    Migration Fries
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/migration-services"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    Migration Services
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/flight"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi flex items-center gap-1"
                  >
                    Book a Flight
                    <img src={plane} alt="" />
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/schools_we_support"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    Migration Loans
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/merchant-fees"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    Migration Fees Payment{" "}
                  </NavLink>
                </li>
              </ul>

              <li
                className="flex items-center gap-2 font-fontLight"
                onClick={toggleMigrationPathways}
              >
                Migration Pathways <IoIosArrowDown />
              </li>
              <ul
                className={`pl-6 bg:hidden mt-1 flex flex-col gap-3 ${
                  MigrationPathwaysShow ? "visible" : "hidden"
                }`}
              >
                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/O1visa"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"

                    // className="hover:text-vestigreen hover:font-fontSemi"
                  >
                    0-1 Visa
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/EB1visa"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    EB1 Visa
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/EB2NIWvisa"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi flex items-center gap-1"
                  >
                    EB2-NIW Visa
                    <img src={plane} alt="" />
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/H1Bvisa"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    H1-B Visa
                  </NavLink>
                </li>
               
                <li>
                <NavLink
                    onClick={() => setOpen()}
                    to="/claritycallhome"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    Clarity Calls
                  </NavLink>
                </li>

                <li>
                <NavLink
                    onClick={() => setOpen()}
                    to="/technation"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    UK Tech Nation Visa
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    onClick={() => setOpen()}
                    to="/care"
                    className="text-[14px] font-fontLight hover:text-vestigreen hover:font-fontSemi"
                  >
                    Canada Care Giver Visa
                  </NavLink>
                </li>
              </ul>

              <li
                className={`${
                  Financialshow || Migrationshow ? "mt-2" : "mt-0"
                }`}
              >
                <NavLink
                  onClick={() => setOpen()}
                  to="/element"
                  className="text-[14px]  font-fontLight hover:text-vestigreen hover:font-fontSemi"
                >
                  Element by Vesti (Business Account)
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="absolute hidden bg:block bg:translate-y-[-800%] bg:group-hover:translate-y-0 bg:transition bg:delay-500 bg:ease-in-out">
            <ProductsDropdown setOpen={setOpen} />
          </div>
        </li>
        <li className="group">
          <NavLink
            onClick={toggleCompany}
            // onClick={() => setOpen(false)}
            // to="/about-us"
            // className={`flex items-center hover:text-vestigreen font-[400] hover:font-fontSemi
            // ${
            //   splitLocation[1] === "about-us" &&
            //   `lg:border-b-[6px] lg:border-[#67A948] text-vestigreen`
            // }
            // `}
            className="flex items-center font-[400] relative cursor-pointer hover:text-vestigreen"
          >
            <p className="pr-1">Company</p>
            <IoIosArrowDown />
          </NavLink>

          <div
            className={`pl-6 bg:hidden mt-4 ${
              showCompany ? "visible" : "hidden"
            }`}
          >
            <ul className="flex flex-col gap-y-2">
              <NavLink
                to="/about-us"
                className="flex items-center gap-2 font-fontLight hover:text-vestigreen"
              >
                About Us
              </NavLink>

              <NavLink
                to="/careers"
                className="flex items-center gap-2 font-fontLight hover:text-vestigreen"
              >
                Careers
              </NavLink>

              <NavLink
                to="/news-room"
                className="flex items-center gap-2 font-fontLight hover:text-vestigreen"
              >
                News Room
              </NavLink>
              <NavLink
                to="/team"
                className="flex items-center gap-2 font-fontLight hover:text-vestigreen"
              >
                Team
              </NavLink>
            </ul>
          </div>

          <div className="absolute hidden bg:block bg:translate-y-[-800%] bg:group-hover:translate-y-0 bg:transition bg:delay-500 bg:ease-in-out">
            <CompanysDropdown setOpen={setOpen} />
          </div>
        </li>

        {/* company dropdown starts here */}

        {/* company dropdown ends here */}

        <li className="hidden bg:block">
          <NavLink
            onClick={() => setOpen(false)}
            to="/migration-fries"
            className={` hover:text-vestigreen font-[400] hover:font-fontSemi ${
              splitLocation[1] === "migration-fries" &&
              `lg:border-b-[6px] lg:border-[#67A948] text-vestigreen `
            }`}
            // className="hover:text-vestigreen hover:font-fontSemi"
          >
            Migration Fries
          </NavLink>
        </li>

        <li className="hidden bg:block">
          <NavLink
            onClick={() => setOpen(false)}
            to="/flight"
            className={` hover:text-vestigreen font-[400] hover:font-fontSemi flex items-center gap-2 ${
              splitLocation[1] === "/" &&
              `lg:border-b-[6px] lg:border-[#67A948] text-vestigreen`
            }`}
          >
            Book a Flight
            <img src={plane} alt="" />
          </NavLink>
        </li>
      </ul>
    </>
  );
};

export default NavLinks;

// lg:hidden lg:group-hover:block
