import React, { useState } from "react";
import imgUp from '../assets/Event-Img/imgUp.png'
import imgDown from '../assets/Event-Img/imgDown.png'

export const EmailUpdateForm = ({ title, subtitle, onSubmit, email, setEmail }) => {
  // const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const handleChange = (e) => {
    const value = e.target.value;
    setEmail(value);

    if (!emailRegex.test(value)) {
      setError("Invalid email format");
    } else {
      setError("");
    }
  };

  return (
    <div className="bg-[#F7FFF3] h-full relative mt-8 lg:mt-12 py-20 px-4 md:px-[6%] overflow-hidden">
      <div className="flex flex-col justify-center items-center">
        <div className="w-full sm:w-[400px]">
          <h1 className="font-bold text-[27px] text-center">{title}</h1>
          <p className="text-center mt-2 mb-6 text-[16px]">{subtitle}</p>
          <div className="text-[14px] gap-4 flex items-center w-full">
            <input
              className={`outline-none placeholder-gray-500 px-4 h-12 w-full py-4 rounded-md bg-gray-100 mt-2 ${
                error ? "border border-red-500" : ""
              }`}
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={handleChange}
            />
            <button
            className={`bg-vestigreen text-white py-4 h-12 px-8 rounded-md flex-shrink-0 ${
                !email || error ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={() => onSubmit(email, setError)}
            disabled={!email || error} 
            // disabled={!email || error || submittedEmails.has(email)}
            >
            Submit
            </button>

          </div>
          {error && <p className="text-[13px] text-red-800 font-[600]">{error}</p>}
        </div>
      </div>

      <img src={imgUp} alt="" className='flex justify-start w-[80px] lg:w-auto absolute top-0 -left-5'/>
      <img src={imgDown} alt="" className='fles justify-end absolute w-[80px] lg:w-auto bottom-0 -right-5' />
    </div>
  );
};


