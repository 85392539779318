export const GetScore = (test) => {
  // Count the number of keys with the value "YES"
  // const yesCount = Object.values(test).filter(value => value === 'YES').length;
  // console.log(test);
  let score;
  switch (test) {
    case 0:
      return 0;
    case 1:
      return 20;
    case 2:
      return 40;
    case 3:
      return 70;
    case 4:
      return 80;
    case 5:
      return 90;
    case 6:
      return 99;
    case 7:
      return 99;
    // default:
    //   score = 0;
  }
};
