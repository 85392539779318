import React, { useEffect, useState } from "react";
import axios from "../api/axios";
import { Modal, Spin, Steps } from "antd";
import Inputfloat from "../inputs/inputfloat";
import {
  ClarityCallRadioOption,
  SingleRadioOption,
} from "../inputs/radiobutton";
import ngnwallet from "../../assets/icons/ngnwallet.svg";
import warning from "../../assets/icons/warning.svg";
import { formatAmount } from "../../helpers/utils";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { Button } from "@material-tailwind/react";
import { PaystackButton } from "react-paystack";
import CheckoutForm from "./StepsToBook/CheckoutForm";
import "./StepsToBook/stepsToBook.css";
import { useNavigate, useBeforeUnload } from "react-router-dom";
import { Phonenumber } from "../inputs/phoneinput";

export const ClarityCallProgressStaging = (props) => {
  // console.log(props);
  const navigate = useNavigate();
  const paymentIntent = props.urlparams.get("payment_intent");
  const paymentIntentSecret = props.urlparams.get("reference");
  const paymentReferalCode = props.urlparams.get("referalCode");
  const paymentOptionsType = props.urlparams.get("type");
  const email = props.urlparams.get("email");
  const fullname = props.urlparams.get("fullname");
  const [rate, setRate] = useState();
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [accessCode, setAccessCode] = useState("");
  const [reference, setReference] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [url, setUrl] = useState(null);
  const [current, setCurrent] = useState(1);
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phoneNumber: "",
    paymentOption: "",
    priceOption: "",
    referalCode: "",
  });
  const [referalCode, setReferalCode] = useState("");
  const [type, setType] = useState("");
  const [isNavigating, setIsNavigating] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [isBlocked, setIsBlocked] = useState(true);

  const publicKey = "pk_test_2aa8ac09a6a34eb6c01d1b27b088e9d78bd50426";
  // const publicKey = "pk_live_5ac54092a585eaedb83ec09a1f8896928ffdca87";
  const stripePromise = loadStripe(
    "pk_test_51LAeheHyH0mfw5s2TipQUZsFuhxsAYCb6NJ7RBqCV6B3q4YDdmXyXTIurIFouNBp7tXp4gsEHkCybqP2lef0No2V008zGGFXGM"
  );
  // const stripePromise = loadStripe(
  //   "pk_live_51LAeheHyH0mfw5s2RLpmmI03SP6hhu2aiDRBOwMHGji06PcEYEWx062vdabvuqaAsYZzajFcAdDoDLv3zpnahEkT007kY09NQG"
  // );

  useEffect(() => {
    const storedReferalCode = localStorage.getItem("referalCode");
    setReferalCode(storedReferalCode);
  }, []);

  useEffect(() => {
    const storedValue = localStorage.getItem("type");
    if (storedValue) {
      setType(storedValue);
    }
  }, []);

  useEffect(() => {
    if (formData?.priceOption) {
      localStorage.setItem("type", formData?.priceOption);
    }
  }, [formData?.priceOption]);

  // console.log(formData?.priceOption);

  // console.log("Calendly URL:", url);

  useEffect(() => {
    const getPrices = async () => {
      try {
        const response = await axios.get(
          `https://pathwayapi.wevesti.com/api/v1/pathway/fetch-clarity-call-prices?visaType=${props.visaType}`
        );
        setServices(response.data?.priceLists[0]?.fee);
        // console.log(response.data?.priceLists[0]?.amount);
        // console.log(response);
      } catch (error) {
        console.log(error);
      }
    };

    getPrices();
  }, [props.visaType]);

  const chargeCardDollar = async () => {
    const data = {
      fullname: fullname ? fullname : formData?.fullname,
      paymentOption: "card",
      phoneNumber: formData?.phoneNumber,
      email: email ? email : formData?.email,
      currency: formData?.paymentOption ? formData?.paymentOption : "USD",
      amount: callPrice,
      clarityCallName: `${props.visaType}`,
      type: formData?.priceOption,
      referalCode: referalCode ? referalCode : "",
    };
    setLoading(true);
    try {
      const res = await axios.post(
        `https://pathway-staging.wevesti.com/api/v1/pathway/pay-for-clarity-call-initiate`,
        data
      );
      // const res = await axios.post(
      //   "https://pathwayapi.wevesti.com/api/v1/pathway/pay-for-clarity-call-initiate",
      //   data
      // );
      setClientSecret(res.data?.data?.client_secret);
      setReference(res.data?.reference);
      // console.log("Client Secret for chargeCardDollar", res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error?.response?.data?.message);
      console.error("Error initializing transaction:", error);
    }
  };

  // fetch exchange rates
  useEffect(() => {
    axios
      // .get(`https://pathway-staging.wevesti.com/api/v1/pathway/vesti-fx`)
      .get(`https://pathwayapi.wevesti.com/api/v1/pathway/vesti-fx`)
      .then((res) => {
        // console.log(res);
        setRate(res.data?.data[0]);
      })
      .catch((err) => err);
  }, []);

  const foundService = services?.find(
    (service) => service.name === "prerecorded"
  );
  const callPrice =
    foundService?.amount !== undefined ? foundService.amount : 0;

  const chargeCard = async () => {
    setLoading(true);
    const data = {
      email: email ? email : formData?.email,
      fullname: fullname ? fullname : formData?.fullname,
      phoneNumber: formData?.phoneNumber,
      paymentOption: "card",
      currency: formData?.paymentOption,
      amount: Math.round((callPrice / 100) * rate?.usdNaira),
      clarityCallName: `${props.visaType}`,
      type: formData?.priceOption,
      referalCode: referalCode ? referalCode : "",
    };

    try {
      // Make a request to your server to obtain the access code
      const response = await axios.post(
        "https://pathway-staging.wevesti.com/api/v1/pathway/pay-for-clarity-call-initiate",
        data
      );
      // const response = await axios.post(
      //   "https://pathwayapi.wevesti.com/api/v1/pathway/pay-for-clarity-call-initiate",
      //   data
      // );
      // console.log("Response : ", response);
      setAccessCode(response.data?.data?.access_code);
      setReference(response.data?.data?.reference);
      setLoading(false);

      // Once access code is obtained, initiate the payment process
    } catch (error) {
      setLoading(false);
      console.error("Error fetching access code:", error);
    }
  };

  useEffect(() => {
    if (
      callPrice > 0 &&
      formData?.email &&
      formData?.fullname &&
      formData?.paymentOption === "NGN" &&
      formData?.priceOption
    ) {
      chargeCard();
      console.log("charge card run");
    }
  }, [
    callPrice,
    formData?.email,
    formData?.fullname,
    formData?.paymentOption,
    formData?.priceOption,
    formData?.phoneNumber,
  ]);

  useEffect(() => {
    if (formData?.paymentOption === "USD" && formData?.priceOption) {
      chargeCardDollar();
    }
  }, [formData?.paymentOption, formData?.priceOption]);

  // console.log("reference:", reference)

  const handlePaystackSuccessAction = async (reference) => {
    const data = {
      email: email ? email : formData?.email,
      fullname: fullname ? fullname : formData?.fullname,
      phoneNumber: formData?.phoneNumber,
      paymentOption: "card",
      currency: formData?.paymentOption,
      clarityCallName: `${props.visaType}`,
      reference: reference?.reference,
      referalCode: referalCode ? referalCode : "",
      type: localStorage.getItem("type"),
    };
    // console.log("Data : ", data);
    setLoading(true);
    // Implementation for whatever you want to do with reference and after success call.
    try {
      const res = await axios.post(
        `https://pathway-staging.wevesti.com/api/v1/pathway/verify-reference`,
        data
      );
      // const res = await axios.post(
      //   `https://pathwayapi.wevesti.com/api/v1/pathway/verify-reference`,
      //   data
      // );
      console.log(res);
      if (res) {
        setUrl(res.data?.link);
        localStorage.removeItem("type");
      }
      setLoading(false);
      // localStorage?.removeItem("referalCode");
    } catch (error) {
      setLoading(false);
      console.error("Error fetching card:", error);
    }
  };

  const handleStripeSuccessAction = async () => {
    const data = {
      email: email ? email : formData?.email,
      fullname: fullname ? fullname : formData?.fullname,
      phoneNumber: formData?.phoneNumber,
      paymentOption: "card",
      currency: "USD",
      clarityCallName: `${props.visaType}`,
      reference: paymentIntentSecret,
      referalCode: paymentReferalCode ? paymentReferalCode : "",
      type: paymentOptionsType,
    };
    // console.log("Stripe retrieve Data : ", data);
    setLoading(true);
    // Implementation for whatever you want to do with reference and after success call.
    try {
      // const res = await axios.post(
      //   `https://pathwayapi.wevesti.com/api/v1/pathway/verify-reference`,
      //   data
      // );
      const res = await axios.post(
        `https://pathway-staging.wevesti.com/api/v1/pathway/verify-reference`,
        data
      );
      console.log(res);
      if (res) {
        setUrl(res.data?.link);
        localStorage.removeItem("type");
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      setErrorMessage(error?.response?.data?.message);
      console.error("Error fetching card:", error);
    }
  };

  const handlePaystackCloseAction = () => {
    // implementation for  whatever you want to do when the Paystack dialog closed.
    setAccessCode("");
    setReference("");
    console.log("closed");
  };

  const config = {
    reference,
    email: formData?.email,
    amount:
      formData?.paymentOption === "NGN"
        ? Math.round((callPrice / 100) * rate?.usdNaira)
        : // ? parseFloat(callPrice / 100) * rate?.usdNaira
          "",
    publicKey: publicKey,
  };

  const componentProps = {
    ...config,
    text: "Make Payment",
    onSuccess: (reference) => handlePaystackSuccessAction(reference),
    onClose: handlePaystackCloseAction,
  };

  useEffect(() => {
    if (paymentIntent) {
      handleStripeSuccessAction();
    }
  }, [paymentIntent]);

  useEffect(() => {
    if ((paymentIntent && url === null) || url !== null) {
      setCurrent(3);
    } else if (
      formData?.fullname &&
      formData?.email &&
      formData?.phoneNumber &&
      formData?.paymentOption
    ) {
      setCurrent(2);
    } else setCurrent(1);
  }, [formData, url]);

  const isValidUrl = (url) => {
    return url?.startsWith("https://");
  };

  const isVideoUrl = (url) => {
    const videoExtensions = [".mp4", ".webm", ".ogg"];
    return videoExtensions?.some((extension) => url?.endsWith(extension));
  };

  const close = () => {
    navigate("/claritycallhome-staging", { replace: true });
    localStorage.removeItem("referalCode");
    localStorage.removeItem("type");
  };

  const steps = [
    {
      title: "Personal Info",
      content: (
        <>
          <div className="border-[0.5px] border-[#67A948] py-6 px-6 md:px-10 rounded-[20px]">
            <p className="text-[#67A948] font-[550] text-[16px]">
              Personal Details
            </p>

            <div>
              <div className="flex items-center gap-7 my-4">
                <Inputfloat
                  type="text"
                  label="Full Name"
                  name="fullName"
                  placeholder="Austin Duke"
                  value={
                    props.urlparams.get("fullname")
                      ? props.urlparams.get("fullname")
                      : formData?.fullname
                  }
                  disabled={false}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      fullname: e.target.value,
                    });
                  }}
                />

                <Inputfloat
                  type="email"
                  label="Email Address"
                  name="email"
                  placeholder="Johndoe@gmail.com"
                  value={
                    props.urlparams.get("email")
                      ? props.urlparams.get("email")
                      : formData?.email
                  }
                  disabled={false}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    });
                  }}
                />
                <div className="w-full">
                  <Phonenumber
                    value={
                      props.urlparams.get("phoneNumber")
                        ? props.urlparams.get("phoneNumber")
                        : formData?.phoneNumber
                    }
                    country="NG"
                    setValue={(value) => {
                      setFormData({ ...formData, phoneNumber: String(value) });
                    }}
                  />
                </div>
              </div>
            </div>

            <p className="text-[#67A948] font-[550] text-[16px]">
              Payment Method
            </p>

            <div className="flex flex-col  md:flex-row lg:flex-row gap-7 mt-4 w-full">
              {!paymentIntent && (
                <>
                  <div className="w-full">
                    <SingleRadioOption
                      image={ngnwallet}
                      changed={(value) => {
                        setFormData({
                          ...formData,
                          paymentOption: value,
                        });
                      }}
                      id="1"
                      isSelected={formData?.paymentOption === "NGN"}
                      label="Pay in Naira (NGN)"
                      value="NGN"
                    />
                  </div>
                  <div className="w-full">
                    <SingleRadioOption
                      image={ngnwallet}
                      changed={(value) => {
                        setFormData({
                          ...formData,
                          paymentOption: value,
                        });
                      }}
                      id="2"
                      isSelected={formData?.paymentOption === "USD"}
                      label="Pay in Dollars (USD)"
                      value="USD"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      ),
    },
    {
      title: "Clarity Call Type",
      content: (
        <>
          <div className="border-[0.5px] border-[#67A948] py-6 px-6 md:px-10 relative rounded-[20px]">
            {/* <p className="text-[#67A948] font-[550] text-[16px] text-center">
              Select Clarity Call Type
            </p> */}
            {paymentIntent ||
              (formData?.paymentOption && formData?.priceOption && (
                <p className=" mt-6 font-[600] text-[20px] text-center">
                  Price:{" "}
                  {formData?.paymentOption ? formData?.paymentOption : "USD"}
                  {formatAmount(
                    formData?.paymentOption === "NGN"
                      ? (callPrice / 100) * (rate?.usdNaira / 100)
                      : callPrice / 100,
                    2
                  )}
                </p>
              ))}
            <div className="flex flex-col  md:flex-row lg:flex-row gap-7 mt-4 w-full">
              {/* {formData?.paymentOption && (
                <div className="w-full flex flex-wrap justify-center items-center gap-4">
                  <div className="w-full sm:w-[45%]">
                    <SingleRadioOption
                      image={ngnwallet}
                      changed={(value) => {
                        setFormData({
                          ...formData,
                          priceOption: value,
                        });
                      }}
                      id="1"
                      isSelected={formData?.priceOption === "one-on-one"}
                      label={`One on One Call ($${
                        services?.find(
                          (service) => service.name === "one-on-one"
                        )?.amount / 100
                      })`}
                      sublabel="Meet one-on-one with a Vesti team member. You’ll get to receive Vesti’s annual community membership worth $299."
                      value="one-on-one"
                    />
                  </div>
                  <div className="w-full sm:w-[45%]">
                    <SingleRadioOption
                      image={ngnwallet}
                      changed={(value) => {
                        setFormData({
                          ...formData,
                          priceOption: value,
                        });
                      }}
                      id="2"
                      isSelected={formData?.priceOption === "prerecorded"}
                      label={`Pre-recorded Call ($${
                        services?.find(
                          (service) => service.name === "prerecorded"
                        )?.amount / 100
                      })`}
                      sublabel="Gain valuable insights from a pre-recorded session with a Vesti expert, offering you expert guidance and support."
                      value="prerecorded"
                    />
                  </div>
                  <div className="w-full sm:w-[45%]">
                    <div className="border shadow-md rounded-md flex justify-between p-4 items-start gap-4 cursor-not-allowed">
                      <img src={ngnwallet} alt="" />
                      <div className="flex flex-col justify-center items-center gap-4">
                        <p className="text-[#2b5219] text-xl font-semibold">
                          Miai Migration Assistant AI ($
                          {`${
                            services?.find((service) => service.name === "miai")
                              ?.amount / 100
                          }`}
                          ) (coming soon)
                        </p>
                        <p className="text-[#2b5219] font-light">
                          Gain valuable insights from a pre-recorded session
                          with our Migration Assistant AI, offering you guidance
                          and support.
                        </p>
                      </div>
                    </div>
                    
                  </div>
                </div>
              )} */}

              {formData?.paymentOption && (
                <div className="w-full flex flex-wrap justify-center items-center gap-4">
                  {/* <div className="w-full sm:w-[48%] xxl:w-[49.25%]">
                    <ClarityCallRadioOption
                      image={ngnwallet}
                      changed={(value) => {
                        setFormData({
                          ...formData,
                          priceOption: value,
                        });
                      }}
                      id="1"
                      isSelected={formData?.priceOption === "one-on-one"}
                      label={`One on One Call `}
                      price={`($${
                        services?.find(
                          (service) => service.name === "one-on-one"
                        )?.amount / 100
                      })`}
                      sublabel="Meet one-on-one with a Vesti team member. You’ll get to receive Vesti’s annual community membership worth "
                      sublabelPrice={"$299."}
                      value="one-on-one"
                    />
                  </div> */}
                  {/* <div className="w-full sm:w-[48%] xxl:w-[49.25%]"> */}
                  <div className="mx-auto w-[60%]">
                    <ClarityCallRadioOption
                      image={ngnwallet}
                      changed={(value) => {
                        setFormData({
                          ...formData,
                          priceOption: value,
                        });
                      }}
                      id="2"
                      isSelected={formData?.priceOption === "prerecorded"}
                      label={`Clarity Session `}
                      price={`(${
                        formData?.paymentOption === "NGN" ? "NGN" : "$"
                      }${formatAmount(
                        formData?.paymentOption === "NGN"
                          ? (callPrice / 100) * (rate?.usdNaira / 100)
                          : callPrice / 100,
                        2
                      )})`}
                      sublabel="Gain valuable insights from a Clarity session with a Vesti expert, offering you expert guidance and support."
                      value="prerecorded"
                      sublabelPrice={""}
                    />
                  </div>
                  <div className="w-full sm:w-[48%] mb-4 xl:w-[50%] ">
                    {/* I commented this */}
                    {/* <div className="border shadow-md rounded-md flex justify-between p-4 items-start gap-4 cursor-not-allowed">
                      <img src={ngnwallet} alt="" />
                      <div className="flex flex-col   gap-1">
                        <p className="text-[#2b5219] text-[16px] font-semibold">
                          Miai Migration Assistant AI{" "}
                          <span className="text-[#67A948]">
                            {" "}
                            ($
                            {`${
                              services?.find(
                                (service) => service.name === "miai"
                              )?.amount / 100
                            }`}
                            ) (coming soon){" "}
                          </span>
                        </p>
                        <p className="text-[#3E6F26] text-sm font-[400]">
                          Gain valuable insights from a pre-recorded session
                          with our Migration Assistant AI, offering you guidance
                          and support.
                        </p>
                      </div>
                    </div> */}

                    {/* <SingleRadioOption
                      disabled={true}
                      image={ngnwallet}
                      changed={(value) => {
                        setFormData({
                          ...formData,
                          priceOption: value,
                        });
                      }}
                      id="3"
                      isSelected={formData?.priceOption === "miai"}
                      label={`Miai Migration Assistant AI ($${
                        services?.find((service) => service.name === "miai")
                          ?.amount / 100
                      }) (coming soon)`}
                      sublabel="Gain valuable insights from a pre-recorded session with our Migration Assistant AI, offering you guidance and support."
                      value="miai"
                    /> */}
                  </div>
                </div>
              )}
            </div>

            <p className="bg-white absolute border-[0.5px] left-[20%] sm:left-[40%] md:left-[43%] lg:left-[43%] rounded-[6px] bottom-[-15px] border-[#67A948] py-1 px-3 text-[13px] text-[#67A948] ">
              {formData?.paymentOption === "USD" ? "Powered" : "Secured"} by{" "}
              <span className="font-[600]">
                {formData?.paymentOption === "USD" ? "Stripe" : "Paystack"}
              </span>
            </p>

            {loading ? (
              <div className="p-4">
                <Spin className="custom-spinner " />
              </div>
            ) : errorMessage ? (
              <p className="p-4 text-red-900">{errorMessage}</p>
            ) : formData?.paymentOption === "NGN" &&
              formData?.priceOption &&
              reference &&
              accessCode ? (
              <div className="flex flex-col w-full mx-auto mt-1 mb-6 md:w-[25%]">
                <PaystackButton
                  className="normal-case mt-6 bg-vestigreen rounded-md transition h-12 duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[14px] text-white font-bold"
                  {...componentProps}
                />
                {!reference && !accessCode && (
                  <Button
                    onClick={() => chargeCard()}
                    className="normal-case mt-6 bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[14px] flex justify-center items-center w-full md:inline-block "
                  >
                    Continue
                  </Button>
                )}
              </div>
            ) : (
              formData?.paymentOption === "USD" &&
              formData?.priceOption && (
                <Button
                  onClick={() => setOpen(true)}
                  className="normal-case mt-6 bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[14px]"
                >
                  Make Payment
                </Button>
              )
            )}
          </div>
        </>
      ),
    },
    {
      title: "Clarity Call Booking",
      content: (
        <>
          <div className="">
            {loading && (
              <div className="p-4 flex justify-center items-center">
                <Spin className="custom-spinner" />
              </div>
            )}
            {url === null && errorMessage ? (
              <div className="flex flex-col justify-center items-center">
                <p className=" text-red-900">{errorMessage}</p>
                <Button
                  onClick={() => window.location.reload()}
                  className="normal-case mt-6 bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[14px]"
                >
                  Reload
                </Button>
              </div>
            ) : isValidUrl(url) && isVideoUrl(url) ? (
              <video width="100%" height="100" controls>
                <source src={url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            ) : (
              <iframe
                src={url !== null ? url : " "}
                style={{ width: "100%", border: 0, height: "40rem" }}
              ></iframe>
            )}
          </div>
          {url && (
            <Button
              onClick={close}
              className="normal-case mt-6 bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26] py-3 px-4 text-[14px]"
            >
              Finish
            </Button>
          )}
        </>
      ),
    },
  ];

  const items = steps?.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  // handle premature leaving

  // Helper function to check if formData has any values
  const hasFormData = () => {
    return Object.values(formData).some((value) => value !== "");
  };

  const shouldBlockNavigation = () => {
    return url || hasFormData(); // Check if either url or formData is populated
  };

  // Handle refresh or tab close using the `beforeunload` event

  useBeforeUnload((event) => {
    if (isBlocked && shouldBlockNavigation()) {
      event.preventDefault();
      event.returnValue = ""; // Show the browser's confirmation dialog
    }
  });

  // Intercept navigation attempts and show the modal
  useEffect(() => {
    const handleNavigationAttempt = (e) => {
      e.preventDefault();
      setNextLocation(e.target.location.pathname);
      setIsModalOpen(true); // Open the modal
    };

    const handleBeforeNavigate = (event) => {
      if (!isNavigating) {
        event.preventDefault(); // Prevent the navigation and show the modal
        setNextLocation(event.pathname); // Store the attempted next location
        setIsModalOpen(true); // Show the modal
      }
    };

    window.addEventListener("popstate", handleNavigationAttempt);

    return () => {
      window.removeEventListener("popstate", handleNavigationAttempt); // Cleanup
    };
  }, [isNavigating, url, formData]);

  // useEffect(() => {
  //   const handleNavigationAttempt = (e) => {
  //     if (shouldBlockNavigation() && !isNavigating) {
  //       e.preventDefault();
  //       setNextLocation(e.target.location.pathname);
  //       setIsModalOpen(true); // Open the modal
  //     }
  //   };

  //   window.addEventListener("popstate", handleNavigationAttempt);

  //   return () => {
  //     window.removeEventListener("popstate", handleNavigationAttempt); // Cleanup
  //   };
  // }, [isNavigating, url, formData]);

  // Handle when the user confirms leaving the page
  const handleConfirmLeave = () => {
    setIsBlocked(false);
    setIsNavigating(true); // Allow navigation to happen
    setIsModalOpen(false); // Close the modal
    if (nextLocation) {
      navigate(nextLocation); // Navigate to the next location if set
    } else {
      window.history.go(-1); // Go back in browser history if no specific next location
    }
  };

  // When the user cancels the navigation attempt
  const handleStay = () => {
    setIsModalOpen(false); // Close the modal
    setNextLocation(null); // Clear the next location
  };

  return (
    <div
      className="flex flex-col gap-4 justify-center items-center mt-8"
      id="bookcallSection"
    >
      <div className="w-[80%]">
        <Steps
          current={current}
          items={items}
          labelPlacement="vertical"
          size="small"
        />

        <div className="py-8 customs-steps">{steps[current - 1]?.content}</div>
      </div>
      <Modal open={open} onCancel={() => setOpen(false)} footer={null}>
        <div className="p-8">
          <>
            <h1 className="text-center py-2 text-lg font-medium">
              Clarity Call Payment
            </h1>
            {clientSecret && stripePromise && (
              <Elements stripe={stripePromise} options={{ clientSecret }}>
                <CheckoutForm
                  visaType={props.visaType}
                  fullname={formData?.fullname}
                  email={formData?.email}
                  Reference={reference}
                  referalCode={referalCode}
                  type={type}
                />
              </Elements>
            )}
          </>
        </div>
      </Modal>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        maskClosable={false}
      >
        <div className="bg-white p-6 rounded-md flex flex-col items-center justify-center">
          <img src={warning} alt="caution" />
          <h2 className="text-2xl font-semibold  text-[#62943B]">
            Are you sure you want to leave?
          </h2>
          <p className="mt-4">
            This session will be lost and you will need to start again.
          </p>
          <p className="mt-4 text-[#67A948]">
            NB:You can still access your clarity call video when you open a
            Vesti Account.
          </p>
          <div className="mt-6 flex flex-col gap-4 justify-center w-[75%] items-center">
            <button
              className="px-4 py-2 bg-[#67A948] text-white rounded-md w-full"
              onClick={handleStay}
            >
              Stay on page
            </button>
            <button
              className="px-4 py-2 bg-white border-2 border-[#67A948] text-[#67A948] rounded-md w-full"
              onClick={handleConfirmLeave}
            >
              Exit anyway
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
