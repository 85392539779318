import flash from '../../assets/ElitePass/flash.png'
import waleAvatar from '../../assets/ElitePass/waleAvatar.png'

export const testimonials = [
    {
      quote:
        "Vesti Elite Pass subscription offers a seamless and premium experience, providing access to exclusive resources, expert consultations, and a well-structured support system for global mobility. It’s an excellent choice for professionals seeking reliable guidance and a smooth immigration process. Highly recommended for those looking for efficiency and expert insights.",
      name: "Wale Salami",
      role: "Executive Director, Midlothian Angel Network",
      avatar: waleAvatar,
    },
    {
      quote:
        "I had a really fantastic time with the ELITE pass service, absolutely smooth and breezy airport check-in, the staff were very kind, helpful and made the trip so much easier Totally recommend 10/10",
      name: "Tomi",
      role: "Growth Product Manager",
      avatar: "https://randomuser.me/api/portraits/women/3.jpg",
    },
    {
      quote:
        "Very Nice and detailed Service",
      name: "Mr Victor Olofinlade",
      role: "SmartComply",
      avatar: "https://randomuser.me/api/portraits/men/10.jpg",
    },
    {
      quote:
        "I had a fantastic experience with Vesti Elite Pass during my recent trip to and from Lagos. Their protocol services at the airport were seamless, efficient and professional. From smooth check-ins to hassle-free immigration assistance, everything was handled with care and precision. I highly recommend their services to anyone looking for a stress free travel experience.",
      name: "Mrs Abisola Elehinafe",
      role: "CEO Prime and Hills Event",
      avatar: "https://randomuser.me/api/portraits/men/10.jpg",
    },
  ];

  export const EliteQuestion = [
    {
      title: "Is the service available at all airports?",
      ans: "Vesti is a migration fintech company focused on global migration, aiming to simplify the movement of humans and capital across borders. Our goal is to become the leading provider of legal and financial services for the next one billion immigrants, from the initial decision to emigrate to their arrival in the destination country and beyond.",
    },
    {
      title: " How secure is the process of uploading my international passport?",
      ans: "We take your privacy seriously. All passport uploads are encrypted and stored securely, complying with global data protection standards.",
    },
    {
      title: "Can I use the Elite Pass for multiple trips?",
      ans: "Vesti tailors visa strategies for startups by considering the startup's specific needs and the qualifications of its founders and employees. This may involve recommending visa types that are suited to startups, such as EB-5 visas for investment or O-1 visas for individuals with extraordinary ability. Vesti also assists with the complex paperwork and procedures involved in applying for visas.",
    },
    {
      title: "Are there any additional fees beyond the Elite Pass cost?",
      ans: "Accelerators and VC firms can partner with Vesti to streamline the visa process for their portfolio startups. Vesti's expertise can help startups secure the necessary visas for founders and employees, allowing them to focus on growing their businesses. This partnership can be mutually beneficial, as a smoother visa process can attract more talented individuals to startups.",
    },
  ];

  export const cardFeatures = [
    {
      img: flash,
      title: "Expedited Check-In & Check-Out"
    },
    {
      img: flash,
      title: "Premium Lounge Access"
    },
    {
      img: flash,
      title: "Personalized Airport Protocol Services"
    },
    // {
    //   img: flash,
    //   title: "Onboarding & Offboarding Assistance"
    // },
    // {
    //   img: flash,
    //   title: "Global Coverage for All Your Travels"
    // }
  ]
  
  
  