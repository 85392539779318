import React from 'react';
import { useParams } from 'react-router-dom';
import caseStudyImg from '../assets/SuccessStory/CaseStudyPic.png'
import premblyImg from '../assets/SuccessStory/Prembly.png';
import interswitchImg from '../assets/SuccessStory/Interswitch.png';
import paystackImg from '../assets/SuccessStory/Paystack.png';
import providusImg from '../assets/SuccessStory/Providus.png';

const CaseStudyPage = () => {
    const { brand } = useParams(); 
    const study = caseStudies[brand]; 
  
    if (!study) {
      return <h1 className="text-center text-red-500">Case study not found</h1>;
    }
  
    return (
      <div className="p-6 md:p-12 lg:px-32">
        <h1 className="text-[30px] font-bold">{study.title}</h1>
        <p className="text-[18px] text-[#AEB4BE]">{study.sub}</p>
        <h3 className="text-3xl font-bold">{study.subtitle}</h3>
        <p className="mt-4 text-lg">{study.content}</p>
        <img src={study.image} alt={study.subtitle} className="w-full mt-6 rounded-md shadow-md" />
      </div>
    );
  };
  
  export default CaseStudyPage;

const caseStudies = {
  vesti: {
    title: "Company Case Study",
    sub: "Testimonials For Startup",
    subtitle: "Vesti Technologies | Migration and Financial Technology",
    content: `
      VESTI is the preferred provider of legal and financial services for humans going global.
      - **Mission:** To be the go-to legal and financial services provider for 1 billion immigrants.
      - **Vision:** A financial platform for the next half a billion immigrants worldwide.
      - **Values:** Excellence, connectedness, trust, and accountability.
    `,
    image: caseStudyImg,
  },
  prembly: {
    title: "Company Case Study",
    sub: "Testimonials For Startup",
    subtitle: "Identitypass Introduces Prembly",
    content: `
      Prembly is a leading identity verification and compliance solutions provider...
      - **Mission:** To enhance security and compliance globally.
      - **Vision:** A world where identity fraud is eliminated.
    `,
    image: premblyImg,
  },
  interswitch: {
    title: "Company Case Study",
    sub: "Testimonials For Startup",
    subtitle: "Interswitch Group: Global Payment Innovation",
    content: `
      Interswitch is a pioneer in digital payments across Africa.
      - **Achievements:** Unveiled the 2024 Global Payment Innovation Report.
      - **Impact:** Empowering millions through seamless payment technology.
    `,
    image: interswitchImg,
  },
  paystack: {
    title: "Company Case Study",
    sub: "Testimonials For Startup",
    subtitle: "Paystack: Building Seamless Payment Experiences",
    content: `
      Paystack powers businesses with secure and seamless payment solutions.
      - **Growth:** Expanding across Africa with millions of active merchants.
      - **Innovation:** A trusted partner in the digital payments revolution.
    `,
    image: paystackImg,
  },
  providus: {
    title: "Company Case Study",
    sub: "Testimonials For Startup",
    subtitle: "Providus Bank and Unity Bank Merger",
    content: `
      Providus Bank has merged with Unity Bank to create a stronger financial entity.
      - **Impact:** Enhancing financial inclusion and expanding services.
      - **Approval:** Officially recognized by the Central Bank of Nigeria.
    `,
    image: providusImg,
  },
};


