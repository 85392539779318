import React, { useState, useEffect } from "react";
import axios from "../components/api/axios";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import plane from "../assets/miai/charter-flight.svg";
import FormattedTextComponent from "../components/Miai/FormattedText";
import Loader from "../components/Loader/Loader";

const UserLocation = (props) => {
  const [error, setError] = useState(false);

  const [typewritermsg, setTypewriterMsg] = useState("");
  const [answer, setAnswer] = useState("");

  const handleLocationChange = (event) => {
    props.setNewLocation(event.target.value);
  };

  const GOOGLE_API_KEY = "AIzaSyBEO0G6kLtVJEznlncUow-p0imTlHJ8fn0"; // Replace with your actual API key

  const [location, setLocation] = useState({
    lat: null,
    lng: null,
    address: null,
  });

  // Function to get user's latitude and longitude
  const fetchLocationFromGoogleAPI = async () => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/geolocation/v1/geolocate?key=${GOOGLE_API_KEY}`,
        {
          method: "POST",
        }
      );

      const data = await response.json();
      if (data.location) {
        const { lat, lng } = data.location;
        setLocation((prev) => ({ ...prev, lat, lng }));

        // Fetch formatted address as City, Country
        fetchAddressFromCoordinates(lat, lng);
      } else {
        console.error("Failed to get location:", data);
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  // Function to get "City, Country" format from coordinates
  const fetchAddressFromCoordinates = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_API_KEY}`
      );

      const data = await response.json();
      if (data.results.length > 0) {
        const addressComponents = data.results[0].address_components;

        let city = "";
        let country = "";

        addressComponents.forEach((component) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name; // Country
          }
        });

        //  const formattedAddress =
        //    city && country ? `${city}, ${country}` : "Location not found";

        const formattedAddress = country ? `${country}` : "Location not found";
        setLocation((prev) => ({ ...prev, address: formattedAddress }));
        props.setUserCountry(formattedAddress);
      } else {
        console.error("Failed to get address:", data);
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };

  // Fetch location when component mounts
  useEffect(() => {
    fetchLocationFromGoogleAPI();
  }, []);

  const handleuserDetail = async (miaiResponse) => {
    const payload = {
      email: props.email,
      origin: props.userCountry,
      destination: props.newLocation,
      miaiResponse: miaiResponse,
    };

    await axios
      .post("/v1/bot/ai-platform", payload)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
  };

  const Handlepost = async () => {
    props.setLoading(true);
    setAnswer("");
    setTypewriterMsg("Typing");

    const payload = {
      question: `Pathway Visa Guide for moving from ${props.userCountry} to ${props.newLocation}`,
      chatId: "67c884b0-0f4f-4475-8853-4c5e3512b9fc",
    };

    await axios
      .post("/v1/bot/chat-no-auth-gemini", payload)
      .then((response) => {
        props.setLoading(false);
        setAnswer(
          response.data?.data?.data?.candidates[0]?.content?.parts[0]?.text
        );
        handleuserDetail(
          response.data?.data?.data?.candidates[0]?.content?.parts[0]?.text
        );
        // setTypewriterMsg("Typing");
      })
      .catch((error) => {
        console.log(error);
        props.setLoading(true);
      });
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="w-full lg:w-[80%] mt-4 ">
      <p className="text-white mb-4 text-[16px] bg:text-[18px]">
        Enter your desired destination below to get started{" "}
      </p>

      {/* <div className="flex gap-2 bg:gap-3 mb-2 items-center w-full ">
        <div className="w-full relative">
          <label className="text-[11px]  text-white absolute top-[-10px] rounded-[5px] left-4 bg-[#071E32] px-2">
            Phone Number
          </label>
          <input
            type="text"
            placeholder="+23481187909"
            value={props.phoneNumber}
            onChange={(e) => props.setPhoneNumber(e.target.value)}
            className="text-[#060E42]  text-[13px] px-2 py-3 rounded-sm outline-none"
          />{" "}
        </div>

        <div className="w-full relative">
          <label className="text-[11px]  text-white absolute top-[-10px] rounded-[5px] left-4 bg-[#071E32] px-2">
            Email
          </label>
          <input
            type="text"
            placeholder="Enter your email"
            value={props.email}
            onChange={(e) => props.setEmail(e.target.value)}
            className="text-[#060E42]  text-[13px] px-2 py-3 rounded-sm outline-none"
          />{" "}
        </div>
      </div> */}

      <div className="flex gap-2 bg:gap-3 items-center w-full">
        <div className="w-full relative">
          {/* Pre-filled input field with userCountry */}
          <label className="text-[11px]  text-white absolute top-[-10px] rounded-[5px] left-4 bg-[#071E32] px-2">
            Origin
          </label>
          <input
            type="text"
            placeholder="Lagos, Nigeria"
            value={props.userCountry}
            onChange={(e) => props.setUserCountry(e.target.value)} // Optional: allow editing userCountry
            disabled={false} // Disable editing if not required
            className="text-[#060E42] text-[13px] px-2 py-3 rounded-sm outline-none"
          />
        </div>
        <img src={plane} alt="" className="mb-1 rotate-animation" />

        <div className="w-full relative">
          {/* Second input field for the user to enter a new location */}
          <label className="text-[11px]  text-white absolute top-[-10px] rounded-[5px] left-4 bg-[#071E32] px-2">
            Destination
          </label>
          <input
            type="text"
            placeholder="Texas, US"
            value={props.newLocation}
            onChange={handleLocationChange}
            className="text-[#060E42] text-[13px] px-2 py-3 rounded-sm outline-none"
          />
        </div>
      </div>
      {error && !props.newLocation ? (
        <p className="text-red-700 w-full mb-1  text-[14px] text-center font-semibold">
          *Enter Desired Destination to proceed
        </p>
      ) : (
        <></>
      )}
      {error && !props.userCountry ? (
        <p className="text-red-700 w-full mb-1  text-[14px] text-center font-semibold">
          *Enter Origin to proceed
        </p>
      ) : (
        <></>
      )}

      {props.loading && (
        <div className="flex justify-center items-center my-2">
          <Loader />
        </div>
      )}

      {answer ? (
        <div className="leading-[20px] bg-[#fff] px-1 text-[14px] pb-2 mb-4  rounded-sm max-h-[12rem] lg:max-h-[8rem] overflow-y-auto">
          <FormattedTextComponent
            text={answer}
            typewritermsg={typewritermsg}
            setTypewriterMsg={setTypewriterMsg}
          />

          <br />

          {typewritermsg == "done" && (
            <>
              <p>
                {" "}
                <span
                  onClick={() =>
                    window.open("https://app.wevesti.com/register", "_blank")
                  }
                  className=" text-vestigreen font-bold pb-2 px-2 cursor-pointer underline"
                >
                  Create an account to continue {typewritermsg}
                </span>
              </p>
              <p
                // onClick={() =>
                //   window.open("https://app.wevesti.com/register", "_blank")
                // }
                className=" text-vestigreen font-bold pb-2 px-2 mt-2 "
              >
                Already arrived a new country? Ask more questions or create a
                personalized plan using Miai™️ in full view. Click button below
                to start.
              </p>
            </>
          )}
        </div>
      ) : (
        <div className=""></div>
      )}

      {props.loading ? (
        <></>
      ) : answer ? (
        <>
          {" "}
          <div
            onClick={() => {
              props.Handlepost();
            }}
            className="font-[600] mb-4 cursor-pointer text-[12px] lg:text-[13px] capitalize px-1 py-3  rounded-sm text-center  w-[100%]  bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
          >
            Get a free plan in Miai ™
          </div>
        </>
      ) : (
        <>
          <div
            className={`w-full hidden bg:block ${
              answer ? "mb-4" : " mt-4 mb-16"
            }`}
          >
            <div
              className={`w-full items-center flex gap-[0px] bg:gap-3 bg-white px-[1px] sm:px-1 `}
            >
              <div className="w-full relative">
                <input
                  type="text"
                  placeholder="Enter your email"
                  value={props.email}
                  onChange={(e) => props.setEmail(e.target.value)}
                  className="text-[#060E42]  text-[13px] px-2 py-3 rounded-sm outline-none"
                />{" "}
              </div>

              <div className={`w-[100%] `}>
                {props.newLocation &&
                props.userCountry &&
                props.email &&
                validateEmail(props.email) ? (
                  <div
                    onClick={() => {
                      Handlepost();
                    }}
                    className="font-[600] cursor-pointer text-[9px] lg:text-[13px] capitalize px-[0.5px] sm:px-1 py-3  rounded-sm text-center  w-[100%]  bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
                  >
                    Discover Your Pathway with Miai ™
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setError(true);
                    }}
                    className="font-[600] cursor-pointer text-[9px] lg:text-[13px] capitalize px-[0.5px] sm:px-1 py-3  rounded-sm text-center  w-[100%]  bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
                  >
                    Discover Your Pathway with Miai ™
                  </div>
                )}
              </div>
            </div>

            {props.email && !validateEmail(props.email) && (
              <p className="text-red-700 w-full mt-2  text-[14px] text-center font-semibold">
                *Please enter a valid email address
              </p>
            )}

            {error && !props.email ? (
              <p className="text-red-700 w-full mt-2  text-[14px] text-center font-semibold">
                *Enter your email to proceed
              </p>
            ) : (
              <></>
            )}
          </div>

          {/* Mobile button */}

          <div
            className={`w-full bg:hidden ${answer ? "mb-4" : " mt-1 mb-16"}`}
          >
            <div
              className={`w-full items-center flex flex-col  gap-[0px] bg:gap-3  px-[1px] sm:px-1 `}
            >
              <div className="w-full relative">
                <input
                  type="text"
                  placeholder="Enter your email"
                  value={props.email}
                  onChange={(e) => props.setEmail(e.target.value)}
                  className="text-[#060E42] bg-white text-[13px] px-2 py-3 rounded-sm outline-none"
                />{" "}
              </div>

              <div className={`w-[100%] `}>
                {props.newLocation &&
                props.userCountry &&
                props.email &&
                validateEmail(props.email) ? (
                  <div
                    onClick={() => {
                      Handlepost();
                    }}
                    className="font-[600] cursor-pointer text-[11px] lg:text-[13px] capitalize px-[0.5px] sm:px-1 py-4  rounded-sm text-center  w-[100%]  bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
                  >
                    Discover Your Pathway with Miai ™
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setError(true);
                    }}
                    className="font-[600] cursor-pointer text-[11px] lg:text-[13px] capitalize px-[0.5px] sm:px-1 py-4  rounded-sm text-center  w-[100%]  bg-vestigreen transition duration-700 ease-in-out hover:bg-[#3E6F26]"
                  >
                    Discover Your Pathway with Miai ™
                  </div>
                )}
              </div>
            </div>

            {props.email && !validateEmail(props.email) && (
              <p className="text-red-700 w-full mt-2  text-[14px] text-center font-semibold">
                *Please enter a valid email address
              </p>
            )}

            {error && !props.email ? (
              <p className="text-red-700 w-full mt-2  text-[14px] text-center font-semibold">
                *Enter your email to proceed
              </p>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserLocation;
